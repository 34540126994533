import Slideshow from '../pages/Slideshow';
import About from '../pages/About';
import Service from './Service';
import Contact from '../pages/Contact';
import Footer from "../pages/Footer";
import CustomerBenefit from "./CustomerBenefit";
import OurProgrammers from "./OurProgrammers";
import AppCollection from "./AppCollection";
import WebCollection from "./WebCollection";

function Home() {
  return (
    <div>
        <Slideshow />
        <About/>
        <Service/>
        <CustomerBenefit/>
        <AppCollection/>
        <WebCollection/>
        <OurProgrammers/>
        <Contact/>
        <Footer/>
    </div>
  )
}

export default Home