import React from 'react'
import "../scss/Contactpage.scss"

function Contact() {
  return (
    <div className='Contact' id='contact'>
          <div className='titles'>
            <h3 className='subTitle'>Get in Touch with Us</h3>
            <h2 className='title'>Contact Form.</h2>
       </div>
        <div className='contact-info' data-aos="fade-right">
            <form action="https://formsubmit.co/admin@technopresso.com" method="POST">
                <input type='text' className='textField' placeholder='Full Name' name="name" autoComplete="on" required />
                <input type='text' className='textField' placeholder='Email Address' name="email" autoComplete="on" required />
                <textarea className='textField messageField' placeholder='Your Message'  name="content" required></textarea>
                <button type='submit' className='textField submitButton'>Send Message</button>{/* value="Send Message" */}
            </form>
        </div>

    </div>
  )
}

export default Contact