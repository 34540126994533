import AppCollection from '../pages/AppCollection';
import WebCollection from "../pages/WebCollection"
import Footer from "./Footer";
import ThreeD from "./ThreeD";
import ScrollToBotButton from '../components/ScrollBotBtn';

function AllCollection() {
  return (
    <div>
        <ScrollToBotButton/>
        <ThreeD/>
      <AppCollection/>
      <WebCollection/>
        <Footer/>
    </div>
  )
}

export default AllCollection