import "../../scss/Portfolio1.scss"
import "../../scss/common_style.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEnvelope, faStar, faChevronLeft, faChevronRight, faClock} from '@fortawesome/free-solid-svg-icons';
import {
    faFacebook,
    faTwitter,
    faInstagram,
    faLinkedin,
    faDribbble,
    faBehance
} from '@fortawesome/free-brands-svg-icons';

import logo from "../../imgs/logo.svg";
import process from "../../imgs/icons/process.svg";
import circletext from "../../imgs/icons/circle-text.svg";
import logoclutch from "../../imgs/header/logo-clutch.svg";
import sicon1 from "../../imgs/header/s-icon1.svg";
import sicon2 from "../../imgs/header/s-icon2.svg";
import awards1 from "../../imgs/header/awards/1.svg";
import awards2 from "../../imgs/header/awards/2.svg";
import awards3 from "../../imgs/header/awards/3.svg";
import works1 from "../../imgs/works/1.jpg";
import works2 from "../../imgs/works/2.jpg";
import works3 from "../../imgs/works/3.jpg";
import works4 from "../../imgs/works/4.jpg";
import works5 from "../../imgs/works/5.jpg";
import works6 from "../../imgs/works/6.jpg";
import works7 from "../../imgs/works/7.jpg";
import testim1 from "../../imgs/testim/1.jpg";
import testim2 from "../../imgs/testim/2.jpg";
import testim3 from "../../imgs/testim/3.jpg";
import testim4 from "../../imgs/testim/4.jpg";
import testim5 from "../../imgs/testim/5.jpg";
import team1 from "../../imgs/about/team1.png";
import team2 from "../../imgs/about/team2.png";
import team3 from "../../imgs/about/team3.png";
import team4 from "../../imgs/about/team4.png";
import arrowtopright from "../../imgs/icons/arrow-top-right.svg";
import avatar from "../../imgs/blog/avatar.jpg";
import blog1 from "../../imgs/blog/1.jpg";
import React from "react";

export default function Collection() {

  return (
  <div className='portfolio1'>
    <div className="hamenu">
        <div className="close-menu cursor-pointer ti-close"></div>
        <div className="container-fluid rest d-flex">
            <div className="menu-links">
                <ul className="main-menu rest">
                    <li>
                        <div className="o-hidden">
                            <div className="link cursor-pointer dmenu"><span className="fill-text" data-text="Home">Home</span>
                                <i></i>
                            </div>
                        </div>
                        <div className="sub-menu">
                            <ul>
                                <li>
                                    <a href="#" className="sub-link">Startup Agency</a>
                                </li>
                                <li>
                                    <a href="#" className="sub-link">Creative Studio</a>
                                </li>
                                <li>
                                    <a href="/" className="sub-link">Creative Agency</a>
                                </li>
                                <li>
                                    <a href="/" className="sub-link">Digital Marketing</a>
                                </li>
                                <li>
                                    <a href="#" className="sub-link">Modern Portfolio</a>
                                </li>
                                <li>
                                    <a href="#" className="sub-link">Digital Studio</a>
                                </li>
                                <li>
                                    <a href="#" className="sub-link">Modern Agency</a>
                                </li>
                                <li>
                                    <a href="#" className="sub-link">Creative Agency 2</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <div className="o-hidden">
                            <div className="link cursor-pointer dmenu"><span className="fill-text"
                                    data-text="Pages">Pages</span>
                                <i></i>
                            </div>
                        </div>
                        <div className="sub-menu">
                            <ul>
                                <li>
                                    <a href="#" className="sub-link">About
                                        Us</a>
                                </li>
                                <li>
                                    <a href="#" className="sub-link">Our
                                        Services</a>
                                </li>
                                <li>
                                    <a href="#" className="sub-link">Services Details</a>
                                </li>
                                 <li>
                                    <a href="#" className="sub-link">Our
                                        Team</a>
                                </li>
                                <li>
                                    <a href="#" className="sub-link">Pricing</a>
                                </li>
                                <li>
                                    <a href="#" className="sub-link">FAQS</a>
                                </li>
                                <li>
                                    <a href="#" className="sub-link">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <div className="o-hidden">
                            <div className="link cursor-pointer dmenu"><span className="fill-text"
                                    data-text="Portfolio">Portfolio</span>
                                <i></i>
                            </div>
                        </div>
                        <div className="sub-menu">
                            <ul>
                                <li>
                                    <a href="#" className="sub-link">Standard</a>
                                </li>
                                <li>
                                    <a href="#" className="sub-link">Gallery</a>
                                </li>
                                <li>
                                    <a href="#" className="sub-link">Card</a>
                                </li>
                                <li>
                                    <a href="#" className="sub-link">Grid 2 Column</a>
                                </li>
                                <li>
                                    <a href="#" className="sub-link">Gid 3 Column</a>
                                </li>
                                <li>
                                    <a href="#" className="sub-link">Grid 4 Column</a>
                                </li>
                                <li>
                                    <a href="#" className="sub-link">Project Details</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <div className="o-hidden">
                            <div className="link cursor-pointer dmenu"><span className="fill-text" data-text="Blog">Blog</span>
                                <i></i>
                            </div>
                        </div>
                        <div className="sub-menu">
                            <ul>
                                <li>
                                    <a href="#" className="sub-link">Blog Grid</a>
                                </li>
                                <li>
                                    <a href="#" className="sub-link">Blog Standard</a>
                                </li>
                                <li>
                                    <a href="#" className="sub-link">Blog Details</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <div className="o-hidden">
                            <a href="#" className="link"><span className="fill-text"
                                    data-text="Contact Us">Contact
                                    Us</span></a>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="cont-info valign">
                <div className="text-center full-width">
                    <div className="logo">
                        <img loading={"lazy"} src={logo} alt=""/>
                    </div>
                    <div className="social-icon mt-40">
                        <a href="#"> <FontAwesomeIcon icon={ faFacebook } /> </a>
                        <a href="#">  <FontAwesomeIcon icon={ faTwitter } /> </a>
                        <a href="#"> <FontAwesomeIcon icon={ faLinkedin }/> </a>
                        <a href="#"> <FontAwesomeIcon icon={ faInstagram }/> </a>
                    </div>
                    <div className="item mt-30">
                        <h5>541 Melville Geek, <br/> Palo Alto, CA 94301</h5>
                    </div>
                    <div className="item mt-10">
                        <h5><a href="#0">Hello@email.com</a></h5>
                        <h5 className="underline"><a href="#0">+1 840 841 25 69</a></h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="smooth-wrapper">
        <div id="smooth-content">
            <main>
                <div className="main-container container-xxl d-flex">
                    <div className="left-side">
                        <div>
                            <nav className="navbar navbar-expand-lg">
                                <div className="topnav ml-auto">
                                    <div className="menu-icon cursor-pointer">
                                        <span className="icon ti-align-right"></span>
                                    </div>
                                </div>
                            </nav>
                            <div className="info md-hide">
                                <div className="circle-text">
                                    <img loading={"lazy"} src={circletext} alt=""/>
                                    <img loading={"lazy"} src={logo} alt="" className="icon"/>
                                </div>
                                <div className="cont text-center mt-40">
                                    <ul className="rest">
                                        <li className="mb-5">admin@technopresso.com</li>
                                        <li className="mb-5">(+010) 523 68 69</li>
                                        <li>Based Cebu, Philippines</li>

                                    <br/>
                                    <br/>
                                    <br/>
                                    </ul>
                                    <span className="mt-30">© 2024, All Rights Reserved</span>
                                    <div className="social-icon mt-40">
                                        <a href="#0">
                                            <FontAwesomeIcon icon={ faTwitter } />
                                        </a>
                                        <a href="#0">
                                            <FontAwesomeIcon icon={ faInstagram }/>
                                        </a>
                                        <a href="#0">
                                            <FontAwesomeIcon icon={ faDribbble } />
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="md-hide">
                            <a href="/contact" className="butn butn-md butn-bord butn-rounded">
                                <div className="d-flex align-items-center">
                                    <span className="icon mr-10">
                                        <FontAwesomeIcon icon={ faEnvelope } />
                                    </span>
                                    <a >Work With Us</a>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="right-side">
                        <header className="header-ca pt-10">
                            <div className="container w-100 p-0">
                                <div className="row sm-marg">
                                    <div className="col-lg-9">
                                        <div className="main-box">
                                            <div className="caption">
                                                <h1>Brand and <br/> Design</h1>
                                            </div>
                                            <div className="text">
                                                <h6>Technofolio, small agency <br/> with big ideas</h6>
                                            </div>
                                            <div className="gl-rate d-flex align-items-center mt-60">
                                                <div className="icon">
                                                    <img loading={"lazy"} src={logoclutch} alt=""/>
                                                </div>
                                                <div className="cont">
                                                    <h6>4.9/5
                                                        <span className="stars">
                                                            <FontAwesomeIcon icon={faStar}/>
                                                            <FontAwesomeIcon icon={faStar}/>
                                                            <FontAwesomeIcon icon={faStar}/>
                                                            <FontAwesomeIcon icon={faStar}/>
                                                            <FontAwesomeIcon icon={faStar}/>
                                                        </span>
                                                    </h6>
                                                    <p>Based on 24 reviews on Clutch</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 right-column">
                                        <div>
                                            <div className="serv">
                                                <h6>Based Boston, MA. We're an agency that focused on crafting experience
                                                    design & development digital products.</h6>
                                                <div className="content" data-tp-throwable-scene="true">
                                                    <div className="item" data-tp-throwable-el="">
                                                        <div className="">
                                                            <span>SEO/Marketing</span>
                                                        </div>
                                                    </div>
                                                    <div className="item" data-tp-throwable-el="">
                                                        <div className="">
                                                            <span className="bdark">Motion</span>
                                                        </div>
                                                    </div>
                                                    <div className="item" data-tp-throwable-el="">
                                                        <div className="icon1">
                                                            <img loading={"lazy"} src={sicon1.svg} alt=""/>
                                                        </div>
                                                    </div>
                                                    <div className="item" data-tp-throwable-el="">
                                                        <div className="">
                                                            <span className="bred">UI/UX Design</span>
                                                        </div>
                                                    </div>
                                                    <div className="item" data-tp-throwable-el="">
                                                        <div className="">
                                                            <img loading={"lazy"} src={sicon2.svg} alt=""/>
                                                        </div>
                                                    </div>
                                                    <div className="item" data-tp-throwable-el="">
                                                        <div className="">
                                                            <span>Branding</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="awards mt-10">
                                            <div className="swiper awards-swiper swiper-container">
                                                <div className="swiper-wrapper">
                                                    <div className="swiper-slide">
                                                        <div className="item">
                                                            <div className="img">
                                                                <img loading={"lazy"} src={awards1} alt=""/>
                                                            </div>
                                                            <span>Agency <br/> of the year</span>
                                                        </div>
                                                    </div>
                                                    <div className="swiper-slide">
                                                        <div className="item">
                                                            <div className="img">
                                                                <img loading={"lazy"} src={awards2} alt=""/>
                                                            </div>
                                                            <span>20 best agency <br/> 2023</span>
                                                        </div>
                                                    </div>
                                                    <div className="swiper-slide">
                                                        <div className="item">
                                                            <div className="img">
                                                                <img loading={"lazy"} src={awards3} alt=""/>
                                                            </div>
                                                            <span>95% job success <br/> on upwork</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="controls">
                                                    <div className="swiper-button-next">
                                                        <FontAwesomeIcon icon={faChevronLeft} />
                                                    </div>
                                                    <div className="swiper-button-prev">
                                                        <FontAwesomeIcon icon={faChevronRight}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <section className="works-ca section-padding bg-light radius-20 mt-15">
                            <div className="sec-head mb-80">
                                <div className="row">
                                    <div className="col-lg-6 md-mb15">
                                        <h2>Projects</h2>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="text">
                                            <p>Business challenges are tough, but we have a proven record <br/> of
                                                elevating our partners to their next and best selves.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="item mb-60 wow fadeInUp slow" data-wow-delay="0.2s">
                                        <div className="img">
                                            <img loading={"lazy"} src={works1} alt=""/>
                                            <div className="tags">
                                                <a href="#0">Branding</a>
                                                <a href="#0">UI/HX</a>
                                                <a href="#0">Motion</a>
                                            </div>
                                            <a href="#" className="plink"></a>
                                        </div>
                                        <h6>Newz - Magazine Site</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="item mb-60 wow fadeInUp slow" data-wow-delay="0.2s">
                                        <div className="img square fit-img">
                                            <img loading={"lazy"} src={works2} alt=""/>
                                            <div className="tags">
                                                <a href="#0">Branding</a>
                                            </div>
                                            <a href="#" className="plink"></a>
                                        </div>
                                        <h6>LW Rebrand</h6>
                                    </div>
                                </div>
                                <div className="col-lg-6 offset-lg-3 wow fadeInUp slow" data-wow-delay="0.4s">
                                    <div className="item mb-60">
                                        <div className="img">
                                            <img loading={"lazy"} src={works3} alt=""/>
                                            <div className="tags">
                                                <a href="#0">UI/HX</a>
                                                <a href="#0">Webflow</a>
                                            </div>
                                            <a href="#" className="plink"></a>
                                        </div>
                                        <h6>ZumarCons Firm</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 offset-lg-4 wow fadeInUp slow" data-wow-delay="0.2s">
                                    <div className="item mb-60">
                                        <div className="img square fit-img">
                                            <img loading={"lazy"} src={works4} alt=""/>
                                            <div className="tags">
                                                <a href="#0">Branding</a>
                                            </div>
                                            <a href="#" className="plink"></a>
                                        </div>
                                        <h6>LW Rebrand</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-8 offset-lg-4 wow fadeInUp slow" data-wow-delay="0.2s">
                                    <div className="item mb-60">
                                        <div className="img">
                                            <img loading={"lazy"} src={works5} alt=""/>
                                            <div className="tags">
                                                <a href="#0">Branding</a>
                                                <a href="#0">UI/HX</a>
                                                <a href="#0">Motion</a>
                                            </div>
                                            <a href="#" className="plink"></a>
                                        </div>
                                        <h6>Newz - Magazine Site</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="item mb-60 wow fadeInUp slow" data-wow-delay="0.2s">
                                        <div className="img">
                                            <img loading={"lazy"} src={works6} alt=""/>
                                            <div className="tags">
                                                <a href="#0">Branding</a>
                                            </div>
                                            <a href="#" className="plink"></a>
                                        </div>
                                        <h6>LW Rebrand</h6>
                                    </div>
                                </div>
                                <div className="col-lg-3 offset-lg-3">
                                    <div className="item mb-60  wow fadeInUp slow" data-wow-delay="0.4s">
                                        <div className="img square fit-img">
                                            <img loading={"lazy"} src={works7} alt=""/>
                                            <div className="tags">
                                                <a href="#0">UI/HX</a>
                                                <a href="#0">Webflow</a>
                                            </div>
                                            <a href="#" className="plink"></a>
                                        </div>
                                        <h6>ZumarCons Firm</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mt-20">
                                <a href="#" className="butn butn-md butn-bg butn-rounded">
                                    <div className="d-flex align-items-center">
                                        <span>See All Our Projects</span>
                                        <span className="ml-20">
                                            <FontAwesomeIcon icon={faChevronRight}/>
                                        </span>
                                    </div>
                                </a>
                            </div>
                        </section>
                        <section className="process-ca section-padding bg-light radius-20 mt-15 ontop">
                            <div className="sec-head mb-80">
                                <div className="row">
                                    <div className="col-lg-6 md-mb15">
                                        <h2>Process</h2>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="text">
                                            <p>Business challenges are tough, but we have a proven record <br/> of
                                                elevating our partners to their next and best selves.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 valign">
                                    <div className="img md-mb50 wow zoomIn slow" data-wow-delay="0.2s">
                                        <img loading={"lazy"} src={process} alt=""/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="accordion wow fadeInUp slow" data-wow-delay="0.4s" id="accordionExample">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="heading1">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapse1" aria-expanded="true"
                                                    aria-controls="collapse1">
                                                    <span>1.</span>Research
                                                </button>
                                            </h2>
                                            <div id="collapse1" className="accordion-collapse collapse"
                                                aria-labelledby="heading1" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>Once the wireframe get approved at step 1, we’ll build prototype
                                                        design to visually the idea</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item active">
                                            <h2 className="accordion-header" id="heading2">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapse2"
                                                    aria-expanded="false" aria-controls="collapse2">
                                                    <span>2.</span>Concept Design
                                                </button>
                                            </h2>
                                            <div id="collapse2" className="accordion-collapse collapse show"
                                                aria-labelledby="heading2" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>Once the wireframe get approved at step 1, we’ll build prototype
                                                        design to visually the idea</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="heading3">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapse3"
                                                    aria-expanded="false" aria-controls="collapse3">
                                                    <span>3.</span>Implementation
                                                </button>
                                            </h2>
                                            <div id="collapse3" className="accordion-collapse collapse"
                                                aria-labelledby="heading3" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>Once the wireframe get approved at step 1, we’ll build prototype
                                                        design to visually the idea</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="heading4">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapse4"
                                                    aria-expanded="false" aria-controls="collapse4">
                                                    <span>4.</span>Testing
                                                </button>
                                            </h2>
                                            <div id="collapse4" className="accordion-collapse collapse"
                                                aria-labelledby="heading4" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>Once the wireframe get approved at step 1, we’ll build prototype
                                                        design to visually the idea</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="#" className="butn butn-md butn-bg butn-rounded mt-60">
                                        <div className="d-flex align-items-center">
                                            <span>Work With Us Now</span>
                                            <span className="icon ml-20">
                                                <FontAwesomeIcon icon={faChevronRight}/>
                                            </span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </section>


                        <section className="numbers-ca">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="item md-mb50 wow fadeInUp slow" data-wow-delay="0.2s">
                                        <h2>95%</h2>
                                        <span>Clients satisfied and <br/> repeating</span>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="item md-mb50 wow fadeInUp slow" data-wow-delay="0.4s">
                                        <h2>125+</h2>
                                        <span>projects completed in <br/> 20 countries</span>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="item wow fadeInUp slow" data-wow-delay="0.6s">
                                        <h2>24</h2>
                                        <span>award winning and honorable <br/> recognition</span>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="testimonials-ca section-padding radius-20 mt-15">
                            <div className="sec-head mb-80">
                                <div className="row">
                                    <div className="col-lg-6 md-mb15">
                                        <h2>Reviews</h2>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex">
                                            <div className="gl-rate d-flex align-items-center ml-auto">
                                                <div className="icon">
                                                    <img loading={"lazy"} src={logoclutch} alt=""/>
                                                </div>
                                                <div className="cont">
                                                    <h6>4.9/5 <span>Rating on Clutch </span></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item wow fadeInUp slow" data-wow-delay="0.2s">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="info d-flex align-items-center">
                                            <div className="md-mb30">
                                                <div className="img fit-img">
                                                    <img loading={"lazy"} src={testim1} alt=""/>
                                                </div>
                                            </div>
                                            <div className="cont">
                                                <h6>CEO at Archin Co.</h6>
                                                <span>Brian Lee</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 offset-lg-1">
                                        <div className="text">
                                            <h6>“Their services aren’t cookie-cutter and are truly specific to us.”</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item wow fadeInUp slow" data-wow-delay="0.2s">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="info d-flex align-items-center">
                                            <div className="md-mb30">
                                                <div className="img fit-img">
                                                    <img loading={"lazy"} src={testim2} alt=""/>
                                                </div>
                                            </div>
                                            <div className="cont">
                                                <h6>President, Newz JSC.</h6>
                                                <span>Aaron Beck</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 offset-lg-1">
                                        <div className="text">
                                            <h6>“A rebrand is not typically done in a chaotic, archaic industry like
                                                ours, so their work has really set us apart."</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item wow fadeInUp slow" data-wow-delay="0.2s">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="info d-flex align-items-center">
                                            <div className="md-mb30">
                                                <div className="img fit-img">
                                                    <img loading={"lazy"} src={testim3} alt=""/>
                                                </div>
                                            </div>
                                            <div className="cont">
                                                <h6>Marketing Manager, OKG</h6>
                                                <span>Tim Morthy</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 offset-lg-1">
                                        <div className="text">
                                            <h6>"The Technofolio team truly amplified our messaging through their expert
                                                use of visuals."</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item wow fadeInUp slow" data-wow-delay="0.2s">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="info d-flex align-items-center">
                                            <div className="md-mb30">
                                                <div className="img fit-img">
                                                    <img loading={"lazy"} src={testim4} alt=""/>
                                                </div>
                                            </div>
                                            <div className="cont">
                                                <h6>Director, ZumarCons</h6>
                                                <span>Lewis Cook</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 offset-lg-1">
                                        <div className="text">
                                            <h6>"Our experience with Technofolio was really good."</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item wow fadeInUp slow" data-wow-delay="0.2s">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="info d-flex align-items-center">
                                            <div className="md-mb30">
                                                <div className="img fit-img">
                                                    <img loading={"lazy"} src={testim5} alt=""/>
                                                </div>
                                            </div>
                                            <div className="cont">
                                                <h6>CTO, Itech Co.</h6>
                                                <span>Mohamed Moussa</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 offset-lg-1">
                                        <div className="text">
                                            <h6>"They have been excellent at leveraging the wealth of knowledge and
                                                expertise that Technofolio has across their team members."</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                <a href="#0" className="butn butn-md butn-bord butn-rounded mt-40">
                                    <div className="d-flex align-items-center">
                                        <span>See All Reviews on Clutch</span>
                                        <span className="icon ml-20">
                                            <FontAwesomeIcon icon={faChevronRight}/>
                                        </span>
                                    </div>
                                </a>
                            </div>
                        </section>
                        <section className="about-ca mt-15">
                            <div className="row sm-marg">
                                <div className="col-lg-9">
                                    <div className="main-box md-mb10">
                                        <div className="caption js-splittext-lines">
                                            <h1>Geek and <br/> Heat</h1>
                                        </div>
                                        <div className="text">
                                            <h6>Work Hard, Play Hard is <br/> Technofolio’s philosophy</h6>
                                        </div>
                                        <div className="states mt-60">
                                            <h2>25+</h2>
                                            <span>multidisciplinary designers <br/> and managers</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 right-column">
                                    <div>
                                        <div className="team mb-10">
                                            <div className="swiper team-swiper swiper-container">
                                                <div className="swiper-wrapper">
                                                    <div className="swiper-slide">
                                                        <div className="item text-center">
                                                            <div className="img">
                                                                <img loading={"lazy"} src={team1} alt=""/>
                                                            </div>
                                                            <div className="cont">
                                                                <h6>Logan Dang</h6>
                                                                <span className="sub-color">CEO Founder</span>
                                                                <div className="social-icon">
                                                                    <a href="#0">
                                                                        <FontAwesomeIcon icon={faTwitter}/>
                                                                    </a>
                                                                    <a href="#0">
                                                                        <FontAwesomeIcon icon={faInstagram}/>
                                                                    </a>
                                                                    <a href="#0">
                                                                        <FontAwesomeIcon icon={faDribbble}/>
                                                                    </a>
                                                                    <a href="#0">
                                                                        <FontAwesomeIcon icon={faBehance}/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="swiper-slide">
                                                        <div className="item text-center">
                                                            <div className="img">
                                                                <img loading={"lazy"} src={team2} alt=""/>
                                                            </div>
                                                            <div className="cont">
                                                                <h6>Brian Hanley</h6>
                                                                <span className="sub-color">CTO</span>
                                                                <div className="social-icon">
                                                                    <a href="#0">
                                                                        <FontAwesomeIcon icon={faTwitter}/>
                                                                    </a>
                                                                    <a href="#0">
                                                                        <FontAwesomeIcon icon={faDribbble}/>
                                                                    </a>
                                                                    <a href="#0">
                                                                        <FontAwesomeIcon icon={faBehance}/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="swiper-slide">
                                                        <div className="item text-center">
                                                            <div className="img">
                                                                <img loading={"lazy"} src={team3} alt=""/>
                                                            </div>
                                                            <div className="cont">
                                                                <h6>Jurgen Klopp</h6>
                                                                <span className="sub-color">Senior Product Management</span>
                                                                <div className="social-icon">
                                                                    <a href="#0">
                                                                        <FontAwesomeIcon icon={faTwitter}/>
                                                                    </a>
                                                                    <a href="#0">
                                                                        <FontAwesomeIcon icon={faBehance}/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="swiper-slide">
                                                        <div className="item text-center">
                                                            <div className="img">
                                                                <img loading={"lazy"} src={team4} alt=""/>
                                                            </div>
                                                            <div className="cont">
                                                                <h6>Thomas Finland</h6>
                                                                <span className="sub-color">Creative Director</span>
                                                                <div className="social-icon">
                                                                    <a href="#0">
                                                                        <FontAwesomeIcon icon={faTwitter}/>
                                                                    </a>
                                                                    <a href="#0">
                                                                        <FontAwesomeIcon icon={faInstagram}/>
                                                                    </a>
                                                                    <a href="#0">
                                                                        <FontAwesomeIcon icon={faDribbble}/>
                                                                    </a>
                                                                    <a href="#0">
                                                                        <FontAwesomeIcon icon={faBehance}/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="botom d-flex align-items-end">
                                                    <div>
                                                        <span className="title">Our <br/> Leaders</span>
                                                    </div>
                                                    <div className="controls ml-auto">
                                                        <div className="swiper-button-next">
                                                            <FontAwesomeIcon icon={faChevronLeft}/>
                                                        </div>
                                                        <div className="swiper-button-prev">
                                                            <FontAwesomeIcon icon={faChevronRight}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="join">
                                        <div className="ontop">
                                            <div className="jon-link d-flex">
                                                <div>
                                                    <span>Join our <br/> team</span>
                                                </div>
                                                <div className="ml-auto">
                                                    <a href="#0" className="icon">
                                                        <img loading={"lazy"} src={arrowtopright} alt=""/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="cont">
                                                <h4>Remote UI/UX Designer</h4>
                                                <span>Work on anywhere with high benefits and salary up to $3000</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="blog-ca section-padding bg-light radius-20 mt-15">
                            <div className="sec-head mb-80">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h2>News</h2>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex">
                                            <a href="#" className="butn butn-md butn-bord butn-rounded ml-auto">
                                                <div className="d-flex align-items-center">
                                                    <span>All Articles</span>
                                                    <span className="icon ml-20">
                                                        <FontAwesomeIcon icon={faChevronRight}/>
                                                    </span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row xlg-marg row-bord">
                                <div className="col-lg-6">
                                    <div className="mitem md-mb50 wow fadeInUp slow" data-wow-delay="0.2s">
                                        <div className="info d-flex align-items-center">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <div className="author-img fit-img">
                                                        <img loading={"lazy"} src={avatar} alt=""/>
                                                    </div>
                                                </div>
                                                <div className="author-info ml-10">
                                                    <span>M Moussa</span>
                                                    <span className="sub-color">editor</span>
                                                </div>
                                            </div>
                                            <div className="date ml-auto">
                                                <span className="sub-color"><FontAwesomeIcon icon={ faClock } /> 12 hours
                                                    ago</span>
                                            </div>
                                        </div>
                                        <div className="img fit-img mt-30">
                                            <img loading={"lazy"} src={blog1} alt=""/>
                                        </div>
                                        <div className="cont mt-30">
                                            <h5>
                                                <a href="#0">We’re winner SOTY at CSS Award 2023</a>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 wow fadeInUp slow" data-wow-delay="0.2s">
                                    <div className="item pb-20 mb-20 bord-thin-bottom">
                                        <span className="date sub-color"><FontAwesomeIcon icon={faClock}/>2 days ago</span>
                                        <h6 className="sub-head">
                                            <a href="#0">Rebrand vs Reresh: 10 Minutes on Brand <br/> with Technofolio</a>
                                        </h6>
                                    </div>
                                    <div className="item pb-20 mb-20 bord-thin-bottom">
                                        <span className="date sub-color"><FontAwesomeIcon icon={faClock}/>15 days ago</span>
                                        <h6 className="sub-head">
                                            <a href="#0">How to build culture young office?</a>
                                        </h6>
                                    </div>
                                    <div className="item pb-20 mb-20 bord-thin-bottom">
                                        <span className="date sub-color"><FontAwesomeIcon icon={faClock}/>1 month ago</span>
                                        <h6 className="sub-head">
                                            <a href="#0">Case Study: Crafting a UX Strategy Compelling Messaging</a>
                                        </h6>
                                    </div>
                                    <div className="item pb-20 bord-thin-bottom">
                                        <span className="date sub-color">
                                            <FontAwesomeIcon icon={faClock}/>
                                            2 month ago</span>
                                        <h6 className="sub-head">
                                            <a href="#0">UI & UX: What is important?</a>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </main>
        </div>
    </div>
</div>
  )
}
