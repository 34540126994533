import React, { useEffect } from 'react';
import './index.scss';

//AOS
import AOS from 'aos';
import 'aos/dist/aos.css';

//routes
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import Service from './pages/Service';
import OurProgrammers from './pages/OurProgrammers';
import AllCollection from './pages/AllCollection';
import CustomerBenefit from './pages/CustomerBenefit';
import Porfolio1 from './pages/portfolio/Portfolio1';
import Porfolio2 from './pages/portfolio/Portfolio2';
import Porfolio3 from './pages/portfolio/Portfolio3';
import Porfolio4 from './pages/portfolio/Portfolio4';
import Porfolio5 from './pages/portfolio/Portfolio5';
import Porfolio6 from './pages/portfolio/Portfolio6';
import OurServices from './pages/OurServices';
import Particles from './components/Particles';

//Nav
import Navbar from './components/Navbar';

//Cursor
import AnimeCursor from './components/AnimeCursor';

//Scroll to Top
import ScrollToTopButton from './components/ScrollBtn';
import ContactUs from "./pages/ContactUs";
import ScrollToTop from "./components/ScrollToTop";
import ThreeD from "./pages/ThreeD";
import ChatBot from "./pages/ChatBot";


function App() {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      delay: 300,
      offset: 200,
      breakpoints: {
        // When screen width is less than or equal to 520px
        520: {
          duration: 2500,
          delay: 100, // Change the duration to 100ms
          offset: 200,
        }
      }
    });
  }, []);

  return (
    <div className="App">
      <ScrollToTopButton/>
        <Router>
        <ScrollToTop/>
        <Navbar/>
          <AnimeCursor/>
          <ChatBot/>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/service" element={<Service />} />
            <Route path="/allCollection" element={<AllCollection />} />
            <Route path="/ourprogrammers" element={<OurProgrammers />} />
            <Route path="/customerBenefit" element={<CustomerBenefit />} />
            <Route path="/ourservices" element={<OurServices />} />
            <Route path="/allCollection/porfolio1" element={<Porfolio1 />} />
            <Route path="/allCollection/porfolio2" element={<Porfolio2 />} />
            <Route path="/allCollection/porfolio3" element={<Porfolio3 />} />
            <Route path="/allCollection/porfolio4" element={<Porfolio4 />} />
            <Route path="/allCollection/porfolio5" element={<Porfolio5 />} />
            <Route path="/allCollection/porfolio6" element={<Porfolio6 />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/thread" element={<ThreeD />} />
          </Routes>
        </Router>
      <Particles/>
    </div>
  );
}

export default App;
