import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import "../../scss/Portfolio3.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEnvelope, faStar, faChevronLeft, faChevronRight, faClock, faCheck} from '@fortawesome/free-solid-svg-icons';
import {
    faFacebook,
    faTwitter,
    faInstagram,
    faLinkedin,
    faDribbble,
    faBehance, faGithub
} from '@fortawesome/free-brands-svg-icons';

import logo from "../../imgs/logo.svg";
import intro from "../../imgs/portfolio3/intro.jpg";
import technopresso from "../../imgs/background/banner-transparent.webp";
import circlebadge from "../../imgs/portfolio3/header/circle-badge.svg";
import img1 from "../../imgs/portfolio3/header/1.webp";
import img2 from "../../imgs/portfolio3/header/2.jpg";
import brands1 from "../../imgs/portfolio3/brands/1.svg";
import brands2 from "../../imgs/portfolio3/brands/2.svg";
import brands3 from "../../imgs/portfolio3/brands/3.svg";
import brands4 from "../../imgs/portfolio3/brands/4.svg";
import brands5 from "../../imgs/portfolio3/brands/5.svg";
import works1 from "../../imgs/portfolio3/works/1.jpg";
import works2 from "../../imgs/portfolio3/works/2.jpg";
import works3 from "../../imgs/portfolio3/works/3.jpg";
import works4 from "../../imgs/portfolio3/works/4.jpg";
import arrowtopright from "../../imgs/icons/arrow-top-right.svg";
import crownsolid from "../../imgs/icons/crown-solid.svg";
import beziercurvesolid from "../../imgs/icons/bezier-curve-solid.svg";
import codesolid from "../../imgs/icons/code-solid.svg";
import chartlinesolid from "../../imgs/icons/chart-line-solid.svg";
import testimavatar1 from "../../imgs/portfolio3/testim/avatar1.jpg"
import testimavatar2 from "../../imgs/portfolio3/testim/avatar2.jpg"
import testimavatar3 from "../../imgs/portfolio3/testim/avatar3.jpg"
import blog1 from "../../imgs/portfolio3/blog/1.jpg"
import blog2 from "../../imgs/portfolio3/blog/2.jpg"
import React from "react";
import {faPlay} from "@fortawesome/free-solid-svg-icons/faPlay";

export default function Collection() {
  return (
  <div className='portfolio3'>
            <div className="hamenu">
                <div className="close-menu cursor-pointer ti-close"></div>
                <div className="container-fluid rest d-flex">
                    <div className="menu-links">
                        <ul className="main-menu rest">
                            <li>
                                <div className="o-hidden">
                                    <div className="link cursor-pointer dmenu"><span className="fill-text" data-text="Home">Home</span>
                                        <i></i></div>
                                </div>
                                <div className="sub-menu">
                                    <ul>
                                        <li>
                                            <a href="../startup_agency/index.html" className="sub-link">Startup Agency</a>
                                        </li>
                                        <li>
                                            <a href="../creative-studio/index.html" className="sub-link">Creative Studio</a>
                                        </li>
                                        <li>
                                            <a href="../creative_agency/index.html" className="sub-link">Creative Agency</a>
                                        </li>
                                        <li>
                                            <a href="../digital-marketing/index.html" className="sub-link">Digital Marketing</a>
                                        </li>
                                        <li>
                                            <a href="../modern_portfolio/index.html" className="sub-link">Modern Portfolio</a>
                                        </li>
                                        <li>
                                            <a href="../digital_studio/index.html" className="sub-link">Digital Studio</a>
                                        </li>
                                        <li>
                                            <a href="../modern_agency/index.html" className="sub-link">Modern Agency</a>
                                        </li>
                                        <li>
                                            <a href="../creative_agency2/index.html" className="sub-link">Creative Agency 2</a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="o-hidden">
                                    <div className="link cursor-pointer dmenu"><span className="fill-text" data-text="Pages">Pages</span>
                                        <i></i></div>
                                </div>
                                <div className="sub-menu">
                                    <ul>
                                        <li>
                                            <a href="../inner_pages/about.html" className="sub-link">About Us</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/services.html" className="sub-link">Our Services</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/service-details.html" className="sub-link">Services Details</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/team.html" className="sub-link">Our Team</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/pricing.html" className="sub-link">Pricing</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/faqs.html" className="sub-link">FAQS</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/contact.html" className="sub-link">Contact Us</a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="o-hidden">
                                    <div className="link cursor-pointer dmenu"><span className="fill-text" data-text="Portfolio">Portfolio</span>
                                        <i></i></div>
                                </div>
                                <div className="sub-menu">
                                    <ul>
                                        <li>
                                            <a href="../inner_pages/portfolio-standard.html" className="sub-link">Standard</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/portfolio-gallery.html" className="sub-link">Gallery</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/portfolio-cards.html" className="sub-link">Card</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/portfolio-layout2.html" className="sub-link">Grid 2 Column</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/portfolio-layout3.html" className="sub-link">Gid 3 Column</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/portfolio-layout4.html" className="sub-link">Grid 4 Column</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/project-details.html" className="sub-link">Project Details</a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="o-hidden">
                                    <div className="link cursor-pointer dmenu"><span className="fill-text" data-text="Blog">Blog</span>
                                        <i></i></div>
                                </div>
                                <div className="sub-menu">
                                    <ul>
                                        <li>
                                            <a href="../inner_pages/blog-grid.html" className="sub-link">Blog Grid</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/blog-standard.html" className="sub-link">Blog Standard</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/blog-details.html" className="sub-link">Blog Details</a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="o-hidden">
                                    <a href="../inner_pages/contact.html" className="link"><span className="fill-text"
                                            data-text="Contact Us">Contact Us</span></a>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="cont-info valign">
                        <div className="text-center full-width">
                            <div className="logo">
                                <img loading={"lazy"} src={logo} alt=""/>
                            </div>
                            <div className="social-icon mt-40">
                                <a href="#"> <FontAwesomeIcon icon={ faFacebook } /> </a>
                                <a href="#"> <FontAwesomeIcon icon={ faTwitter } /> </a>
                                <a href="#"> <FontAwesomeIcon icon={ faLinkedin } /> </a>
                                <a href="#"> <FontAwesomeIcon icon={ faInstagram } /> </a>
                            </div>
                            <div className="item mt-30">
                                <h5>541 Melville Geek, <br/> Palo Alto, CA 94301</h5>
                            </div>
                            <div className="item mt-10">
                                <h5><a href="#0">Hello@email.com</a></h5>
                                <h5 className="underline"><a href="#0">+1 840 841 25 69</a></h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="smooth-wrapper">


                <div id="smooth-content">

                    <main>

                        <header className="header-dm section-padding">
                            <div className="container-xl position-re">
                                <div className="row">
                                    <div className="col-lg-9">
                                        <div className="caption">
                                            <h1>The digital marketing agency <br/> for fast growth</h1>
                                        </div>
                                        <div className="row md-hide">
                                            <div className="col-lg-6 imgs-cube">
                                                <div className="imgs">
                                                    <div className="circle">
                                                        <img loading={"lazy"} src={circlebadge} alt=""/>
                                                    </div>
                                                </div>
                                                <div className="img fit-img radius-30 mt-50">
                                                    <img loading={"lazy"} src={img1} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mimg fit-img">
                                    <div className="shaps top">
                                        <div className="shap-right-top">
                                            <svg viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"
                                                className="w-11 h-11">
                                                <path
                                                    d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z"
                                                    fill="#0e0f11"></path>
                                            </svg>
                                        </div>
                                        <div className="shap-left-bottom">
                                            <svg viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"
                                                className="w-11 h-11">
                                                <path
                                                    d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z"
                                                    fill="#0e0f11"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <img loading={"lazy"} src={img2} alt=""/>
                                    <div className="text">
                                        <span className="fz-12 text-u mb-10">Since <br/> 2002</span>
                                        <p>We are professional agency <br/>
                                            working with heart from melbourne</p>
                                        <div className="shaps bottom">
                                            <div className="shap-left-top">
                                                <svg viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"
                                                    className="w-11 h-11">
                                                    <path
                                                        d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z"
                                                        fill="#0e0f11"></path>
                                                </svg>
                                            </div>
                                            <div className="shap-right-bottom">
                                                <svg viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"
                                                    className="w-11 h-11">
                                                    <path
                                                        d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z"
                                                        fill="#0e0f11"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>

                        <section className="intro-dm">
                            <div className="container">
                                <div className="sec-head mb-100">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <h6 className="sub-head">Who we are ?</h6>
                                        </div>
                                        <div className="col-lg-8">
                                            <h3 className="text-indent">As a tight-knit team of experts, we create memorable and
                                                emotional websites,
                                                digital experiences, and native apps.
                                            </h3>
                                            <a href="../inner_pages/about.html" className="crv-butn mt-40">
                                                <div className="d-flex">
                                                    <span className="text">More about us</span>
                                                    <span className="icon">
                                                        <img loading={"lazy"} src={arrowtopright} alt=""/>
                                                    </span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="clients-dm">
                            <div className="main-marq shadow-off">
                                <div className="slide-har st1">
                                    <div className="box">
                                        <div className="item">
                                            <img loading={"lazy"} src={brands1} alt=""/>
                                        </div>
                                        <div className="item">
                                            <img loading={"lazy"} src={brands2} alt=""/>
                                        </div>
                                        <div className="item">
                                            <img loading={"lazy"} src={brands3} alt=""/>
                                        </div>
                                        <div className="item">
                                            <img loading={"lazy"} src={brands4} alt=""/>
                                        </div>
                                        <div className="item">
                                            <img loading={"lazy"} src={brands5} alt=""/>
                                        </div>
                                    </div>
                                    <div className="box">
                                        <div className="item">
                                            <img loading={"lazy"} src={brands1} alt=""/>
                                        </div>
                                        <div className="item">
                                            <img loading={"lazy"} src={brands2} alt=""/>
                                        </div>
                                        <div className="item">
                                            <img loading={"lazy"} src={brands3} alt=""/>
                                        </div>
                                        <div className="item">
                                            <img loading={"lazy"} src={brands4} alt=""/>
                                        </div>
                                        <div className="item">
                                            <img loading={"lazy"} src={brands5} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="works-dm section-padding">
                            <div className="container">
                                <div className="gallery row md-marg" style={{position: 'relative', height: '1393.47px'}}>
                                    <div className="items col-lg-6 order-md-2" style={{position: 'absolute', left: '0px', top: '0px'}}>
                                        <div className="item">
                                            <div className="img">
                                                <img loading={"lazy"} src={works2} alt=""/>
                                                <div className="tags">
                                                    <a href="#0">Development</a>
                                                    <a href="#0">UI/UX</a>
                                                    <a href="#0">illustration</a>
                                                </div>
                                            </div>
                                            <div className="cont mt-30">
                                                <div className="info sub-color mb-10">
                                                    <span>2023</span>
                                                    <span className="dot"></span>
                                                    <span></span>
                                                </div>
                                                <h6>
                                                    <a href="../inner_pages/project-details.html">Archin Architecture Studio</a>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="items col-lg-6 order-md-1" style={{position: 'absolute', left: '673px', top: '0px'}}>
                                        <div className="sec-head">
                                            <h6 className="sub-head mb-15">Featured Works</h6>
                                            <h2 className="fz-60">Take a look at <br/> our projects</h2>
                                        </div>
                                    </div>
                                    <div className="items col-lg-6 order-md-2" style={{position: 'absolute', left: '673px', top: '180px'}}>
                                        <div className="item">
                                            <div className="img">
                                                <img loading={"lazy"} src={works1} alt=""/>
                                                <div className="tags">
                                                    <a href="#0">Development</a>
                                                    <a href="#0">UI/UX</a>
                                                    <a href="#0">illustration</a>
                                                </div>
                                            </div>
                                            <div className="cont mt-30">
                                                <div className="info sub-color mb-10">
                                                    <span>2023</span>
                                                    <span className="dot"></span>
                                                    <span></span>
                                                </div>
                                                <h6>
                                                    <a href="../inner_pages/project-details.html">Unerio Residential Complex Landing page</a>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="items col-lg-6 order-md-2" style={{position: 'absolute', left: '0px', top: '606px'}}>
                                        <div className="item">
                                            <div className="img">
                                                <img loading={"lazy"} src={works3} alt=""/>
                                                <div className="tags">
                                                    <a href="#0">Development</a>
                                                    <a href="#0">UI/UX</a>
                                                    <a href="#0">illustration</a>
                                                </div>
                                            </div>
                                            <div className="cont mt-30">
                                                <div className="info sub-color mb-10">
                                                    <span>2023</span>
                                                    <span className="dot"></span>
                                                    <span></span>
                                                </div>
                                                <h6>
                                                    <a href="../inner_pages/project-details.html">Jorger Clarkson - Architect Personal Portfolio</a>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="items col-lg-6 order-md-2" style={{position: 'absolute', left: '673px', top: '787px'}}>
                                        <div className="item">
                                            <div className="img">
                                                <img loading={"lazy"} src={works4} alt=""/>
                                                <div className="tags">
                                                    <a href="#0">Development</a>
                                                    <a href="#0">UI/UX</a>
                                                    <a href="#0">illustration</a>
                                                </div>
                                            </div>
                                            <div className="cont mt-30">
                                                <div className="info sub-color mb-10">
                                                    <span>2023</span>
                                                    <span className="dot"></span>
                                                    <span></span>
                                                </div>
                                                <h6>
                                                    <a href="../inner_pages/project-details.html">Newz - Magazine Site</a>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="items col-lg-6 order-md-2" style={{position: 'absolute', left: '0px', top: '1212px'}}>
                                        <a href="../inner_pages/portfolio-gallery.html" className="crv-butn mt-100">
                                            <div className="d-flex">
                                                <span className="text">View all works</span>
                                                <span className="icon">
                                                    <img loading={"lazy"} src={arrowtopright} alt=""/>
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="services-dm sub-bg radius-30 section-padding">
                            <div className="container section-padding pt-0">
                                <div className="sec-head mb-100">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <h6 className="sub-head">Our Service Expertise</h6>
                                        </div>
                                        <div className="col-lg-5">
                                            <h3 className="text-indent md-mb15">We help you to build a creative digital business</h3>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="text">
                                                <p>We are a digital marketing agency with expertise, and we’re on a mission
                                                    to help you take the next step in your business.</p>
                                                <a href="../inner_pages/services.html" className="crv-butn mt-40">
                                                    <div className="d-flex">
                                                        <span className="text">Learn More</span>
                                                        <span className="icon">
                                                            <img loading={"lazy"} src={arrowtopright} alt=""/>
                                                        </span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-end">
                                    <div className="col-lg-9">
                                        <div className="item d-flex align-items-center">
                                            <div className="title">
                                                <h2>Branding</h2>
                                            </div>
                                            <div className="text ml-auto">
                                                <div className="d-flex align-items-center">
                                                    <p>Brand Identity, Stragegy & Consult, <br/> Position, Rebrand</p>
                                                    <div>
                                                        <div className="icon invert">
                                                            <img loading={"lazy"} src={crownsolid} alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item d-flex align-items-center">
                                            <div className="title">
                                                <h2>Design</h2>
                                            </div>
                                            <div className="text ml-auto">
                                                <div className="d-flex align-items-center">
                                                    <p>Brand Identity, Stragegy & Consult, <br/> Position, Rebrand</p>
                                                    <div>
                                                        <div className="icon invert">
                                                            <img loading={"lazy"} src={beziercurvesolid} alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item d-flex align-items-center">
                                            <div className="title">
                                                <h2>Code</h2>
                                            </div>
                                            <div className="text ml-auto">
                                                <div className="d-flex align-items-center">
                                                    <p>Brand Identity, Stragegy & Consult, <br/> Position, Rebrand</p>
                                                    <div>
                                                        <div className="icon invert">
                                                            <img loading={"lazy"} src={codesolid} alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item d-flex align-items-center">
                                            <div className="title">
                                                <h2>Growth</h2>
                                            </div>
                                            <div className="text ml-auto">
                                                <div className="d-flex align-items-center">
                                                    <p>Brand Identity, Stragegy & Consult, <br/> Position, Rebrand</p>
                                                    <div>
                                                        <div className="icon invert">
                                                            <img loading={"lazy"} src={chartlinesolid} alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="intro-vid ontop">
                            <div className="container">
                                <div className="bg-img" data-background={intro} style={{backgroundImage: "url(" + intro + ")"}}>
                                    <div className="states">
                                        <div className="imgs mb-30">
                                            <div className="img fit-img">
                                                <img loading={"lazy"} src={testimavatar1} alt=""/>
                                            </div>
                                            <a href="#0" className="icon">
                                                <img loading={"lazy"} src={arrowtopright} alt=""/>
                                            </a>
                                        </div>
                                        <h3 className="mb-15">7k+ Clients</h3>
                                        <p>Approaches are prominently featured on many architect websites.</p>
                                    </div>
                                    <div className="play-button">
                                        <a href="https://youtu.be/AzwC6umvd1s" className="vid">
                                            <FontAwesomeIcon icon={ faPlay } />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="testimonials-dm section-padding pb-0">
                            <div className="container">
                                <div className="sec-head mb-100">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <h6 className="sub-head">What clients say?</h6>
                                        </div>
                                        <div className="col-lg-7">
                                            <h3 className="text-indent">We're trusted by the <br/> most important teams.
                                            </h3>
                                            <div className="states d-flex mt-40">
                                                <div>
                                                    <h6><FontAwesomeIcon icon={ faCheck } /> 7k+ Customers</h6>
                                                </div>
                                                <div className="ml-80">
                                                    <h6><FontAwesomeIcon icon={ faCheck } /> 99% Satisfaction</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Swiper
                                breakpoints={{
                                    100: {
                                        slidesPerView: 1,
                                    },
                                    640: {
                                        width: 640,
                                        slidesPerView: 1,
                                    },
                                    // when window width is >= 768px
                                    768: {
                                        width: 768,
                                        slidesPerView: 2,
                                    },

                                }}
                                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                                  spaceBetween={50}
                                  slidesPerView={3}
                                  navigation
                                  pagination={{ clickable: true }}
                                  scrollbar={{ draggable: true }}

                                >
                                  <SwiperSlide >
                                  <div className="item">
                                                  <div className="d-flex mb-30">
                                                      <div className="img">
                                                          <div className="fit-img">
                                                              <img loading={"lazy"} src={testimavatar1} alt=""/>
                                                          </div>
                                                      </div>
                                                      <div className="ml-auto">
                                                          <span className="sub-color">Design quality</span>
                                                      </div>
                                                  </div>
                                                  <h5>“Their services aren’t cookie-cutter and are truly specific to us.”</h5>
                                                  <div className="d-flex mt-30">
                                                      <span className="sub-color">Aaron Beck</span>
                                                      <div className="stars ml-auto">
                                                          <FontAwesomeIcon icon={ faStar } />
                                                          <FontAwesomeIcon icon={ faStar } />
                                                          <FontAwesomeIcon icon={ faStar } />
                                                          <FontAwesomeIcon icon={ faStar } />
                                                          <FontAwesomeIcon icon={ faStar } />
                                                      </div>
                                                  </div>
                                              </div>

                                  </SwiperSlide>
                                  <SwiperSlide>
                                  <div className="item">
                                                  <div className="d-flex mb-30">
                                                      <div className="img">
                                                          <div className="fit-img">
                                                              <img loading={"lazy"} src={testimavatar2} alt=""/>
                                                          </div>
                                                      </div>
                                                      <div className="ml-auto">
                                                          <span className="sub-color">Design quality</span>
                                                      </div>
                                                  </div>
                                                  <h5>“Their services aren’t cookie-cutter and are truly specific to us.”</h5>
                                                  <div className="d-flex mt-30">
                                                      <span className="sub-color">Aaron Beck</span>
                                                      <div className="stars ml-auto">
                                                          <FontAwesomeIcon icon={ faStar } />
                                                          <FontAwesomeIcon icon={ faStar } />
                                                          <FontAwesomeIcon icon={ faStar } />
                                                          <FontAwesomeIcon icon={ faStar } />
                                                          <FontAwesomeIcon icon={ faStar } />
                                                      </div>
                                                  </div>
                                              </div>
                                  </SwiperSlide>
                                  <SwiperSlide>
                                  <div className="item">
                                                  <div className="d-flex mb-30">
                                                      <div className="img">
                                                          <div className="fit-img">
                                                              <img loading={"lazy"} src={testimavatar3} alt=""/>
                                                          </div>
                                                      </div>
                                                      <div className="ml-auto">
                                                          <span className="sub-color">Design quality</span>
                                                      </div>
                                                  </div>
                                                  <h5>“Their services aren’t cookie-cutter and are truly specific to us.”</h5>
                                                  <div className="d-flex mt-30">
                                                      <span className="sub-color">Aaron Beck</span>
                                                      <div className="stars ml-auto">
                                                          <FontAwesomeIcon icon={ faStar } />
                                                          <FontAwesomeIcon icon={ faStar } />
                                                          <FontAwesomeIcon icon={ faStar } />
                                                          <FontAwesomeIcon icon={ faStar } />
                                                          <FontAwesomeIcon icon={ faStar } />
                                                      </div>
                                                  </div>
                                              </div>

                                  </SwiperSlide>
                                  <SwiperSlide>
                                  <div className="item">
                                                  <div className="d-flex mb-30">
                                                      <div className="img">
                                                          <div className="fit-img">
                                                              <img loading={"lazy"} src={testimavatar3} alt=""/>
                                                          </div>
                                                      </div>
                                                      <div className="ml-auto">
                                                          <span className="sub-color">Design quality</span>
                                                      </div>
                                                  </div>
                                                  <h5>“Their services aren’t cookie-cutter and are truly specific to us.”</h5>
                                                  <div className="d-flex mt-30">
                                                      <span className="sub-color">Aaron Beck</span>
                                                      <div className="stars ml-auto">
                                                          <FontAwesomeIcon icon={ faStar } />
                                                          <FontAwesomeIcon icon={ faStar } />
                                                          <FontAwesomeIcon icon={ faStar } />
                                                          <FontAwesomeIcon icon={ faStar } />
                                                          <FontAwesomeIcon icon={ faStar } />
                                                      </div>
                                                  </div>
                                              </div></SwiperSlide>

                                              <SwiperSlide>
                                                <div className="item">
                                                      <div className="d-flex mb-30">
                                                          <div className="img">
                                                              <div className="fit-img">
                                                                  <img loading={"lazy"} src={testimavatar1} alt=""/>
                                                              </div>
                                                          </div>
                                                          <div className="ml-auto">
                                                              <span className="sub-color">Design quality</span>
                                                          </div>
                                                      </div>
                                                      <h5>“Their services aren’t cookie-cutter and are truly specific to us.”</h5>
                                                      <div className="d-flex mt-30">
                                                          <span className="sub-color">Aaron Beck</span>
                                                          <div className="stars ml-auto">
                                                              <FontAwesomeIcon icon={ faStar } />
                                                              <FontAwesomeIcon icon={ faStar } />
                                                              <FontAwesomeIcon icon={ faStar } />
                                                              <FontAwesomeIcon icon={ faStar } />
                                                              <FontAwesomeIcon icon={ faStar } />
                                                          </div>
                                                      </div>
                                                  </div>
                                              </SwiperSlide>

                                </Swiper>
                            </div>
                        </section>
                        <section className="blog-sa">
                            <div className="container section-padding">
                                <div className="sec-head mb-100">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <h6 className="sub-head">Latest Articles</h6>
                                        </div>
                                        <div className="col-lg-8">
                                            <h3 className="text-indent">The place that we share everything related to design trends,
                                                tips and more.
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="item md-mb50">
                                            <div className="img fit-img">
                                                <img loading={"lazy"} src={blog1} alt=""/>
                                            </div>
                                            <div className="cont mt-30">
                                                <h6>
                                                    <a href="../inner_pages/blog-details.html">Technopresso Group revolutionizes work with the <br/> power of AI-Driven</a>
                                                </h6>
                                                <div className="info d-flex align-items-center mt-20">
                                                    <a href="#0" className="tag">
                                                        <span>Design Trends</span>
                                                    </a>
                                                    <a href="#0" className="date">
                                                        <span>/ May 15, 2024</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="item">
                                            <div className="img fit-img">
                                                <img loading={"lazy"} src={blog2} alt=""/>
                                            </div>
                                            <div className="cont mt-30">
                                                <h6>
                                                    <a href="../inner_pages/blog-details.html">Common UX painpoints in Dashboard-related <br/> projects</a>
                                                </h6>
                                                <div className="info d-flex align-items-center mt-20">
                                                    <a href="#0" className="tag">
                                                        <span>Experience</span>
                                                    </a>
                                                    <a href="#0" className="date">
                                                        <span>/ May 15, 2024</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                    <footer className="footer-sa pb-80">
                        <div className="container section-padding">
                            <div className="sec-head mb-80">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <a href="#" className="logo md-mb80">
                                            <img loading={"lazy"} src={technopresso} alt=""/>
                                        </a>
                                    </div>
                                    <div className="col-lg-6">
                                        <h3 className="text-indent">We hope to empower user and simplify their everyday lives.
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="contact-infos">
                                <div className="row">
                                    <div className="col-lg-6 offset-lg-5">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="item">
                                                    <span className="sub-color">location</span>
                                                    <p>Cebu, Philippines</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 d-flex justify-content-end justify-end">
                                                <div className="item">
                                                    <span className="sub-color">inquiry</span>
                                                    <p>admin@technopresso.com</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 offset-lg-5">
                                        <div className="social-icon">
                                            <a href="#0">
                                                <FontAwesomeIcon icon={ faTwitter } />
                                            </a>
                                            <a href="#0">
                                                <FontAwesomeIcon icon={ faInstagram } />
                                            </a>
                                            <a href="#0">
                                                <FontAwesomeIcon icon={ faDribbble } />
                                            </a>
                                            <a href="#0">
                                                <FontAwesomeIcon icon={ faBehance } />
                                            </a>
                                            <a href="#0">
                                                <FontAwesomeIcon icon={ faGithub } />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sub-footer">
                            <div className="container bord-thin-top-light pt-50">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="copy sub-color md-mb50">
                                            <p>© 2024 <a href="#0">Technopresso Group</a>. All Right Reserved</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 d-flex justify-content-end">
                                        <div className="links sub-color d-flex justify-content-between">
                                            <a href="#" className="active">Home</a>
                                            <a href="../inner_pages/portfolio-standard.html">Works</a>
                                            <a href="../inner_pages/about.html">Studio</a>
                                            <a href="../inner_pages/blog-standard.html">News</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
</div>
  )
}
