import React from "react";
import About from '../pages/About';
import Footer from "../pages/Footer";

function AboutUs() {
    return (
        <div>
            <About />
            <Footer />
        </div>
    )
}

export default AboutUs