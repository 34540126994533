import React from 'react'
import { Link } from "react-router-dom";
import "../scss/SlideShow.scss";

import { Carousel, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import slide1 from "../imgs/slides/slides1.webp";
import slide2 from "../imgs/slides/slides3.webp";
import slide3  from "../imgs/slides/slides2.webp";
import slide4  from "../imgs/slides/slides4.webp";

function Slideshow() {
    const { t } = useTranslation();

    //ここのcaptionとdescriptionはもう関係ない i18nextのmain.json(ja/en)確認
    const images = [
        { id:1, src: slide1,  alt:'About Technopress Group', caption:'About Us', description:'Aim to be the leading Software developer in the industry', idTag:"about"},
        { id:2, src: slide2,  alt:'Our Services in Technopresso ', caption:'Our Service', description:'Build your Project with Low Cost and High Quality', idTag:"ourservices"},
        { id:3, src: slide3,  alt:'Android, iOS, and Web Application', caption:'Our Collections', description:'Check APP and Web Collections', idTag:"allCollection"},
        { id:4, src: slide4,  alt:'Contact Technopresso', caption:'Contact Us', description:'Get In Touch With Us', idTag:"contact"},
    ];

  return (
    <Carousel controls={true} className='SlideShow' id='slideShow'>
     {images.map((image) => (
        <Carousel.Item key={image.id} interval={1500}>
          <img loading={"lazy"} src={image.src} alt={image.alt} />
          <Carousel.Caption className="carousel-caption">
            <h2>{t(`slides.${image.idTag}.caption`)}</h2>
            <p>{t(`slides.${image.idTag}.description`)}</p>
            <Link to={image.idTag}  smooth="true" offset={50} duration={200}>
              <Button className="custom-button">{t('slides.learnMore')}</Button>
            </Link>
          </Carousel.Caption>
          <div className='overlay'></div>
        </Carousel.Item>
      ))} 
    </Carousel>
  );
}

export default Slideshow