import React, {useState} from 'react';
import Draggable from 'react-draggable'
import Resizable from 're-resizable'

import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrapのスタイルをインポート
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { format } from "date-fns";

import '../scss/Chatbot.css'
import agent from '../imgs/agent.png'
import genai from "../imgs/genaichat.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPaperPlane, faXmark} from '@fortawesome/free-solid-svg-icons';

function ChatBot() {
    const [isVisible, setIsVisible] = useState(true);

    const toggleImage = () => {
        setIsVisible(!isVisible);
        console.log('toggled', isVisible)
    };

    const [input, setInput] = useState("");
    const [messages, setMessages] = useState([
        {
            message: "Hi, I am Maria, your Technopresso AI Agent. How may I help you?",
            sender: "ChatGPT",
            timestamp: format(Date(), "MMMM do, yyyy H:mma")
        }
    ]);

    const handleChange = (event)=>{
        setInput(event.target.value)
    }

    const handleSend = async (event)=>{
        event.preventDefault()
        const newMessage = {
            message: input,
            sender: "user",
            timestamp: format(Date(), "MMMM do, yyyy H:mma")
        }

        const newMessages = [...messages,newMessage];

        setMessages(newMessages);

        setInput('');

        await processMessageToChatGPT(newMessages);
    }

    async function processMessageToChatGPT(chatMessages){
        const API_KEY = "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"
        let apiMessages = chatMessages.map((messageObject)=>{
            let role="";
            if(messageObject.sender === "ChatGPT"){
                role = "assistant"
            }else{
                role = "user"
            }
            return (
                {role: role, content: messageObject.message}
            )
        });

        const systemMessage =
            {
                role: "system",
                content: "You are AI agent of Technopresso Group. Your name is Maria. Explain briefly about Technopresso IT Solutions or simply called Technopresso. "
            }

        const messageRule1 = {
            role: "system",
            content: "Only answer about Technopresso-related topic"
        }

        const messageRule2 = {
            role: "system",
            content: "\"Technopresso is a software development team & IT Consulting service that was established year 2022 in Manila, Philippines. " +
                "That provide expert advice, provide development support and services to help businesses optimize their information technology (IT) " +
                "systems and processes and help them achieve their business goal\""
        }

        const apiRequestBody = {
            "model": "gpt-4o-mini",
            "messages": [
                systemMessage,
                messageRule1,
                messageRule2,
                ...apiMessages
            ],
            "temperature": 0.7
        }

        await fetch("https://api.openai.com/v1/chat/completions",{
            method: "POST",
            headers: {
                "Authorization": `Bearer ${API_KEY}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(apiRequestBody)
        }).then((response)=>{
            return response.json();
        }).then((data)=>{
            // console.log(data.choices[0].message.content);
            setMessages(
                [
                    ...chatMessages,
                    {
                        message: data.choices[0].message.content,
                        sender: "ChatGPT",
                        timestamp: format(Date(), "MMMM do, yyyy H:mma")
                    }
                ]
            )
        })
    }
    return (
        <div className="Chatbot">
            <Draggable>
                <Resizable
                    className="resize_img"
                    defaultSize={{
                        width: 120,
                        height: 360
                    }}
                    style={{
                        background: `url(${genai})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        position: "fixed",
                        overflow: "hidden",
                        alignContent: 'end',
                        right: 50,
                        top: 650,
                        display: isVisible ? 'block' : 'none'

                    }}
                    lockAspectRatio={true}
                    onClick={toggleImage}
                >
                </Resizable>
            </Draggable>
            {
                !isVisible && (
                    <div className="messaging">
                        <div className="inbox_msg">
                            <div className="mesgs">
                                <button onClick={toggleImage} className="closebtn" type="close"><FontAwesomeIcon
                                    icon={faXmark} className='icon'/></button>
                                <div className="msg_history">
                                    {messages.map((message, index) => {
                                        return (
                                            <div
                                                className={message.sender === "ChatGPT" ? 'incoming_msg' : 'outgoing_msg'}>
                                                <div
                                                    className={message.sender === "ChatGPT" ? 'incoming_msg_img' : 'hide_msg_img'}>
                                                    <img src={agent} alt="tech agent"/>
                                                </div>
                                                <div
                                                    className={message.sender === "ChatGPT" ? 'received_msg' : 'sent_msg'}>
                                                    <p>{message.message}</p>
                                                    <span className="time_date"> {message.timestamp}</span>
                                                </div>
                                            </div>

                                        );
                                    })}
                                </div>
                            </div>

                            <div className="prompt-area">
                                <input type="text" placeholder="Send a message..." value={input}
                                       onChange={handleChange}/>
                                <button className="submit" type="submit" onClick={handleSend}><FontAwesomeIcon
                                    icon={faPaperPlane} className='icon'/></button>

                            </div>
                        </div>

                    </div>
                )
            }
        </div>

    );
}

export default ChatBot;
