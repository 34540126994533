import "../../scss/Portfolio6.scss"
import "../../scss/common_style.css"

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronRight, faClock} from '@fortawesome/free-solid-svg-icons';
import {
    faFacebook,
    faTwitter,
    faInstagram,
    faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

import logo from "../../imgs/logo.svg";
import circletext from "../../imgs/portfolio6/cirle-text.svg";
import union from "../../imgs/portfolio6/union.svg";
import header1 from "../../imgs/portfolio6/header/1.webp";
import beziercurvesolid from "../../imgs/icons/bezier-curve-solid.svg";
import codesolid from "../../imgs/icons/code-solid.svg";
import chartlinesolid from "../../imgs/icons/chart-line-solid.svg";

import blog1 from "../../imgs/portfolio6/blog/1.jpg";
import blog2 from "../../imgs/portfolio6/blog/2.jpg";
import blog3 from "../../imgs/portfolio6/blog/3.jpg";
import blog4 from "../../imgs/portfolio6/blog/a1.jpg";
import blog5 from "../../imgs/portfolio6/blog/a2.jpg";

import clients1 from "../../imgs/portfolio6/clients/1.svg";
import clients2 from "../../imgs/portfolio6/clients/2.svg";
import clients3 from "../../imgs/portfolio6/clients/3.svg";
import clients4 from "../../imgs/portfolio6/clients/4.svg";
import clients5 from "../../imgs/portfolio6/clients/5.svg";

import hero5 from "../../imgs/portfolio6/hero/f1.jpg";
import hero6 from "../../imgs/portfolio6/hero/f2.jpg";
import hero7 from "../../imgs/portfolio6/hero/f3.jpg";

import services1 from "../../imgs/portfolio6/services/1.png";
import services2 from "../../imgs/portfolio6/services/2.png";
import services3 from "../../imgs/portfolio6/services/3.png";
import services4 from "../../imgs/portfolio6/services/i1.webp";
import services5 from "../../imgs/portfolio6/services/i2.png";

import testimavatar1 from "../../imgs/portfolio6/testim/avatar1.jpg";
import testimavatar2 from "../../imgs/portfolio6/testim/avatar2.jpg";
import testimavatar3 from "../../imgs/portfolio6/testim/avatar3.jpg";
import testimavatar4 from "../../imgs/portfolio6/testim/Vector_Cricle_Text.svg";
import testimavatar5 from "../../imgs/portfolio6/testim/vector_quote.svg";
import vectorimg10 from "../../imgs/portfolio6/vector-img/face.svg";
import works1 from "../../imgs/portfolio6/works/1.jpg";
import works2 from "../../imgs/portfolio6/works/2.jpg";
import works3 from "../../imgs/portfolio6/works/3.png";
import works4 from "../../imgs/portfolio6/works/4.png";

import React from "react";


export default function Collection() {
    return (
        <div className='portfolio6'>
            <div className="hamenu">
                <div className="close-menu cursor-pointer ti-close"></div>
                <div className="container-fluid rest d-flex">
                    <div className="menu-links">
                        <ul className="main-menu rest">
                            <li>
                                <div className="o-hidden">
                                    <div className="link cursor-pointer dmenu"><span className="fill-text"
                                                                                     data-text="Home">Home</span>
                                        <i></i>
                                    </div>
                                </div>
                                <div className="sub-menu">
                                    <ul>
                                        <li>
                                            <a href="../startup_agency/index.html" className="sub-link">Startup
                                                Agency</a>
                                        </li>
                                        <li>
                                            <a href="../creative-studio/index.html" className="sub-link">Creative
                                                Studio</a>
                                        </li>
                                        <li>
                                            <a href="../creative_agency/index.html" className="sub-link">Creative
                                                Agency</a>
                                        </li>
                                        <li>
                                            <a href="../digital-marketing/index.html" className="sub-link">Digital
                                                Marketing</a>
                                        </li>
                                        <li>
                                            <a href="../modern_portfolio/index.html" className="sub-link">Modern
                                                Portfolio</a>
                                        </li>
                                        <li>
                                            <a href="../digital_studio/index.html" className="sub-link">Digital
                                                Studio</a>
                                        </li>
                                        <li>
                                            <a href="../modern_agency/index.html" className="sub-link">Modern Agency</a>
                                        </li>
                                        <li>
                                            <a href="../creative_agency2/index.html" className="sub-link">Creative
                                                Agency 2</a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="o-hidden">
                                    <div className="link cursor-pointer dmenu"><span className="fill-text"
                                                                                     data-text="Pages">Pages</span>
                                        <i></i>
                                    </div>
                                </div>
                                <div className="sub-menu">
                                    <ul>
                                        <li>
                                            <a href="../inner_pages/about.html" className="sub-link">About Us</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/services.html" className="sub-link">Our Services</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/service-details.html" className="sub-link">Services
                                                Details</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/team.html" className="sub-link">Our Team</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/pricing.html" className="sub-link">Pricing</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/faqs.html" className="sub-link">FAQS</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/contact.html" className="sub-link">Contact Us</a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="o-hidden">
                                    <div className="link cursor-pointer dmenu"><span className="fill-text"
                                                                                     data-text="Portfolio">Portfolio</span>
                                        <i></i>
                                    </div>
                                </div>
                                <div className="sub-menu">
                                    <ul>
                                        <li>
                                            <a href="../inner_pages/portfolio-standard.html"
                                               className="sub-link">Standard</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/portfolio-gallery.html"
                                               className="sub-link">Gallery</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/portfolio-cards.html" className="sub-link">Card</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/portfolio-layout2.html" className="sub-link">Grid 2
                                                Column</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/portfolio-layout3.html" className="sub-link">Gid 3
                                                Column</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/portfolio-layout4.html" className="sub-link">Grid 4
                                                Column</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/project-details.html" className="sub-link">Project
                                                Details</a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="o-hidden">
                                    <div className="link cursor-pointer dmenu"><span className="fill-text"
                                                                                     data-text="Blog">Blog</span>
                                        <i></i>
                                    </div>
                                </div>
                                <div className="sub-menu">
                                    <ul>
                                        <li>
                                            <a href="../inner_pages/blog-grid.html" className="sub-link">Blog Grid</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/blog-standard.html" className="sub-link">Blog
                                                Standard</a>
                                        </li>
                                        <li>
                                            <a href="../inner_pages/blog-details.html" className="sub-link">Blog
                                                Details</a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="o-hidden">
                                    <a href="../inner_pages/contact.html" className="link"><span className="fill-text"
                                                                                                 data-text="Contact Us">Contact Us</span></a>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="cont-info valign">
                        <div className="text-center full-width">
                            <div className="logo">
                                <img loading={"lazy"} src={logo} alt=""/>
                            </div>
                            <div className="social-icon mt-40">
                                <a href="#"> <FontAwesomeIcon icon={faFacebook}/> </a>
                                <a href="#"> <FontAwesomeIcon icon={faTwitter}/> </a>
                                <a href="#"> <FontAwesomeIcon icon={faLinkedin}/> </a>
                                <a href="#"> <FontAwesomeIcon icon={faInstagram}/> </a>
                            </div>
                            <div className="item mt-30">
                                <h5>541 Melville Geek, <br/> Palo Alto, CA 94301</h5>
                            </div>
                            <div className="item mt-10">
                                <h5><a href="#0">Hello@email.com</a></h5>
                                <h5 className="underline"><a href="#0">+1 840 841 25 69</a></h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="smooth-wrapper">
                <div id="smooth-content">
                    <main>
                        <header className="header-mda bg-img valign" data-background={header1} style={{backgroundImage: "url(" + header1 + ")"}}
                                data-overlay-dark="7">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="caption">
                                            <h1>We <br/> Design <br/> Digital <br/> Experience</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>

                        <section className="box-dark goup">

                            <div className="services-mda section-padding">
                                <div className="container">
                                    <div className="sec-head mb-100">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-10">
                                                <div>
                                                    <h2><span className="fz-14 text-u mr-40">Our Services</span> as a
                                                        tight-knit
                                                        team of experts, we create memorable and emotional
                                                        websites <span
                                                            className="fit-img img1"><img
                                                            loading={"lazy"}
                                                            src={services4}
                                                            alt=""/></span> , digital experiences, <span
                                                            className="fit-img img2"><img
                                                            src={services5}
                                                            alt=""/></span> and native apps.</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="item md-mb50">
                                                <div className="cont">
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <h6>Strategy and <br/> Design</h6>
                                                        </div>
                                                        <div className="ml-auto">
                                                            <div className="icon invert">
                                                                <img loading={"lazy"} src={beziercurvesolid} alt=""/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="text">
                                                        <p>We provide digital solutions as Website Design,Mobile App
                                                            Design,
                                                            Landing Page design, Illustration, Animation increase
                                                            company’s values</p>
                                                    </div>
                                                </div>
                                                <div className="img">
                                                    <img loading={"lazy"} src={services1} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="item md-mb50">
                                                <div className="cont">
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <h6>Coding and <br/> Implementation</h6>
                                                        </div>
                                                        <div className="ml-auto">
                                                            <div className="icon invert">
                                                                <img loading={"lazy"} src={codesolid} alt=""/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="text">
                                                        <p>We implement coding with new tech React, Webflow, Wordpress,
                                                            Shopify,
                                                            Flutter, iOS, Android and AI-driven applications</p>
                                                    </div>
                                                </div>
                                                <div className="img">
                                                    <img loading={"lazy"} src={services2} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="item">
                                                <div className="cont">
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <h6>SEO/Marketing and <br/> Advertisment</h6>
                                                        </div>
                                                        <div className="ml-auto">
                                                            <div className="icon invert">
                                                                <img loading={"lazy"} src={chartlinesolid}
                                                                     alt=""/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="text">
                                                        <p>Growth your brand with our SEO/Marketing and advertisment
                                                            solutions.
                                                            Help
                                                            increase the traffic, Google 5* rating and more</p>
                                                    </div>
                                                </div>
                                                <div className="img">
                                                    <img loading={"lazy"} src={services3} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="work-mda">
                                <div className="container section-padding bord-thin-top-light">
                                    <div className="sec-head-lg text-center mb-20">
                                        <h2 className="text-u">Featured <br/> <span className="italic">W</span>orks</h2>
                                    </div>
                                    <div className="row lg-marg">
                                        <div className="col-lg-6">
                                            <div className="item mt-60">
                                                <div className="img fit-img">
                                                    <img loading={"lazy"} src={works1} alt=""/>
                                                    <a href="../inner_pages/portfolio-standard.html"
                                                       className="plink"></a>
                                                </div>
                                                <div className="cont d-flex mt-30">
                                                    <div>
                                                        <h5>Geekfolio</h5>
                                                        <span className="fw-200 fz-14">Creative Digital Agency</span>
                                                    </div>
                                                    <span className="ml-auto fz-13 fw-200 text-u">( Design )</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="item mt-60">
                                                <div className="img fit-img">
                                                    <img loading={"lazy"} src={works2} alt=""/>
                                                    <a href="../inner_pages/portfolio-standard.html"
                                                       className="plink"></a>
                                                </div>
                                                <div className="cont d-flex mt-30">
                                                    <div>
                                                        <h5>Geekfolio</h5>
                                                        <span className="fw-200 fz-14">Creative Digital Agency</span>
                                                    </div>
                                                    <span className="ml-auto fz-13 fw-200 text-u">( Design )</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="item mt-60">
                                                <div className="img fit-img">
                                                    <img loading={"lazy"} src={works3} alt=""/>
                                                    <a href="../inner_pages/portfolio-standard.html"
                                                       className="plink"></a>
                                                </div>
                                                <div className="cont d-flex mt-30">
                                                    <div>
                                                        <h5>Geekfolio</h5>
                                                        <span className="fw-200 fz-14">Creative Digital Agency</span>
                                                    </div>
                                                    <span className="ml-auto fz-13 fw-200 text-u">( Design )</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="item mt-60">
                                                <div className="img fit-img">
                                                    <img loading={"lazy"} src={works4} alt=""/>
                                                    <a href="../inner_pages/portfolio-standard.html"
                                                       className="plink"></a>
                                                </div>
                                                <div className="cont d-flex mt-30">
                                                    <div>
                                                        <h5>Geekfolio</h5>
                                                        <span className="fw-200 fz-14">Creative Digital Agency</span>
                                                    </div>
                                                    <span className="ml-auto fz-13 fw-200 text-u">( Design )</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-80 d-flex justify-content-center">
                                        <a href="../inner_pages/portfolio-layout2.html"
                                           className="butn-circle butn-light d-flex align-items-center justify-content-center text-center">
                                            <div>
                                                <span className="text mb-15">All Projects</span>
                                                <br/>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36"
                                                     viewBox="0 0 37 36" fill="none">
                                                    <path
                                                        d="M1 35L34.2929 1.70711C34.9229 1.07714 36 1.52331 36 2.41421V21.5H29.5"
                                                        stroke="#fff" stroke-width="2"></path>
                                                </svg>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </section>


                        <section className="price section-padding">
                            <div className="container">
                                <div className="sec-head-lg text-center mb-80">
                                    <h2 className="text-u">Our Flat <br/> <span className="italic">P</span>ricing</h2>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="item md-mb50 wow fadeIn" data-wow-delay="0.4s">
                                            <div className="head mb-30">
                                        <span className="icon-img-80 mb-15">
                                            <img loading={"lazy"} src="assets/imgs/price/1.svg" alt=""/>
                                        </span>
                                                <h5 className="mb-10">Basic Plan</h5>
                                                <h6 className="fz-16 fw-400 sub-font">Monthly / <span
                                                    className="opacity-7">Early</span>
                                                </h6>
                                            </div>
                                            <div className="feat mb-30 pb-30 bord-thin-bottom">
                                                <ul className="rest sub-font">
                                                    <li><span className="ti-check icon"></span> Social media advertising
                                                    </li>
                                                    <li><span className="ti-check icon"></span> Report analytics</li>
                                                    <li><span className="ti-check icon"></span> Keyword research</li>
                                                    <li><span className="ti-check icon"></span> Content strategy</li>
                                                    <li><span className="ti-check icon"></span> Customizable
                                                        registration
                                                    </li>
                                                </ul>
                                                <a href="#0" className="view mt-15">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36"
                                                         viewBox="0 0 37 36" fill="none">
                                                        <path
                                                            d="M1 35L34.2929 1.70711C34.9229 1.07714 36 1.52331 36 2.41421V21.5H29.5"
                                                            stroke="#141414" stroke-width="2"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                            <div className="amount d-flex align-items-end">
                                                <h2><span>$</span> 0.96</h2>
                                                <p>/ Monthly</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="item gray md-mb50 wow fadeIn" data-wow-delay="0.6s">
                                            <div className="head mb-30">
                                        <span className="icon-img-80 mb-15">
                                            <img loading={"lazy"} src="assets/imgs/price/2.svg" alt=""/>
                                        </span>
                                                <h5 className="mb-10">Regular Plan</h5>
                                                <h6 className="fz-16 fw-400 sub-font">Monthly / <span
                                                    className="opacity-7">Early</span>
                                                </h6>
                                            </div>
                                            <div className="feat mb-30 pb-30 bord-thin-bottom">
                                                <ul className="rest sub-font">
                                                    <li><span className="ti-check icon"></span> Social media advertising
                                                    </li>
                                                    <li><span className="ti-check icon"></span> Report analytics</li>
                                                    <li><span className="ti-check icon"></span> Keyword research</li>
                                                    <li><span className="ti-check icon"></span> Content strategy</li>
                                                    <li><span className="ti-check icon"></span> Customizable
                                                        registration
                                                    </li>
                                                </ul>
                                                <a href="#0" className="view mt-15">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36"
                                                         viewBox="0 0 37 36" fill="none">
                                                        <path
                                                            d="M1 35L34.2929 1.70711C34.9229 1.07714 36 1.52331 36 2.41421V21.5H29.5"
                                                            stroke="#141414" stroke-width="2"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                            <div className="amount d-flex align-items-end">
                                                <h2><span>$</span> 1.96</h2>
                                                <p>/ Monthly</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="item dark wow fadeIn" data-wow-delay="0.8s">
                                            <div className="head mb-30">
                                        <span className="icon-img-80 mb-15">
                                            <img loading={"lazy"} src="assets/imgs/price/3.svg" alt=""/>
                                        </span>
                                                <h5 className="mb-10 gold-plan">Gold Plan</h5>
                                                <h6 className="fz-16 fw-400 sub-font gold-plan">Monthly / <span
                                                    className="opacity-7 gold-plan">Early</span>
                                                </h6>
                                            </div>
                                            <div className="feat mb-30 pb-30 bord-thin-bottom-light">
                                                <ul className="rest sub-font">
                                                    <li><span className="ti-check icon"></span> Social media advertising
                                                    </li>
                                                    <li><span className="ti-check icon"></span> Report analytics</li>
                                                    <li><span className="ti-check icon"></span> Keyword research</li>
                                                    <li><span className="ti-check icon"></span> Content strategy</li>
                                                    <li><span className="ti-check icon"></span> Customizable
                                                        registration
                                                    </li>
                                                </ul>
                                                <a href="#0" className="view mt-15">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36"
                                                         viewBox="0 0 37 36" fill="none">
                                                        <path
                                                            d="M1 35L34.2929 1.70711C34.9229 1.07714 36 1.52331 36 2.41421V21.5H29.5"
                                                            stroke="#fff" stroke-width="2"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                            <div className="amount d-flex align-items-end ">
                                                <h2 className="gold-plan"><span className="gold-plan">$</span> 7.96</h2>
                                                <p>/ Monthly</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="testimonials-ds">
                            <div className="container section-padding pb-0 bord-thin-top">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <div className="qoute-icon md-mb50">
                                            <div className="text">
                                                <img loading={"lazy"} src={testimavatar4} alt=""/>
                                            </div>
                                            <div className="icon">
                                                <img loading={"lazy"} src={testimavatar5} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="swiper-container">
                                            <Swiper
                                                spaceBetween={10}
                                                slidesPerView={1}
                                                onSlideChange={() => console.log('slide change')}
                                                onSwiper={(swiper) => console.log(swiper)}
                                            >
                                                <SwiperSlide>
                                                    <div className="swiper-slide">
                                                        <div className="item">
                                                            <div className="text">
                                                                <h4>“Technofolio studio ability to create a high quality
                                                                    UI
                                                                    is
                                                                    stands out. It’s something we placed a premium on. A
                                                                    studio
                                                                    with passionate, professional, fun and full
                                                                    creativity.
                                                                    Recommend!.”</h4>
                                                            </div>
                                                            <div
                                                                className="info d-flex align-items-center mt-40 pt-30 bord-thin-top">
                                                                <div>
                                                                    <div className="img-author">
                                                                        <img loading={"lazy"} src={testimavatar1} alt=""/>
                                                                    </div>
                                                                </div>
                                                                <div className="info-text">
                                                                    <span>Bradley Gordon</span>
                                                                    <p>CEO & Founder, Archin Studio</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className="swiper-slide">
                                                        <div className="item">
                                                            <div className="text">
                                                                <h4>“Technofolio studio ability to create a high quality
                                                                    UI
                                                                    is
                                                                    stands out. It’s something we placed a premium on. A
                                                                    studio
                                                                    with passionate, professional, fun and full
                                                                    creativity.
                                                                    Recommend!.”</h4>
                                                            </div>
                                                            <div
                                                                className="info d-flex align-items-center mt-40 pt-30 bord-thin-top">
                                                                <div>
                                                                    <div className="img-author">
                                                                        <img loading={"lazy"} src={testimavatar2} alt=""/>
                                                                    </div>
                                                                </div>
                                                                <div className="info-text">
                                                                    <span>Bradley Gordon</span>
                                                                    <p>CEO & Founder, Archin Studio</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className="swiper-slide">
                                                        <div className="item">
                                                            <div className="text">
                                                                <h4>“Technofolio studio ability to create a high quality
                                                                    UI
                                                                    is
                                                                    stands out. It’s something we placed a premium on. A
                                                                    studio
                                                                    with passionate, professional, fun and full
                                                                    creativity.
                                                                    Recommend!.”</h4>
                                                            </div>
                                                            <div
                                                                className="info d-flex align-items-center mt-40 pt-30 bord-thin-top">
                                                                <div>
                                                                    <div className="img-author">
                                                                        <img loading={"lazy"} src={testimavatar3} alt=""/>
                                                                    </div>
                                                                </div>
                                                                <div className="info-text">
                                                                    <span>Bradley Gordon</span>
                                                                    <p>CEO & Founder, Archin Studio</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                    </Swiper>
                                        </div>
                                </div>
                            </div>
                </div>
            </section>

            <section className="clients-ds section-padding">
                <div className="container">
                    <div className="sec-head mb-70">
                        <h6 className="sub-head">partner with +150 brands</h6>
                    </div>
                    <div className="row sm-marg">
                        <div className="col-lg col-md-4 col-6 md-mb30">
                            <div className="item d-flex align-items-center justify-content-center">
                                <div className="img">
                                    <img loading={"lazy"} src={clients1} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg col-md-4 col-6 md-mb30">
                            <div className="item d-flex align-items-center justify-content-center">
                                <div className="img">
                                    <img loading={"lazy"} src={clients2} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg col-md-4 col-6 md-mb30">
                            <div className="item d-flex align-items-center justify-content-center">
                                <div className="img">
                                    <img loading={"lazy"} src={clients3} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg col-md-4 col-6 sm-mb30">
                                        <div className="item d-flex align-items-center justify-content-center">
                                            <div className="img">
                                                <img loading={"lazy"} src={clients4} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg col-md-4 col-6">
                                        <div className="item d-flex align-items-center justify-content-center">
                                            <div className="img">
                                                <img loading={"lazy"} src={clients5} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>



                        <section className="box-dark section-padding">



                            <div className="faqs">
                                <div className="main-marq mb-80">
                                    <div className="slide-har st1">
                                        <div className="box non-strok">
                                            <div className="item">
                                                <h4 className="d-flex align-items-center"><span>Frequency</span>
                                                </h4>
                                            </div>
                                            <div className="item">
                                                <h4 className="d-flex align-items-center"><span>Frequency</span>
                                                </h4>
                                            </div>
                                            <div className="item">
                                                <h4 className="d-flex align-items-center"><span>Frequency</span>
                                                </h4>
                                            </div>
                                            <div className="item">
                                                <h4 className="d-flex align-items-center"><span>Frequency</span>
                                                </h4>
                                            </div>
                                            <div className="item">
                                                <h4 className="d-flex align-items-center"><span>Frequency</span>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="box non-strok">
                                            <div className="item">
                                                <h4 className="d-flex align-items-center"><span>Frequency</span>
                                                </h4>
                                            </div>
                                            <div className="item">
                                                <h4 className="d-flex align-items-center"><span>Frequency</span>
                                                </h4>
                                            </div>
                                            <div className="item">
                                                <h4 className="d-flex align-items-center"><span>Frequency</span>
                                                </h4>
                                            </div>
                                            <div className="item">
                                                <h4 className="d-flex align-items-center"><span>Frequency</span>
                                                </h4>
                                            </div>
                                            <div className="item">
                                                <h4 className="d-flex align-items-center"><span>Frequency</span>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="position-re">
                                    <div className="container ontop">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-6 col-md-10">
                                                <div className="sec-head-lg text-center mb-80">
                                                    <h2 className="text-u"><span className="italic">A</span>sked <br/>Questions
                                                    </h2>
                                                </div>
                                                <div className="accordion" id="accordionExample">
                                                    <p className="mb-30">Donec ac augue a enim tempus cinia sed id odio.
                                                        Orci arius natoque penatibu magnis parturient.</p>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingOne">
                                                            <button className="accordion-button" type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseOne"
                                                                    aria-expanded="true"
                                                                    aria-controls="collapseOne"><span
                                                                className="face-icon mr-30"><img
                                                                src={vectorimg10} alt=""/></span>
                                                                What
                                                                can we
                                                                do for you with Figma? <span className="icon"><svg
                                                                    xmlns="http://www.w3.org/2000/svg" width="19"
                                                                    height="8"
                                                                    viewBox="0 0 19 8" fill="none">
                                                                <path
                                                                    d="M0.100505 0.899495L17.4853 0.899495C18.3762 0.899495 18.8224 1.97664 18.1924 2.6066L13.8184 6.98061L11.9799 5.14214"
                                                                    stroke="#141414"/>
                                                            </svg></span></button>
                                                        </h2>
                                                        <div id="collapseOne"
                                                             className="accordion-collapse collapse show"
                                                             aria-labelledby="headingOne"
                                                             data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <p>Morbi tempor pharetra dui vitae condimentum. Morbi
                                                                    mattis
                                                                    cursus
                                                                    dignissim. Curabitur mauris massa, efficitur vitae
                                                                    nisl nec,
                                                                    fringilla commodo nisl. Quisque eu tellus tincidunt,
                                                                    vehicula
                                                                    arcu
                                                                    in, feugiat velit.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingTwo">
                                                            <button className="accordion-button collapsed" type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseTwo"
                                                                    aria-expanded="false" aria-controls="collapseTwo">
                                                        <span className="face-icon mr-30"><img
                                                            src={vectorimg10} alt=""/></span> Do
                                                                you
                                                                create one single full home page?<span className="icon"><svg
                                                                xmlns="http://www.w3.org/2000/svg" width="19" height="8"
                                                                viewBox="0 0 19 8" fill="none">
                                                                <path
                                                                    d="M0.100505 0.899495L17.4853 0.899495C18.3762 0.899495 18.8224 1.97664 18.1924 2.6066L13.8184 6.98061L11.9799 5.14214"
                                                                    stroke="#141414"/>
                                                            </svg></span>
                                                            </button>
                                                        </h2>
                                                        <div id="collapseTwo" className="accordion-collapse collapse"
                                                             aria-labelledby="headingTwo"
                                                             data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <p>Morbi tempor pharetra dui vitae condimentum. Morbi
                                                                    mattis
                                                                    cursus
                                                                    dignissim. Curabitur mauris massa, efficitur vitae
                                                                    nisl nec,
                                                                    fringilla commodo nisl. Quisque eu tellus tincidunt,
                                                                    vehicula
                                                                    arcu
                                                                    in, feugiat velit.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingThree">
                                                            <button className="accordion-button collapsed" type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseThree"
                                                                    aria-expanded="false" aria-controls="collapseThree">
                                                        <span className="face-icon mr-30"><img
                                                            src={vectorimg10} alt=""/></span>What
                                                                is
                                                                the standard size of business cards?<span
                                                                className="icon"><svg
                                                                xmlns="http://www.w3.org/2000/svg" width="19" height="8"
                                                                viewBox="0 0 19 8" fill="none">
                                                                <path
                                                                    d="M0.100505 0.899495L17.4853 0.899495C18.3762 0.899495 18.8224 1.97664 18.1924 2.6066L13.8184 6.98061L11.9799 5.14214"
                                                                    stroke="#141414"/>
                                                            </svg></span>
                                                            </button>
                                                        </h2>
                                                        <div id="collapseThree" className="accordion-collapse collapse"
                                                             aria-labelledby="headingThree"
                                                             data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <p>Morbi tempor pharetra dui vitae condimentum. Morbi
                                                                    mattis
                                                                    cursus
                                                                    dignissim. Curabitur mauris massa, efficitur vitae
                                                                    nisl nec,
                                                                    fringilla commodo nisl. Quisque eu tellus tincidunt,
                                                                    vehicula
                                                                    arcu
                                                                    in, feugiat velit.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingFour">
                                                            <button className="accordion-button collapsed" type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseFour"
                                                                    aria-expanded="false" aria-controls="collapseFour">
                                                        <span className="face-icon mr-30"><img
                                                            src={vectorimg10} alt=""/></span>What
                                                                should be on an agency website?<span className="icon"><svg
                                                                xmlns="http://www.w3.org/2000/svg" width="19" height="8"
                                                                viewBox="0 0 19 8" fill="none">
                                                                <path
                                                                    d="M0.100505 0.899495L17.4853 0.899495C18.3762 0.899495 18.8224 1.97664 18.1924 2.6066L13.8184 6.98061L11.9799 5.14214"
                                                                    stroke="#141414"/>
                                                            </svg></span>
                                                            </button>
                                                        </h2>
                                                        <div id="collapseFour" className="accordion-collapse collapse"
                                                             aria-labelledby="headingFour"
                                                             data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <p>Morbi tempor pharetra dui vitae condimentum. Morbi
                                                                    mattis
                                                                    cursus
                                                                    dignissim. Curabitur mauris massa, efficitur vitae
                                                                    nisl nec,
                                                                    fringilla commodo nisl. Quisque eu tellus tincidunt,
                                                                    vehicula
                                                                    arcu
                                                                    in, feugiat velit.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="imgs">
                                        <div className="img1 fit-img wow fadeIn" data-wow-delay="0.4s">
                                            <img loading={"lazy"} src={hero5} alt=""/>
                                        </div>
                                        <div className="img2 fit-img wow fadeIn" data-wow-delay="0.6s">
                                            <img loading={"lazy"} src={hero6} alt=""/>
                                        </div>
                                        <div className="img3 fit-img wow fadeIn" data-wow-delay="0.8s">
                                            <img loading={"lazy"} src={hero7} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </section>



                        <div className="blog-mp section-padding bg-gray">
                            <div className="container">
                                <div className="sec-head-lg mb-80">
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <h2 className="text-u">Latest <br/> <span className="italic">N</span>ews
                                            </h2>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="d-flex">
                                                <a href="../inner_pages/blog-grid.html"
                                                   className="butn butn-md butn-bord butn-rounded ml-auto">
                                                    <span>All Articles</span>
                                                    <span className="icon ml-20">
                                                    <FontAwesomeIcon icon={ faChevronRight } />
                                                </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row xlg-marg">
                                    <div className="col-lg-4 bord">
                                        <div className="item md-mb50">
                                            <div className="info d-flex align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="author-img fit-img">
                                                            <img loading={"lazy"} src={blog4} alt=""/>
                                                        </div>
                                                    </div>
                                                    <div className="author-info ml-10">
                                                        <span>M Moussa</span>
                                                        <span className="sub-color">editor</span>
                                                    </div>
                                                </div>
                                                <div className="date ml-auto">
                                            <span className="sub-color"><FontAwesomeIcon icon={ faClock } /> 12 hours
                                                ago</span>
                                                </div>
                                            </div>
                                            <div className="img fit-img mt-30">
                                                <img loading={"lazy"} src={blog1} alt=""/>
                                            </div>
                                            <div className="cont mt-30">
                                                <h6>
                                                    We’re winner SOTY at CSS
                                                        Award 2023
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 bord">
                                        <div className="item md-mb50">
                                            <div className="info d-flex align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="author-img fit-img">
                                                            <img loading={"lazy"} src={blog4} alt=""/>
                                                        </div>
                                                    </div>
                                                    <div className="author-info ml-10">
                                                        <span>M Moussa</span>
                                                        <span className="sub-color">editor</span>
                                                    </div>
                                                </div>
                                                <div className="date ml-auto">
                                            <span className="sub-color"><FontAwesomeIcon icon={ faClock } /> 12 hours
                                                ago</span>
                                                </div>
                                            </div>
                                            <div className="img fit-img mt-30">
                                                <img loading={"lazy"} src={blog2} alt=""/>
                                            </div>
                                            <div className="cont mt-30">
                                                <h6>
                                                   Common UX painpoints in
                                                        Dashboard-related projects you must
                                                        know
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="item">
                                            <div className="info d-flex align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="author-img fit-img">
                                                            <img loading={"lazy"} src={blog5} alt=""/>
                                                        </div>
                                                    </div>
                                                    <div className="author-info ml-10">
                                                        <span>M Moussa</span>
                                                        <span className="sub-color">editor</span>
                                                    </div>
                                                </div>
                                                <div className="date ml-auto">
                                            <span className="sub-color"><FontAwesomeIcon icon={ faClock } /> 12 hours
                                                ago</span>
                                                </div>
                                            </div>
                                            <div className="img fit-img mt-30">
                                                <img loading={"lazy"} src={blog3} alt=""/>
                                            </div>
                                            <div className="cont mt-30">
                                                <h6>
                                                    Visual Website Tips
                                                        #5
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <footer className="footer-mp section-padding pb-0">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="info-item md-mb30">
                                            <span className="fz-12 text-u sub-color mb-10">location</span>
                                            <h6>152 Thatcher Road St, Mahattan, <br/> NY 10463, US</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="info-item md-mb30">
                                            <span className="fz-12 text-u sub-color mb-10">location</span>
                                            <h6>152 Thatcher Road St, Mahattan, <br/> NY 10463, US</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 d-flex">
                                        <div className="ml-auto ml-none">
                                            <div className="social-icon">
                                                <a href="#"> <FontAwesomeIcon icon={faFacebook}/> </a>
                                                <a href="#"> <FontAwesomeIcon icon={faTwitter}/> </a>
                                                <a href="#"> <FontAwesomeIcon icon={faLinkedin}/> </a>
                                                <a href="#"> <FontAwesomeIcon icon={faInstagram}/> </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="footer-logo">
                                <h1><span>tec</span>folio</h1>
                                <div className="union">
                                        <img loading={"lazy"} src={circletext} alt=""/>
                                        <div className="icon">
                                            <img loading={"lazy"} src={union} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="sub-footer pt-30 pb-30 mt-30 bord-thin-top">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="copy sub-color md-mb30">
                                                <p>© 2024 <a href="#0">Technopresso Group</a>. All Right Reserved</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 d-flex justify-content-end">
                                            <div className="links sub-color d-flex justify-content-between">
                                                <a href="#" className="active">Home</a>
                                                <a href="../inner_pages/portfolio-standard.html">Works</a>
                                                <a href="../inner_pages/about.html">Studio</a>
                                                <a href="../inner_pages/blog-standard.html">News</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </footer>

                    </main>

                </div>
            </div>
        </div>
    )
}
