import "../../scss/Portfolio2.scss"
import "../../scss/common_style.css"

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronRight,
    faCheck,
    faCircleInfo
} from '@fortawesome/free-solid-svg-icons';
import {
    faFacebook,
    faTwitter,
    faInstagram,
    faLinkedin,
    faDribbble
} from '@fortawesome/free-brands-svg-icons';

import favicon from "../../imgs/favicon.ico";
import logo from "../../imgs/portfolio2/logo.svg";
import arrowtopright from "../../imgs/icons/arrow-top-right.svg";
import crownsolid from "../../imgs/icons/crown-solid.svg";
import beziercurvesolid from "../../imgs/icons/bezier-curve-solid.svg";
import codesolid from "../../imgs/icons/code-solid.svg";
import chartlinesolid from "../../imgs/icons/chart-line-solid.svg";
import paperplane from "../../imgs/icons/paper-plane.svg";

import blog1 from "../../imgs/portfolio2/blog/1.jpg";
import blog2 from "../../imgs/portfolio2/blog/2.jpg";
import blog3 from "../../imgs/portfolio2/blog/3.jpg";

import brands1 from "../../imgs/portfolio2/brands/1.svg";
import brands2 from "../../imgs/portfolio2/brands/2.svg";
import brands3 from "../../imgs/portfolio2/brands/3.svg";
import brands4 from "../../imgs/portfolio2/brands/4.svg";

import clients1 from "../../imgs/portfolio2/clients/1.svg";
import clients2 from "../../imgs/portfolio2/clients/2.svg";
import clients3 from "../../imgs/portfolio2/clients/3.svg";
import clients4 from "../../imgs/portfolio2/clients/4.svg";
import clients5 from "../../imgs/portfolio2/clients/5.svg";

import intro1 from "../../imgs/portfolio2/intro/f1.jpg";
import intro2 from "../../imgs/portfolio2/intro/f2.jpg";

import pricing1 from "../../imgs/portfolio2/pricing/Label.svg";

import team1 from "../../imgs/portfolio2/team/1.png";
import team2 from "../../imgs/portfolio2/team/2.png";
import team3 from "../../imgs/portfolio2/team/3.png";
import team4 from "../../imgs/portfolio2/team/4.png";
import team5 from "../../imgs/portfolio2/team/5.png";
import team6 from "../../imgs/portfolio2/team/6.png";


import testim1 from "../../imgs/portfolio2/testim/avatar1.jpg";
import testim2 from "../../imgs/portfolio2/testim/avatar2.jpg";
import testim3 from "../../imgs/portfolio2/testim/avatar3.jpg";
import testim4 from "../../imgs/portfolio2/testim/Vector_Cricle_Text.svg";
import testim5 from "../../imgs/portfolio2/testim/vector_quote.svg";


import works1 from "../../imgs/portfolio2/works/1.webp";
import works2 from "../../imgs/portfolio2/works/2.jpg";
import works3 from "../../imgs/portfolio2/works/3.jpg";
import works4 from "../../imgs/portfolio2/works/4.jpg";


import React from "react";

export default function Collection() {

  return (
  <div className='portfolio2'>
      <div className="hamenu">
          <div className="close-menu cursor-pointer ti-close"></div>
          <div className="container-fluid rest d-flex">
              <div className="menu-links">
                  <ul className="main-menu rest">
                      <li>
                          <div className="o-hidden">
                              <div className="link cursor-pointer dmenu"><span className="fill-text"
                                                                               data-text="Home">Home</span>
                                  <i></i>
                              </div>
                          </div>
                          <div className="sub-menu">
                              <ul>
                                  <li>
                                      <a href="../startup_agency/index" className="sub-link">Startup Agency</a>
                                  </li>
                                  <li>
                                      <a href="../creative-studio/index" className="sub-link">Creative Studio</a>
                                  </li>
                                  <li>
                                      <a href="../creative_agency/index" className="sub-link">Creative Agency</a>
                                  </li>
                                  <li>
                                      <a href="../digital-marketing/index" className="sub-link">Digital
                                          Marketing</a>
                                  </li>
                                  <li>
                                      <a href="../modern_portfolio/index" className="sub-link">Modern Portfolio</a>
                                  </li>
                                  <li>
                                      <a href="../digital_studio/index" className="sub-link">Digital Studio</a>
                                  </li>
                                  <li>
                                      <a href="../modern_agency/index" className="sub-link">Modern Agency</a>
                                  </li>
                                  <li>
                                      <a href="../creative_agency2/index" className="sub-link">Creative Agency
                                          2</a>
                                  </li>
                              </ul>
                          </div>
                      </li>
                      <li>
                          <div className="o-hidden">
                              <div className="link cursor-pointer dmenu"><span className="fill-text"
                                                                               data-text="Pages">Pages</span>
                                  <i></i>
                              </div>
                          </div>
                          <div className="sub-menu">
                              <ul>
                                  <li>
                                      <a href="/about" className="sub-link">About Us</a>
                                  </li>
                                  <li>
                                      <a href="/services" className="sub-link">Our Services</a>
                                  </li>
                                  <li>
                                      <a href="/service-details" className="sub-link">Services
                                          Details</a>
                                  </li>
                                  <li>
                                      <a href="/team" className="sub-link">Our Team</a>
                                  </li>
                                  <li>
                                      <a href="/pricing" className="sub-link">Pricing</a>
                                  </li>
                                  <li>
                                      <a href="/faqs" className="sub-link">FAQS</a>
                                  </li>
                                  <li>
                                      <a href="/contact" className="sub-link">Contact Us</a>
                                  </li>
                              </ul>
                          </div>
                      </li>
                      <li>
                          <div className="o-hidden">
                              <div className="link cursor-pointer dmenu"><span className="fill-text"
                                                                               data-text="Portfolio">Portfolio</span>
                                  <i></i>
                              </div>
                          </div>
                          <div className="sub-menu">
                              <ul>
                                  <li>
                                      <a href="/portfolio-standard" className="sub-link">Standard</a>
                                  </li>
                                  <li>
                                      <a href="/portfolio-gallery" className="sub-link">Gallery</a>
                                  </li>
                                  <li>
                                      <a href="/portfolio-cards" className="sub-link">Card</a>
                                  </li>
                                  <li>
                                      <a href="/portfolio-layout2" className="sub-link">Grid 2
                                          Column</a>
                                  </li>
                                  <li>
                                      <a href="/portfolio-layout3" className="sub-link">Gid 3
                                          Column</a>
                                  </li>
                                  <li>
                                      <a href="/portfolio-layout4" className="sub-link">Grid 4
                                          Column</a>
                                  </li>
                                  <li>
                                      <a href="/project-details" className="sub-link">Project
                                          Details</a>
                                  </li>
                              </ul>
                          </div>
                      </li>
                      <li>
                          <div className="o-hidden">
                              <div className="link cursor-pointer dmenu"><span className="fill-text"
                                                                               data-text="Blog">Blog</span>
                                  <i></i>
                              </div>
                          </div>
                          <div className="sub-menu">
                              <ul>
                                  <li>
                                      <a href="/blog-grid" className="sub-link">Blog Grid</a>
                                  </li>
                                  <li>
                                      <a href="/blog-standard" className="sub-link">Blog Standard</a>
                                  </li>
                                  <li>
                                      <a href="#" className="sub-link">Blog Details</a>
                                  </li>
                              </ul>
                          </div>
                      </li>
                      <li>
                          <div className="o-hidden">
                              <a href="/contact" className="link"><span className="fill-text"
                                                                                           data-text="Contact Us">Contact Us</span></a>
                          </div>
                      </li>
                  </ul>
              </div>
              <div className="cont-info valign">
                  <div className="text-center full-width">
                      <div className="logo">
                          <img loading={"lazy"} src={logo} alt=""/>
                      </div>
                      <div className="social-icon mt-40">
                          <a href="#"> <FontAwesomeIcon icon={ faFacebook } /> </a>
                          <a href="#"> <FontAwesomeIcon icon={ faTwitter } /> </a>
                          <a href="#"> <FontAwesomeIcon icon={ faLinkedin } /> </a>
                          <a href="#"> <FontAwesomeIcon icon={ faInstagram } /> </a>
                      </div>
                      <div className="item mt-30">
                          <h5>541 Melville Geek, <br/> Palo Alto, CA 94301</h5>
                      </div>
                      <div className="item mt-10">
                          <h5><a href="#0">Hello@email.com</a></h5>
                          <h5 className="underline"><a href="#0">+1 840 841 25 69</a></h5>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div id="smooth-wrapper">
          
          <div id="smooth-content">
              <main>
                  <header className="header-ds">
                      <div className="container">
                          <div className="row justify-content-between">
                              <div className="col-lg-5">
                                  <div className="caption md-mb30">
                                      <h1>Bigger, <br/> Bolder and <br/> <span className="play-font">Better</span></h1>
                                  </div>
                              </div>
                              <div className="col-lg-5">
                                  <div className="text md-mb50">
                                      <p>Based in San Francisco, we’re a digital products design & development studio
                                          that
                                          passionate with the creation high applicability of digital experiences</p>
                                      <a href="/about"
                                         className="butn butn-md butn-bord butn-rounded mt-40">
                                          <div className="d-flex align-items-center">
                                              <span>Learn More</span>
                                              <span className=" ml-10">
                                                <FontAwesomeIcon icon={ faChevronRight } />
                                            </span>
                                          </div>
                                      </a>
                                  </div>
                              </div>
                          </div>
                          <div className="row justify-content-between">
                              <div className="col-lg-5 d-flex align-items-end">
                                  <div className="social-icon md-hide">
                                      <a href="#0">
                                          <FontAwesomeIcon icon={ faTwitter } />
                                      </a>
                                      <a href="#0">
                                          <FontAwesomeIcon icon={ faInstagram } />
                                      </a>
                                      <a href="#0">
                                          <FontAwesomeIcon icon={ faDribbble } />
                                      </a>
                                  </div>
                              </div>
                              <div className="col-lg-5">
                                  <div className="numb d-flex">
                                      <div>
                                          <h2>98%</h2>
                                          <span>Clients satisfied and <br/> repeating</span>
                                      </div>
                                      <div className="ml-auto">
                                          <div>
                                              <h2>125+</h2>
                                              <span>projects completed in <br/> 24 countries</span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </header>

                  <section className="box-light section-padding pt-0">


                      <div className="intro-ds">
                          <div className="container">
                              <div className="layers"></div>
                              <div className="box">
                                  <div className="comit bord-thin-bottom pb-30 mb-80">
                                      <div className="row">
                                          <div className="col-lg-4">
                                              <div className="item">
                                                  <h6>Top-notch Experts</h6>
                                              </div>
                                          </div>
                                          <div className="col-lg-4">
                                              <div className="item text-center">
                                                  <h6>Dedicated Support 24/7</h6>
                                              </div>
                                          </div>
                                          <div className="col-lg-4">
                                              <div className="item text-right">
                                                  <h6>Flexible Pricing</h6>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="founders">
                                      <div className="row justify-content-between">
                                          <div className="col-lg-3 md-mb30">
                                              <div className="imgs d-flex align-items-center">
                                                  <div>
                                                      <div className="img img1">
                                                          <img loading={"lazy"} src={intro1} alt=""/>
                                                      </div>
                                                  </div>
                                                  <div>
                                                      <div className="img img2">
                                                          <img loading={"lazy"} src={intro2} alt=""/>
                                                      </div>
                                                  </div>
                                              </div>
                                              <p>Founders of <br/> Technofolio</p>
                                          </div>
                                          <div className="col-lg-7">
                                              <div className="cont">
                                                  <h4 className="text-1">We help business elevate their value through custom software
                                                      development, product design, QA and consulting services.</h4>
                                                  <a href="/about"
                                                     className="butn butn-md butn-bg butn-rounded mt-40">
                                                      <div className="d-flex align-items-center">
                                                          <span className="more-about">More About Us</span>
                                                          <span className="more-about ml-10">
                                                            <FontAwesomeIcon icon={ faChevronRight } />
                                                        </span>
                                                      </div>
                                                  </a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="brand-ds pt-100 pb-100">
                                      <div className="row">
                                          <div className="col-lg-3 col-md-6 item">
                                              <div className="text-center">
                                                  <div className="img">
                                                      <img loading={"lazy"} src={brands1} alt=""/>
                                                  </div>
                                                  <p>4.8/5 Star Rating on <br/> Goodfirms</p>
                                              </div>
                                          </div>
                                          <div className="col-lg-3 col-md-6 item">
                                              <div className="text-center">
                                                  <div className="img">
                                                      <img loading={"lazy"} src={brands2} alt=""/>
                                                  </div>
                                                  <p>Top 50 Global <br/> Companies on Clutch</p>
                                              </div>
                                          </div>
                                          <div className="col-lg-3 col-md-6 item">
                                              <div className="text-center">
                                                  <div className="img">
                                                      <img loading={"lazy"} src={brands3} alt=""/>
                                                  </div>
                                                  <p>95% Job Success <br/> on Upwork</p>
                                              </div>
                                          </div>
                                          <div className="col-lg-3 col-md-6 item">
                                              <div className="text-center">
                                                  <div className="img">
                                                      <img loading={"lazy"} src={brands4} alt=""/>
                                                  </div>
                                                  <p>Top 20 Global Team <br/> on Behance</p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="works-ds">
                          <div className="container pt-100 bord-thin-top">
                              <div className="sec-head mb-70 d-flex align-items-center">
                                  <div>
                                      <h2>Featured <span>Works</span></h2>
                                  </div>
                                  <div className="ml-auto">
                                      <a href="/allcollection"
                                         className="butn butn-md butn-bord butn-rounded">
                                          <div className="d-flex align-items-center">
                                              <span className="text-1">See All Projects</span>
                                              <span className="ml-10">
                                                <FontAwesomeIcon icon={ faChevronRight } />
                                            </span>
                                          </div>
                                      </a>
                                  </div>
                              </div>
                              <div className="work-box">
                                  <div className=" swiper-container">
                                      <Swiper
                                          breakpoints={{
                                              100: {
                                                  slidesPerView: 1,
                                              },
                                              640: {
                                                  slidesPerView: 1,
                                              },
                                              // when window width is >= 768px
                                              768: {
                                                  slidesPerView: 1,
                                              },

                                          }}
                                          spaceBetween={10}
                                          slidesPerView={2}
                                          onSlideChange={() => console.log('slide change')}
                                          onSwiper={(swiper) => console.log(swiper)}
                                      >
                                          <SwiperSlide>
                                              <div className="swiper-slide">
                                                  <div className="item">
                                                      <div className="img">
                                                          <img loading={"lazy"} src={works1} alt=""/>
                                                      </div>
                                                      <div className="cont">
                                                          <h4>Archin Architecture Studio</h4>
                                                          <span
                                                              className="sub-color">Prototype Design, Webflow</span>
                                                      </div>
                                                  </div>
                                              </div>
                                          </SwiperSlide>
                                          <SwiperSlide>
                                              <div className="swiper-slide">
                                                  <div className="item">
                                                      <div className="img">
                                                          <img loading={"lazy"} src={works2} alt=""/>
                                                      </div>
                                                      <div className="cont">
                                                          <h4>Zumar Construct Firm</h4>
                                                          <span
                                                              className="sub-color">Web Design & Development</span>
                                                      </div>
                                                  </div>
                                              </div>
                                          </SwiperSlide>
                                          <SwiperSlide>
                                              <div className="swiper-slide">
                                                  <div className="item">
                                                      <div className="img">
                                                          <img loading={"lazy"} src={works3} alt=""/>
                                                      </div>
                                                      <div className="cont">
                                                          <h4>Archin Architecture Studio</h4>
                                                          <span
                                                              className="sub-color">Prototype Design, Webflow</span>
                                                      </div>
                                                  </div>
                                              </div>
                                          </SwiperSlide>
                                          <SwiperSlide>
                                              <div className="swiper-slide">
                                                  <div className="item">
                                                      <div className="img">
                                                          <img loading={"lazy"} src={works4} alt=""/>
                                                      </div>
                                                      <div className="cont">
                                                          <h4>Archin Architecture Studio</h4>
                                                          <span
                                                              className="sub-color">Prototype Design, Webflow</span>
                                                      </div>
                                                  </div>
                                              </div>
                                          </SwiperSlide>
                                      </Swiper>
                                  </div>
                              </div>
                          </div>
                      </div>


                  </section>

                  <section className="services-ds section-padding">
                      <div className="container">
                          <div className="sec-head mb-70 d-flex align-items-center">
                              <div>
                                  <h2 className="text">Our <span>Services</span></h2>
                              </div>
                              <div className="ml-auto">
                                  <a href="/ourservices" className="butn butn-md butn-bord butn-rounded">
                                      <div className="d-flex align-items-center">
                                          <span>See Our Approach</span>
                                          <span className="ml-10">
                                            <FontAwesomeIcon icon={ faChevronRight } />
                                        </span>
                                      </div>
                                  </a>
                              </div>
                          </div>
                          <div className="item d-flex align-items-center">
                              <div className="title">
                                  <h2>Branding</h2>
                              </div>
                              <div className="text ml-auto">
                                  <div className="d-flex align-items-center">
                                      <p>Brand Identity, Stragegy & Consult, <br/> Position, Rebrand</p>
                                      <div>
                                          <div className="invert">
                                              <img loading={"lazy"} src={crownsolid} alt=""/>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="item d-flex align-items-center">
                              <div className="title">
                                  <h2>Design</h2>
                              </div>
                              <div className="text ml-auto">
                                  <div className="d-flex align-items-center">
                                      <p>Brand Identity, Stragegy & Consult, <br/> Position, Rebrand</p>
                                      <div>
                                          <div className="invert">
                                              <img loading={"lazy"} src={beziercurvesolid} alt=""/>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="item d-flex align-items-center">
                              <div className="title">
                                  <h2>Code</h2>
                              </div>
                              <div className="text ml-auto">
                                  <div className="d-flex align-items-center">
                                      <p>Brand Identity, Stragegy & Consult, <br/> Position, Rebrand</p>
                                      <div>
                                          <div className="invert">
                                              <img loading={"lazy"} src={codesolid} alt=""/>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="item d-flex align-items-center">
                              <div className="title">
                                  <h2>Growth</h2>
                              </div>
                              <div className="text ml-auto">
                                  <div className="d-flex align-items-center">
                                      <p>Brand Identity, Stragegy & Consult, <br/> Position, Rebrand</p>
                                      <div>
                                          <div className="invert">
                                              <img loading={"lazy"} src={chartlinesolid} alt=""/>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </section>

                  <section className="box-dark">

                      <div className="container box">

                          <div className="layers"></div>


                          <div className="testimonials-ds">
                              <div className="row">
                                  <div className="col-lg-5">
                                      <div className="qoute-icon md-mb50">
                                          <div className="text">
                                              <img loading={"lazy"} src={testim4} alt=""/>
                                          </div>
                                          <div className="icon">
                                              <img loading={"lazy"} src={testim5} alt=""/>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg-7">
                                      <div className="swiper testim-swiper swiper-container">
                                          <div className="swiper-wrapper">
                                              <div className="swiper-slide">
                                                  <div className="item">
                                                      <div >
                                                          <h4 className="text">“Technofolio studio ability to create a high quality UI is
                                                              stands out. It’s something we placed a premium on. A
                                                              studio
                                                              with passionate, professional, fun and full creativity.
                                                              Recommend!.”</h4>
                                                      </div>
                                                      <div
                                                          className="info d-flex align-items-center mt-40 pt-30 bord-thin-top-light">
                                                          <div>
                                                              <div className="img-author">
                                                                  <img loading={"lazy"} src={testim1} alt=""/>
                                                              </div>
                                                          </div>
                                                          <div className="info-text">
                                                              <span>Bradley Gordon</span>
                                                              <p>CEO & Founder, Archin Studio</p>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="swiper-slide">
                                                  <div className="item">
                                                      <div className="text">
                                                          <h4>“Technofolio studio ability to create a high quality UI is
                                                              stands out. It’s something we placed a premium on. A
                                                              studio
                                                              with passionate, professional, fun and full creativity.
                                                              Recommend!.”</h4>
                                                      </div>
                                                      <div
                                                          className="info d-flex align-items-center mt-40 pt-30 bord-thin-top-light">
                                                          <div>
                                                              <div className="img-author">
                                                                  <img loading={"lazy"} src={testim2} alt=""/>
                                                              </div>
                                                          </div>
                                                          <div className="info-text">
                                                              <span>Bradley Gordon</span>
                                                              <p>CEO & Founder, Archin Studio</p>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="swiper-slide">
                                                  <div className="item">
                                                      <div className="text">
                                                          <h4>“Technofolio studio ability to create a high quality UI is
                                                              stands out. It’s something we placed a premium on. A
                                                              studio
                                                              with passionate, professional, fun and full creativity.
                                                              Recommend!.”</h4>
                                                      </div>
                                                      <div
                                                          className="info d-flex align-items-center mt-40 pt-30 bord-thin-top-light">
                                                          <div>
                                                              <div className="img-author">
                                                                  <img loading={"lazy"} src={testim3} alt=""/>
                                                              </div>
                                                          </div>
                                                          <div className="info-text">
                                                              <span>Bradley Gordon</span>
                                                              <p>CEO & Founder, Archin Studio</p>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="swiper-pagination"></div>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div className="clients-ds mt-100">
                              <div className="sec-head mb-70">
                                  <h6 className="sub-head">partner with +150 brands</h6>
                              </div>
                              <div className="row sm-marg">
                                  <div className="col-lg col-md-4 col-6 md-mb30">
                                      <div className="item d-flex align-items-center justify-content-center">
                                          <div className="img">
                                              <img loading={"lazy"} src={clients1} alt=""/>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg col-md-4 col-6 md-mb30">
                                      <div className="item d-flex align-items-center justify-content-center">
                                          <div className="img">
                                              <img loading={"lazy"} src={clients2} alt=""/>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg col-md-4 col-6 md-mb30">
                                      <div className="item d-flex align-items-center justify-content-center">
                                          <div className="img">
                                              <img loading={"lazy"} src={clients3} alt=""/>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg col-md-4 col-6 sm-mb30">
                                      <div className="item d-flex align-items-center justify-content-center">
                                          <div className="img">
                                              <img loading={"lazy"} src={clients4} alt=""/>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg col-md-4 col-6">
                                      <div className="item d-flex align-items-center justify-content-center">
                                          <div className="img">
                                              <img loading={"lazy"} src={clients5} alt=""/>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>


                      </div>

                  </section>

                 
                  <section className="pricing-ds section-padding">
                      <div className="container">
                          <div className="sec-head mb-70">
                              <h2 className="text">Membership <span>Pricing</span></h2>
                              <div className="d-flex align-items-center mt-50">
                                  <div className="text">
                                      <p>Clear, straightforward plans tailored to your needs. <br/> Choose a plan and
                                          kick-start now</p>
                                  </div>
                                  <div className="ml-auto">
                                      <div className="pricing-tabsHead">
                                          <div className="price-radios">
                                              <div className="form-check form-check-inline">
                                                  <input className="form-check-input" type="radio" name="inlineRadioOptions"
                                                         id="monthly-input" value="option1" />
                                                      <label className="form-check-label" for="monthly-input">
                                                          Billed Monthly
                                                      </label>
                                              </div>
                                              <div className="form-check form-check-inline">
                                                  <input className="form-check-input" type="radio" name="inlineRadioOptions"
                                                         id="yearly-input" value="option2" checked />
                                                      <label className="form-check-label" for="yearly-input">
                                                          Billed yearly
                                                          <small className="rounded-pill">-10%</small>
                                                      </label>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="table-responsive">
                              <div className="content">
                                  <div className="price-head">
                                      <div className="price-headTitle"></div>
                                      <div className="price-headItem">
                                          <h6>basic</h6>
                                          <h2 className="monthly_price">$3,999 <span>/mo</span></h2>
                                          <h2 className="yearly_price">$3,999 <span>/yr</span></h2>
                                          <p>Perfect for startup or <br/> small business</p>
                                      </div>
                                      <div className="price-headItem bg-gray">
                                          <h6>Premium</h6>
                                          <h2 className="monthly_price color-blue5">$6,499 <span>/mo</span></h2>
                                          <h2 className="yearly_price color-blue5">$6,499 <span>/yr</span></h2>
                                          <p>Perfect for medium or <br/> big business</p>
                                          <div className="label">
                                              <img loading={"lazy"} src={pricing1} alt=""/>
                                          </div>
                                      </div>
                                      <div className="price-headItem">
                                          <h6>ultimate</h6>
                                          <h2 className="monthly_price">$12,499 <span>/mo</span></h2>
                                          <h2 className="yearly_price">$12,499 <span>/yr</span></h2>
                                          <p>Perfect for organise or <br/> enterpise</p>
                                      </div>
                                  </div>

                                  <div className="price-body">
                                      <div className="price-bodyItems">
                                          <div className="price-bodyTitle">
                                              Pause or cancel anytime
                                          </div>
                                          <div className="price-item">
                                              <FontAwesomeIcon icon={ faCheck } />
                                          </div>
                                          <div className="price-item bg-gray">
                                              <FontAwesomeIcon icon={ faCheck } />
                                          </div>
                                          <div className="price-item">
                                              <FontAwesomeIcon icon={ faCheck } />
                                          </div>
                                      </div>

                                      <div className="price-bodyItems">
                                          <div className="price-bodyTitle">
                                              Free support up to 12 Months
                                          </div>
                                          <div className="price-item">
                                              <FontAwesomeIcon icon={ faCheck } />
                                          </div>
                                          <div className="price-item bg-gray">
                                              <FontAwesomeIcon icon={ faCheck } />
                                          </div>
                                          <div className="price-item">
                                              <FontAwesomeIcon icon={ faCheck } />
                                          </div>
                                      </div>

                                      <div className="price-bodyItems">
                                          <div className="price-bodyTitle">
                                              Revisions times
                                          </div>
                                          <div className="price-item">
                                              <span>3 Revisions Times</span>
                                          </div>
                                          <div className="price-item bg-gray">
                                              <span>Unlimited</span>
                                          </div>
                                          <div className="price-item">
                                              <span>Unlimited</span>
                                          </div>
                                      </div>

                                      <div className="price-bodyItems">
                                          <div className="price-bodyTitle">
                                              Request at a time <FontAwesomeIcon icon={ faCircleInfo } />
                                          </div>
                                          <div className="price-item">
                                              <span>1 Request</span>
                                          </div>
                                          <div className="price-item bg-gray">
                                              <span>2 Request</span>
                                          </div>
                                          <div className="price-item">
                                              <span>4 Request</span>
                                          </div>
                                      </div>

                                      <div className="price-bodyItems">
                                          <div className="price-bodyTitle">
                                              Your projects alway be priority
                                          </div>
                                          <div className="price-item">
                                          </div>
                                          <div className="price-item bg-gray">
                                              <FontAwesomeIcon icon={ faCheck } />
                                          </div>
                                          <div className="price-item">
                                              <FontAwesomeIcon icon={ faCheck } />
                                          </div>
                                      </div>

                                      <div className="price-bodyItems">
                                          <div className="price-bodyTitle">
                                              Google Analysis
                                          </div>
                                          <div className="price-item">
                                              <span>$5/Year</span>
                                          </div>
                                          <div className="price-item bg-gray">
                                              <FontAwesomeIcon icon={ faCheck } />
                                          </div>
                                          <div className="price-item">
                                              <FontAwesomeIcon icon={ faCheck } />
                                          </div>
                                      </div>

                                      <div className="price-bodyItems">
                                          <div className="price-bodyTitle">
                                              Invoice, Tax Documentation included
                                          </div>
                                          <div className="price-item">
                                              <FontAwesomeIcon icon={ faCheck } />
                                          </div>
                                          <div className="price-item bg-gray">
                                              <FontAwesomeIcon icon={ faCheck } />
                                          </div>
                                          <div className="price-item">
                                              <FontAwesomeIcon icon={ faCheck } />
                                          </div>
                                      </div>

                                      <div className="price-bodyItems">
                                          <div className="price-bodyTitle">
                                              NDA Agreement included
                                          </div>
                                          <div className="price-item">
                                              <FontAwesomeIcon icon={ faCheck } />
                                          </div>
                                          <div className="price-item bg-gray">
                                              <FontAwesomeIcon icon={ faCheck } />
                                          </div>
                                          <div className="price-item">
                                              <FontAwesomeIcon icon={ faCheck } />
                                          </div>
                                      </div>

                                      <div className="price-bodyItems">
                                          <div className="price-bodyTitle">
                                              Customer Care Gift <FontAwesomeIcon icon={ faCircleInfo } />
                                          </div>
                                          <div className="price-item">
                                          </div>
                                          <div className="price-item bg-gray">
                                          </div>
                                          <div className="price-item">
                                              <FontAwesomeIcon icon={ faCheck } />
                                          </div>
                                      </div>
                                  </div>

                                  <div className="price-foot">
                                      <div className="price-footTitle">
                                      </div>
                                      <div className="price-foot-item">
                                          <a href="#0" className="butn butn-md butn-bord butn-rounded">
                                              <div className="d-flex align-items-center">
                                                  <span>Pick This Package</span>
                                                  <span className="ml-10">
                                                    <FontAwesomeIcon icon={ faChevronRight } />
                                                </span>
                                              </div>
                                          </a>
                                      </div>
                                      <div className="price-foot-item bg-gray">
                                          <a href="#0" className="butn butn-md butn-bg butn-rounded">
                                              <div className="d-flex align-items-center">
                                                  <span>Pick This Package</span>
                                                  <span className="ml-10">
                                                    <FontAwesomeIcon icon={ faChevronRight } />
                                                </span>
                                              </div>
                                          </a>
                                      </div>
                                      <div className="price-foot-item">
                                          <a href="#0" className="butn butn-md butn-bord butn-rounded">
                                              <div className="d-flex align-items-center">
                                                  <span>Pick This Package</span>
                                                  <span className="ml-10">
                                                    <FontAwesomeIcon icon={ faChevronRight } />
                                                </span>
                                              </div>
                                          </a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="botm mt-70">
                            <span>Not find your plan! Want to setup a tailor-plan for only you? <a href="#0">Contact
                                    Us</a></span>
                          </div>
                      </div>
                  </section>

                  <section className="faqs-ds section-padding pt-0">
                      <div className="container">
                          <div className="sec-head text-center mb-70">
                              <h2 className="text">Ask Us <span>Anything</span></h2>
                          </div>
                          <div className="row justify-content-center">
                              <div className="col-lg-8">
                                  <div className="accordion" id="accordionExample">
                                      <div className="accordion-item">
                                          <h2 className="accordion-header" id="heading1">
                                              <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                      data-bs-target="#collapse1" aria-expanded="true"
                                                      aria-controls="collapse1">
                                                  Why’s Technofolio instead of full-time designer?
                                              </button>
                                          </h2>
                                          <div id="collapse1" className="accordion-collapse collapse"
                                               aria-labelledby="heading1" data-bs-parent="#accordionExample">
                                              <div className="accordion-body">
                                                  <p>How quick is quick? For most design, we’re talking 2-3 business
                                                      days.
                                                      We balance speed with quality,ensuring you get top-north design
                                                      swiftly</p>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="accordion-item active">
                                          <h2 className="accordion-header" id="heading2">
                                              <button className="accordion-button collapsed" type="button"
                                                      data-bs-toggle="collapse" data-bs-target="#collapse2"
                                                      aria-expanded="false" aria-controls="collapse2">
                                                  Speed of design delivery?
                                              </button>
                                          </h2>
                                          <div id="collapse2" className="accordion-collapse collapse show"
                                               aria-labelledby="heading2" data-bs-parent="#accordionExample">
                                              <div className="accordion-body">
                                                  <p>How quick is quick? For most design, we’re talking 2-3 business
                                                      days.
                                                      We balance speed with quality,ensuring you get top-north design
                                                      swiftly</p>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="accordion-item">
                                          <h2 className="accordion-header" id="heading3">
                                              <button className="accordion-button collapsed" type="button"
                                                      data-bs-toggle="collapse" data-bs-target="#collapse3"
                                                      aria-expanded="false" aria-controls="collapse3">
                                                  What’s the Technofolio progress like?
                                              </button>
                                          </h2>
                                          <div id="collapse3" className="accordion-collapse collapse"
                                               aria-labelledby="heading3" data-bs-parent="#accordionExample">
                                              <div className="accordion-body">
                                                  <p>How quick is quick? For most design, we’re talking 2-3 business
                                                      days.
                                                      We balance speed with quality,ensuring you get top-north design
                                                      swiftly</p>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="accordion-item">
                                          <h2 className="accordion-header" id="heading4">
                                              <button className="accordion-button collapsed" type="button"
                                                      data-bs-toggle="collapse" data-bs-target="#collapse4"
                                                      aria-expanded="false" aria-controls="collapse4">
                                                  How to request a design?
                                              </button>
                                          </h2>
                                          <div id="collapse4" className="accordion-collapse collapse"
                                               aria-labelledby="heading4" data-bs-parent="#accordionExample">
                                              <div className="accordion-body">
                                                  <p>How quick is quick? For most design, we’re talking 2-3 business
                                                      days.
                                                      We balance speed with quality,ensuring you get top-north design
                                                      swiftly</p>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="accordion-item">
                                          <h2 className="accordion-header" id="heading5">
                                              <button className="accordion-button collapsed" type="button"
                                                      data-bs-toggle="collapse" data-bs-target="#collapse5"
                                                      aria-expanded="false" aria-controls="collapse5">
                                                  In which program do you create designs?
                                              </button>
                                          </h2>
                                          <div id="collapse5" className="accordion-collapse collapse"
                                               aria-labelledby="heading5" data-bs-parent="#accordionExample">
                                              <div className="accordion-body">
                                                  <p>How quick is quick? For most design, we’re talking 2-3 business
                                                      days.
                                                      We balance speed with quality,ensuring you get top-north design
                                                      swiftly</p>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="accordion-item">
                                          <h2 className="accordion-header" id="heading6">
                                              <button className="accordion-button collapsed" type="button"
                                                      data-bs-toggle="collapse" data-bs-target="#collapse6"
                                                      aria-expanded="false" aria-controls="collapse6">
                                                  What if i don’t like design?
                                              </button>
                                          </h2>
                                          <div id="collapse6" className="accordion-collapse collapse"
                                               aria-labelledby="heading6" data-bs-parent="#accordionExample">
                                              <div className="accordion-body">
                                                  <p>How quick is quick? For most design, we’re talking 2-3 business
                                                      days.
                                                      We balance speed with quality,ensuring you get top-north design
                                                      swiftly</p>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="accordion-item">
                                          <h2 className="accordion-header" id="heading7">
                                              <button className="accordion-button collapsed" type="button"
                                                      data-bs-toggle="collapse" data-bs-target="#collapse7"
                                                      aria-expanded="false" aria-controls="collapse7">
                                                  Are there any refunds?
                                              </button>
                                          </h2>
                                          <div id="collapse7" className="accordion-collapse collapse"
                                               aria-labelledby="heading7" data-bs-parent="#accordionExample">
                                              <div className="accordion-body">
                                                  <p>How quick is quick? For most design, we’re talking 2-3 business
                                                      days.
                                                      We balance speed with quality,ensuring you get top-north design
                                                      swiftly</p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="text-center mt-70">
                              <a href="/about" className="butn butn-md butn-bord butn-rounded mt-40">
                                  <div className="d-flex align-items-center">
                                      <span>See All Questions</span>
                                      <span className="ml-10">
                                        <FontAwesomeIcon icon={ faChevronRight } />
                                    </span>
                                  </div>
                              </a>
                          </div>
                      </div>
                  </section>

                  <section className="team-ds">
                      <div className="container section-padding bord-thin-top-light pb-80">
                          <div className="sec-head mb-70 d-flex align-items-center">
                              <div>
                                  <h2 className="text">Our <span>Members</span></h2>
                              </div>
                              <div className="ml-auto">
                                  <a href="/ourservices" className="butn butn-md butn-bord butn-rounded">
                                      <div className="d-flex align-items-center">
                                          <span>See Positions</span>
                                          <span className="ml-10">
                                            <FontAwesomeIcon icon={ faChevronRight } />
                                        </span>
                                      </div>
                                  </a>
                              </div>
                          </div>
                          <div className="row">
                              <div className="col-lg-3 col-md-6">
                                  <div className="item">
                                      <div className="img">
                                          <img loading={"lazy"} src={team1} alt=""/>
                                      </div>
                                      <div className="cont">
                                          <h6>Logan Dang</h6>
                                          <span>CEO Founder</span>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                  <div className="item">
                                      <div className="img">
                                          <img loading={"lazy"} src={team2} alt=""/>
                                      </div>
                                      <div className="cont">
                                          <h6>Brian Hanley</h6>
                                          <span>CTO & CO Founder</span>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                  <div className="item">
                                      <div className="img">
                                          <img loading={"lazy"} src={team3} alt=""/>
                                      </div>
                                      <div className="cont">
                                          <h6>Luke Van de Berg</h6>
                                          <span>Senior Product Management</span>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                  <div className="item">
                                      <div className="img">
                                          <img loading={"lazy"} src={team4} alt=""/>
                                      </div>
                                      <div className="cont">
                                          <h6>Rahat Chowhury</h6>
                                          <span>Marketing Director</span>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                  <div className="item">
                                      <div className="img">
                                          <img loading={"lazy"} src={team5} alt=""/>
                                      </div>
                                      <div className="cont">
                                          <h6>Thomas Finlan</h6>
                                          <span>UI/UX Designer</span>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                  <div className="item">
                                      <div className="img">
                                          <img loading={"lazy"} src={team6} alt=""/>
                                      </div>
                                      <div className="cont">
                                          <h6>Danny Roster</h6>
                                          <span>React Developer</span>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                  <div className="item">
                                      <div className="numb">
                                          <h3>+12</h3>
                                      </div>
                                      <div className="cont">
                                          <h6>Other Members</h6>
                                          <span>
                                            <a href="/ourservices">See All Our Member</a>
                                        </span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </section>
                  <section className="blog-ds section-padding">
                      <div className="container">
                          <div className="sec-head mb-70 d-flex align-items-center">
                              <div>
                                  <h2 className="text">Our <span>Articles</span></h2>
                              </div>
                              <div className="ml-auto">
                                  <a href="#" className="butn butn-md butn-bord butn-rounded">
                                      <div className="d-flex align-items-center">
                                          <span>See All Articles</span>
                                          <span className="ml-10">
                                            <FontAwesomeIcon icon={ faChevronRight } />
                                        </span>
                                      </div>
                                  </a>
                              </div>
                          </div>
                          <div className="row">
                              <div className="col-lg-4">
                                  <div className="item pt-30 bord-thin-top-light md-mb50">
                                      <div className="info d-flex align-items-center mb-20">
                                          <a href="#0" className="tag">
                                              <span>Experience</span>
                                          </a>
                                          <span className="dash">/</span>
                                          <a href="#0" className="date">
                                              <span>May 15, 2024</span>
                                          </a>
                                      </div>
                                      <div className="text mb-50">
                                          <h6>How to build work culture for <br/> young office?</h6>
                                      </div>
                                      <div className="img">
                                          <img loading={"lazy"} src={blog1} alt=""/>
                                              <a href="#" className="invert">
                                                  <img loading={"lazy"} src={arrowtopright} alt=""/>
                                              </a>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-4">
                                  <div className="item pt-30 bord-thin-top-light md-mb50">
                                      <div className="info d-flex align-items-center mb-20">
                                          <a href="#0" className="tag">
                                              <span>Design Trends</span>
                                          </a>
                                          <span className="dash">/</span>
                                          <a href="#0" className="date">
                                              <span>May 1, 2024</span>
                                          </a>
                                      </div>
                                      <div className="text mb-50">
                                          <h6>Technofolio - Winner SOTY at CSS <br/> Winner 2023 with Zumar project</h6>
                                      </div>
                                      <div className="img">
                                          <img loading={"lazy"} src={blog2} alt=""/>
                                              <a href="#" className="invert">
                                                  <img loading={"lazy"} src={arrowtopright} alt=""/>
                                              </a>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-4">
                                  <div className="item pt-30 bord-thin-top-light">
                                      <div className="info d-flex align-items-center mb-20">
                                          <a href="#0" className="tag">
                                              <span>Tips & Tricks</span>
                                          </a>
                                          <span className="dash">/</span>
                                          <a href="#0" className="date">
                                              <span>April 24, 2024</span>
                                          </a>
                                      </div>
                                      <div className="text mb-50">
                                          <h6>Rebrand vs Refesh: 10 Minutes On Brand by Technofolio</h6>
                                      </div>
                                      <div className="img">
                                          <img loading={"lazy"} src={blog3} alt=""/>
                                              <a href="#" className="invert">
                                                  <img loading={"lazy"} src={arrowtopright} alt=""/>
                                              </a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </section>
              </main>
              <footer className="footer-ds bord-thin-top-light">
                  <div className="container section-padding">
                      <div className="row justify-content-between">
                          <div className="col-lg-4">
                              <div className="subscribe md-mb50">
                                  <h6>Be the first to get the latest news <br/> about trends, inspiration & more</h6>
                                  <div className="form-group">
                                      <label for="email_subscribe">Email Address</label>
                                      <input id="email_subscribe" type="email" placeholder="Enter your email address"
                                             name="email_subscribe" />
                                          <button type="submit" className="invert">
                                              <img loading={"lazy"} src={paperplane} alt=""/>
                                          </button>
                                  </div>
                                  <span>By subscribing, you’re accept our <a href="#0">Privacy Policy</a></span>
                              </div>
                          </div>
                          <div className="col-lg-6">
                              <div className="contact-info">
                                  <div className="row lg-marg">
                                      <div className="col-md-6">
                                          <div className="item mb-60">
                                              <h6>Work Inquiry</h6>
                                              <p>contact@Technofolio.agency</p>

                                          </div>
                                      </div>
                                      <div className="col-md-6">
                                          <div className="item mb-60">
                                              <h6>Open Position</h6>
                                              <p>Senior Front-end Wordpress Developer</p>
                                              <p>UI/UX Designer (Remote)</p>
                                          </div>
                                      </div>
                                      <div className="col-md-6">
                                          <div className="item sm-mb60">
                                              <h6>Mahattan, NY</h6>
                                              <p>152 Thatcher Road St, Mahattan NY 10463 United States</p>
                                          </div>
                                      </div>
                                      <div className="col-md-6">
                                          <div className="item">
                                              <h6>Links</h6>
                                              <p><a href="#0">Terms & Conditions</a></p>
                                              <p><a href="#0">Privacy Policy</a></p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="sub-footer bord-thin-top-light pt-50 pb-50">
                      <div className="container">
                          <div className="row">
                              <div className="col-lg-8">
                                  <div className="copy d-flex align-items-center md-mb30">
                                      <div>
                                          <div className="logo">
                                              <img loading={"lazy"} src={favicon} alt=""/>
                                          </div>
                                      </div>
                                      <div className="ml-50">
                                          <p>© 2024 <a href="#0">Technopresso Group</a>. <br/> All Right Reserved</p>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-4 d-flex justify-content-end">
                                  <div className="links sub-color d-flex justify-content-between">
                                      <a href="/" className="active">Home</a>
                                      <a href="/ourservices">Works</a>
                                      <a href="/about">About</a>
                                      <a href="/blog">News</a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </footer>
          </div>
      </div>
  </div>
)
}
