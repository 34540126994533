import React, { Component } from 'react'
import "../scss/Navbar.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBars } from '@fortawesome/free-solid-svg-icons';

//react-router-dom
import { NavLink } from "react-router-dom";

import LngButton from "./LngButton";

class Navbar extends Component {
    state = { clicked: false};
    handleClick = () => {
        this.setState({clicked:!this.state.clicked})
    }
    render() {

    return (
    <>
        <nav>
            <a href="/">
                <picture>
                    <source srcSet="/banner-transparent-small.webp" type="image/webp"/>
                    <img className="navbar-logo" loading={"lazy"} style={{width: 250, height: "auto"}} src="/banner-transparent.webp" alt='Technopresso Official Logo'/>
                </picture>
            </a>
            <div>
                <ul id='navbar' className={this.state.clicked ? "#navbar active" : "#navbar"}>
                <li><NavLink to="/" spy="true" smooth="true" offset={50} duration={500}>Home</NavLink></li>
                    <li><NavLink to="/aboutus" spy="true" smooth="true" offset={50} duration={500}>About</NavLink></li>
                    <li><NavLink to="/ourservices" spy="true" smooth="true" offset={50} duration={500}>Service</NavLink></li>
                    <li><NavLink to="/allCollection" spy="true" smooth="true" offset={50} duration={500}>Collections</NavLink></li>
                    <li><NavLink to="/contactus" spy="true" smooth="true" offset={50} duration={500}>Contact</NavLink></li>
                    <li><a href="https://blog.technopresso.com/" target="_blank" rel="noopener noreferrer">Blog</a></li>
                    <li><LngButton/></li>
                </ul>
            </div>

            <div id='mobile' onClick={ this.handleClick }>
                <FontAwesomeIcon id='bar' className="icon" icon={ this.state.clicked ? faTimes : faBars }/>
            </div>
        </nav>
    </>
  )
}
}

export default Navbar