import "../../scss/Portfolio5.scss"
import "../../scss/common_style.css"

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faStar, faChevronRight, faClock} from '@fortawesome/free-solid-svg-icons';
import {
    faFacebook,
    faTwitter,
    faInstagram,
    faLinkedin
} from '@fortawesome/free-brands-svg-icons';

import logo from "../../imgs/logo.svg";
import circletext from "../../imgs/portfolio5/cirle-text.svg";
import union from "../../imgs/portfolio5/union.svg";

import blog1 from "../../imgs/portfolio5/blog/1.jpg";
import blog2 from "../../imgs/portfolio5/blog/2.jpg";
import blog3 from "../../imgs/portfolio5/blog/3.jpg";
import blog4 from "../../imgs/portfolio5/blog/a1.jpg";
import blog5 from "../../imgs/portfolio5/blog/a2.jpg";

import brands1 from "../../imgs/portfolio5/brands/archin.svg";
import brands2 from "../../imgs/portfolio5/brands/Brisklabs.svg";
import brands3 from "../../imgs/portfolio5/brands/Github.svg";
import brands4 from "../../imgs/portfolio5/brands/Mathew Coo.svg";
import brands5 from "../../imgs/portfolio5/brands/ODA Group.svg";
import brands6 from "../../imgs/portfolio5/brands/RogerArk.svg";
import brands7 from "../../imgs/portfolio5/brands/Udix.svg";
import brands8 from "../../imgs/portfolio5/brands/ZumarCons.svg";

import header from "../../imgs/portfolio5/header/brush.svg";

import process1 from "../../imgs/portfolio5/process/1.jpg";
import process2 from "../../imgs/portfolio5/process/2.jpg";
import process3 from "../../imgs/portfolio5/process/3.jpg";
import process4 from "../../imgs/portfolio5/process/4.jpg";

import serv1 from "../../imgs/portfolio5/serv/1.jpg";
import serv2 from "../../imgs/portfolio5/serv/2.jpg";
import serv3 from "../../imgs/portfolio5/serv/3.jpg";

import team1 from "../../imgs/portfolio5/team/1.png";
import team2 from "../../imgs/portfolio5/team/2.png";
import team3 from "../../imgs/portfolio5/team/3.png";
import team4 from "../../imgs/portfolio5/team/4.png";

import testimavatar1 from "../../imgs/portfolio5/testim/avatar1.jpg";
import testimavatar2 from "../../imgs/portfolio5/testim/avatar2.jpg";
import testimavatar3 from "../../imgs/portfolio5/testim/avatar3.jpg";
import testimavatar4 from "../../imgs/portfolio5/testim/avatar4.jpg";
import testimavatar6 from "../../imgs/portfolio5/testim/logo-clutch.svg";

import works1 from "../../imgs/portfolio5/works/1.webp";
import works2 from "../../imgs/portfolio5/works/2.jpg";
import works3 from "../../imgs/portfolio5/works/3.jpg";
import works4 from "../../imgs/portfolio5/works/4.jpg";
import works5 from "../../imgs/portfolio5/works/5.jpg";
import works6 from "../../imgs/portfolio5/works/6.jpg";
import works7 from "../../imgs/portfolio5/works/7.jpg";
import works8 from "../../imgs/portfolio5/works/8.jpg";
import works9 from "../../imgs/portfolio5/works/9.jpg";

import React from "react";


export default function Collection() {
  return (
      <div className='portfolio5'>
          <div className="hamenu">
              <div className="close-menu cursor-pointer ti-close"></div>
              <div className="container-fluid rest d-flex">
                  <div className="menu-links">
                      <ul className="main-menu rest">
                          <li>
                              <div className="o-hidden">
                                  <div className="link cursor-pointer dmenu"><span className="fill-text"
                                                                                   data-text="Home">Home</span>
                                      <i></i>
                                  </div>
                              </div>
                              <div className="sub-menu">
                                  <ul>
                                      <li>
                                          <a href="../startup_agency/index.html" className="sub-link">Startup Agency</a>
                                      </li>
                                      <li>
                                          <a href="../creative-studio/index.html" className="sub-link">Creative
                                              Studio</a>
                                      </li>
                                      <li>
                                          <a href="../creative_agency/index.html" className="sub-link">Creative
                                              Agency</a>
                                      </li>
                                      <li>
                                          <a href="../digital-marketing/index.html" className="sub-link">Digital
                                              Marketing</a>
                                      </li>
                                      <li>
                                          <a href="../modern_portfolio/index.html" className="sub-link">Modern
                                              Portfolio</a>
                                      </li>
                                      <li>
                                          <a href="../digital_studio/index.html" className="sub-link">Digital Studio</a>
                                      </li>
                                      <li>
                                          <a href="../modern_agency/index.html" className="sub-link">Modern Agency</a>
                                      </li>
                                      <li>
                                          <a href="../creative_agency2/index.html" className="sub-link">Creative Agency
                                              2</a>
                                      </li>
                                  </ul>
                              </div>
                          </li>
                          <li>
                              <div className="o-hidden">
                                  <div className="link cursor-pointer dmenu"><span className="fill-text"
                                                                                   data-text="Pages">Pages</span>
                                      <i></i>
                                  </div>
                              </div>
                              <div className="sub-menu">
                                  <ul>
                                      <li>
                                          <a href="../inner_pages/about.html" className="sub-link">About Us</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/services.html" className="sub-link">Our Services</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/service-details.html" className="sub-link">Services
                                              Details</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/team.html" className="sub-link">Our Team</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/pricing.html" className="sub-link">Pricing</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/faqs.html" className="sub-link">FAQS</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/contact.html" className="sub-link">Contact Us</a>
                                      </li>
                                  </ul>
                              </div>
                          </li>
                          <li>
                              <div className="o-hidden">
                                  <div className="link cursor-pointer dmenu"><span className="fill-text"
                                                                                   data-text="Portfolio">Portfolio</span>
                                      <i></i>
                                  </div>
                              </div>
                              <div className="sub-menu">
                                  <ul>
                                      <li>
                                          <a href="../inner_pages/portfolio-standard.html"
                                             className="sub-link">Standard</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/portfolio-gallery.html"
                                             className="sub-link">Gallery</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/portfolio-cards.html" className="sub-link">Card</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/portfolio-layout2.html" className="sub-link">Grid 2
                                              Column</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/portfolio-layout3.html" className="sub-link">Gid 3
                                              Column</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/portfolio-layout4.html" className="sub-link">Grid 4
                                              Column</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/project-details.html" className="sub-link">Project
                                              Details</a>
                                      </li>
                                  </ul>
                              </div>
                          </li>
                          <li>
                              <div className="o-hidden">
                                  <div className="link cursor-pointer dmenu"><span className="fill-text"
                                                                                   data-text="Blog">Blog</span>
                                      <i></i>
                                  </div>
                              </div>
                              <div className="sub-menu">
                                  <ul>
                                      <li>
                                          <a href="../inner_pages/blog-grid.html" className="sub-link">Blog Grid</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/blog-standard.html" className="sub-link">Blog
                                              Standard</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/blog-details.html" className="sub-link">Blog
                                              Details</a>
                                      </li>
                                  </ul>
                              </div>
                          </li>
                          <li>
                              <div className="o-hidden">
                                  <a href="../inner_pages/contact.html" className="link"><span className="fill-text"
                                                                                               data-text="Contact Us">Contact Us</span></a>
                              </div>
                          </li>
                      </ul>
                  </div>
                  <div className="cont-info valign">
                      <div className="text-center full-width">
                          <div className="logo">
                              <img loading={"lazy"} src={logo} alt=""/>
                          </div>
                          <div className="social-icon mt-40">
                              <a href="#"> <FontAwesomeIcon icon={faFacebook}/> </a>
                              <a href="#"> <FontAwesomeIcon icon={faTwitter}/> </a>
                              <a href="#"> <FontAwesomeIcon icon={faLinkedin}/> </a>
                              <a href="#"> <FontAwesomeIcon icon={faInstagram}/> </a>
                          </div>
                          <div className="item mt-30">
                              <h5>541 Melville Geek, <br/> Palo Alto, CA 94301</h5>
                          </div>
                          <div className="item mt-10">
                              <h5><a href="#0">Hello@email.com</a></h5>
                              <h5 className="underline"><a href="#0">+1 840 841 25 69</a></h5>
                          </div>
                      </div>
                  </div>
              </div>
          </div>


          <div id="smooth-wrapper">


              <div id="smooth-content">

                  <main>



                      <section className="box-light">

                          <div className="layers"></div>

                          <header className="header-mp">
                              <div className="container ontop">
                                  <div className="row justify-content-between">
                                      <div className="col-lg-4">
                                          <div className="text md-mb50">
                                              <h6 className="sub-head">We’re a digital products design and development
                                                  agency that
                                                  passionate with the digital experiences.</h6>
                                              <a href="#0" className="butn butn-md butn-bord butn-rounded mt-40">
                                                  <div className="d-flex align-items-center">
                                                      <span>About Us</span>
                                                      <span className="ml-10">
                                                    <FontAwesomeIcon icon={ faChevronRight } />
                                                </span>
                                                  </div>
                                              </a>
                                          </div>
                                      </div>
                                      <div className="col-lg-5">
                                          <div className="numb row">
                                              <div className="col-md-6 sm-mb30">
                                                  <h2>98%</h2>
                                                  <span>Clients satisfied and repeating</span>
                                              </div>
                                              <div className="col-md-6 d-flex justify-content-end justify-end">
                                                  <div>
                                                      <h2>125+</h2>
                                                      <span>projects completed in 24 countries</span>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="caption">
                                      <h1><span>Tec</span>folio</h1>
                                      <div className="union sm-hide">
                                          <img loading={"lazy"} src={circletext} alt=""/>
                                          <div className="icon">
                                              <img loading={"lazy"} src={union} alt=""/>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <span className="brush">
                            <img loading={"lazy"} src={header} alt=""/>
                        </span>
                          </header>

                          <div className="works-mp section-padding pt-40">
                              <div className="container">
                                  <div className="sec-head mb-80">
                                      <div className="row">
                                          <div className="col-lg-6">
                                              <h2><span>Featured</span> Works</h2>
                                          </div>
                                          <div className="col-lg-5 d-flex align-items-center">
                                              <div className="ml-auto">
                                            <span className="sub-head">Our user-centered design encourages <br/> productivity
                                                &
                                                boosts revenue.</span>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="row md-marg gallery">
                                      <div className="col-lg-4 items">
                                          <div className="item">
                                              <div className="cont d-flex align-items-center">
                                                  <div>
                                                      <h5>Swat Co.</h5>
                                                      <span>branding, ui/ux design</span>
                                                  </div>
                                                  <div className="ml-auto">
                                                      <a href="../inner_pages/project-details.html">
                                                          <FontAwesomeIcon icon={ faChevronRight } />
                                                      </a>
                                                  </div>
                                              </div>
                                              <div className="img">
                                                  <img loading={"lazy"} src={works1} alt=""/>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-4 items">
                                          <div className="item">
                                              <div className="cont d-flex align-items-center">
                                                  <div>
                                                      <h5>ZumaCons Firm</h5>
                                                      <span>branding, ui/ux design, <br/> motion, seo</span>
                                                  </div>
                                                  <div className="ml-auto">
                                                      <a href="../inner_pages/project-details.html">
                                                          <FontAwesomeIcon icon={ faChevronRight } />
                                                      </a>
                                                  </div>
                                              </div>
                                              <div className="img">
                                                  <img loading={"lazy"} src={works2} alt=""/>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-4 items">
                                          <div className="item">
                                              <div className="cont d-flex align-items-center">
                                                  <div>
                                                      <h5>Newz Magazine Site</h5>
                                                      <span>ui/ux design, Illustration <br/> photography,</span>
                                                  </div>
                                                  <div className="ml-auto">
                                                      <a href="../inner_pages/project-details.html">
                                                          <FontAwesomeIcon icon={ faChevronRight } />
                                                      </a>
                                                  </div>
                                              </div>
                                              <div className="img">
                                                  <img loading={"lazy"} src={works3} alt=""/>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-4 items">
                                          <div className="item">
                                              <div className="cont d-flex align-items-center">
                                                  <div>
                                                      <h5>Archin</h5>
                                                      <span>branding, ui/ux design, <br/> motion, seo</span>
                                                  </div>
                                                  <div className="ml-auto">
                                                      <a href="../inner_pages/project-details.html">
                                                          <FontAwesomeIcon icon={ faChevronRight } />
                                                      </a>
                                                  </div>
                                              </div>
                                              <div className="img">
                                                  <img loading={"lazy"} src={works4} alt=""/>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-4 items">
                                          <div className="item">
                                              <div className="cont d-flex align-items-center">
                                                  <div>
                                                      <h5>Jorger Clarkson</h5>
                                                      <span>UI/UX Designm Landing Page, <br/> Webflow, Framer</span>
                                                  </div>
                                                  <div className="ml-auto">
                                                      <a href="../inner_pages/project-details.html">
                                                          <FontAwesomeIcon icon={ faChevronRight } />
                                                      </a>
                                                  </div>
                                              </div>
                                              <div className="img">
                                                  <img loading={"lazy"} src={works6} alt=""/>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-4 items">
                                          <div className="item">
                                              <div className="cont d-flex align-items-center">
                                                  <div>
                                                      <h5>LW Rebrand</h5>
                                                      <span>branding, rebrand, postion <br/> strategy, animation</span>
                                                  </div>
                                                  <div className="ml-auto">
                                                      <a href="../inner_pages/project-details.html">
                                                          <FontAwesomeIcon icon={ faChevronRight } />
                                                      </a>
                                                  </div>
                                              </div>
                                              <div className="img">
                                                  <img loading={"lazy"} src={works5} alt=""/>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-4 items">
                                          <div className="item">
                                              <div className="cont d-flex align-items-center">
                                                  <div>
                                                      <h5>Boat News</h5>
                                                      <span>UI/Ux Design, Webflow</span>
                                                  </div>
                                                  <div className="ml-auto">
                                                      <a href="../inner_pages/project-details.html">
                                                          <FontAwesomeIcon icon={ faChevronRight } />
                                                      </a>
                                                  </div>
                                              </div>
                                              <div className="img">
                                                  <img loading={"lazy"} src={works9} alt=""/>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-4 items">
                                          <div className="item">
                                              <div className="cont d-flex align-items-center">
                                                  <div>
                                                      <h5>The Unerio</h5>
                                                      <span>ui/ux design, mobile app, <br/> motion, seo</span>
                                                  </div>
                                                  <div className="ml-auto">
                                                      <a href="../inner_pages/project-details.html">
                                                          <FontAwesomeIcon icon={ faChevronRight } />
                                                      </a>
                                                  </div>
                                              </div>
                                              <div className="img">
                                                  <img loading={"lazy"} src={works7} alt=""/>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-4 items">
                                          <div className="item">
                                              <div className="cont d-flex align-items-center">
                                                  <div>
                                                      <h5>Arc Garden</h5>
                                                      <span>branding, Graphic Design</span>
                                                  </div>
                                                  <div className="ml-auto">
                                                      <a href="../inner_pages/project-details.html">
                                                          <FontAwesomeIcon icon={ faChevronRight } />
                                                      </a>
                                                  </div>
                                              </div>
                                              <div className="img">
                                                  <img loading={"lazy"} src={works8} alt=""/>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div className="marq-mp">
                              <div className="main-marq color-bg shadow-off">
                                  <div className="slide-har st1">
                                      <div className="box">
                                          <div className="item">
                                              <h5>Top-notch Experts</h5>
                                          </div>
                                          <div className="item">
                                              <h5>Dedicated Support 24/7</h5>
                                          </div>
                                          <div className="item">
                                              <h5>Flexible Pricing</h5>
                                          </div>
                                          <div className="item">
                                              <h5>Top-notch Experts</h5>
                                          </div>
                                          <div className="item">
                                              <h5>AI-Driven Solutions</h5>
                                          </div>
                                          <div className="item">
                                              <h5>Flexiable Price</h5>
                                          </div>
                                      </div>
                                      <div className="box">
                                          <div className="item">
                                              <h5>Top-notch Experts</h5>
                                          </div>
                                          <div className="item">
                                              <h5>Dedicated Support 24/7</h5>
                                          </div>
                                          <div className="item">
                                              <h5>Flexible Pricing</h5>
                                          </div>
                                          <div className="item">
                                              <h5>Top-notch Experts</h5>
                                          </div>
                                          <div className="item">
                                              <h5>AI-Driven Solutions</h5>
                                          </div>
                                          <div className="item">
                                              <h5>Flexiable Price</h5>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="main-marq gray-bg shadow-off">
                                  <div className="slide-har st2">
                                      <div className="box">
                                          <div className="item">
                                              <h5>Top-notch Experts</h5>
                                          </div>
                                          <div className="item">
                                              <h5>Dedicated Support 24/7</h5>
                                          </div>
                                          <div className="item">
                                              <h5>Flexible Pricing</h5>
                                          </div>
                                          <div className="item">
                                              <h5>Top-notch Experts</h5>
                                          </div>
                                          <div className="item">
                                              <h5>AI-Driven Solutions</h5>
                                          </div>
                                          <div className="item">
                                              <h5>Flexiable Price</h5>
                                          </div>
                                      </div>
                                      <div className="box">
                                          <div className="item">
                                              <h5>Top-notch Experts</h5>
                                          </div>
                                          <div className="item">
                                              <h5>Dedicated Support 24/7</h5>
                                          </div>
                                          <div className="item">
                                              <h5>Flexible Pricing</h5>
                                          </div>
                                          <div className="item">
                                              <h5>Top-notch Experts</h5>
                                          </div>
                                          <div className="item">
                                              <h5>AI-Driven Solutions</h5>
                                          </div>
                                          <div className="item">
                                              <h5>Flexiable Price</h5>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div className="services-mp section-padding">
                              <div className="container">
                                  <div className="sec-head mb-80">
                                      <div className="row">
                                          <div className="col-lg-7">
                                              <h2><span>Our</span> Services</h2>
                                          </div>
                                          <div className="col-lg-5 d-flex align-items-center">
                                              <div className="ml-auto">
                                            <span className="sub-head">As a tight-knit team of experts, we create memorable
                                                and
                                                emotional websites, digital experiences, and native apps.</span>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="row">
                                      <div className="col-lg-7 valign">
                                          <div className="serv-title md-mb50">
                                              <div className="tab-title current" data-tab="tab-1">
                                                  <h2>Design</h2>
                                              </div>
                                              <div className="tab-title" data-tab="tab-2">
                                                  <h2>Deployment</h2>
                                              </div>
                                              <div className="tab-title" data-tab="tab-3">
                                                  <h2>Growth</h2>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-5 content">
                                          <div id="tab-1" className="item current">
                                              <div className="img">
                                                  <img loading={"lazy"} src={serv1} alt=""/>
                                              </div>
                                              <div className="cont">
                                                  <div className="row">
                                                      <div className="col-sm-6">
                                                          <span>Branding identity</span>
                                                          <span>Strategy and planning</span>
                                                          <span>rebrand</span>
                                                          <span>pitchdesk</span>
                                                      </div>
                                                      <div className="col-sm-6">
                                                          <span>UI/UX Design</span>
                                                          <span>Wireframe & prototype</span>
                                                          <span>motion</span>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div id="tab-2" className="item">
                                              <div className="img">
                                                  <img loading={"lazy"} src={serv2} alt=""/>
                                              </div>
                                              <div className="cont">
                                                  <div className="row">
                                                      <div className="col-sm-6">
                                                          <span>website development</span>
                                                          <span>mobile app</span>
                                                          <span>ecommerce website</span>
                                                          <span>no-code website</span>
                                                      </div>
                                                      <div className="col-sm-6">
                                                          <span>landing page</span>
                                                          <span>plugins</span>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div id="tab-3" className="item">
                                              <div className="img">
                                                  <img loading={"lazy"} src={serv3} alt=""/>
                                              </div>
                                              <div className="cont">
                                                  <div className="row">
                                                      <div className="col-sm-6">
                                                          <span>Marketing Consult</span>
                                                          <span>SEO / PPC</span>
                                                          <span>Social Media</span>
                                                          <span>GG/ FB Ads</span>
                                                      </div>
                                                      <div className="col-sm-6">
                                                          <span>Affiliate</span>
                                                          <span>Email Marketing</span>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>

                      </section>

                      <section className="box-dark">

                          <div className="process-mp section-padding">
                              <div className="container">
                                  <div className="sec-head mb-80">
                                      <div className="row">
                                          <div className="col-lg-7">
                                              <h2 className="process-text"><span className="process-text">Process</span> Workflow</h2>
                                          </div>
                                          <div className="col-lg-5 d-flex align-items-center">
                                              <div className="ml-auto">
                                            <span className="sub-head process-text">As a tight-knit team of experts, we create memorable
                                                and
                                                emotional websites, digital experiences, and native apps.</span>
                                                  <a href="../inner_pages/contact.html"
                                                     className="butn butn-md butn-bord butn-rounded mt-40">
                                                      <div className="d-flex align-items-center">
                                                          <span className="process-text">Start Project</span>
                                                          <span className="ml-10">
                                                        <FontAwesomeIcon className="process-text" icon={ faChevronRight } />
                                                    </span>
                                                      </div>
                                                  </a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="swiper-container">
                                      <Swiper

                                          spaceBetween={10}
                                          slidesPerView={2}
                                          onSlideChange={() => console.log('slide change')}
                                          onSwiper={(swiper) => console.log(swiper)}
                                      >
                                          <SwiperSlide>
                                              <div className="swiper-slide">
                                                  <div className="item">
                                                      <div className="d-flex">
                                                          <div>
                                                              <div className="img fit-img">
                                                                  <img loading={"lazy"} src={process1} alt=""/>
                                                              </div>
                                                          </div>
                                                          <div className="text">
                                                              <p>We listen stories of user to understand pain points and
                                                                  give a
                                                                  rough estimate about cost and time-frame</p>
                                                          </div>
                                                      </div>
                                                      <div className="botm d-flex align-items-end">
                                                          <h3>Research</h3>
                                                          <div className="ml-auto">
                                                              <span>01</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>

                                          </SwiperSlide>
                                          <SwiperSlide>
                                              <div className="swiper-slide">
                                                  <div className="item">
                                                      <div className="d-flex">
                                                          <div>
                                                              <div className="img fit-img">
                                                                  <img loading={"lazy"} src={process2} alt=""/>
                                                              </div>
                                                          </div>
                                                          <div className="text">
                                                              <p>After wireframe got approved, we’ll build a prototype
                                                                  design to
                                                                  visually the idea. Client can easy to interactive with
                                                                  its</p>
                                                          </div>
                                                      </div>
                                                      <div className="botm d-flex align-items-end">
                                                          <h3>Concept</h3>
                                                          <div className="ml-auto">
                                                              <span>02</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </SwiperSlide>
                                          <SwiperSlide>
                                              <div className="swiper-slide">
                                                  <div className="item">
                                                      <div className="d-flex">
                                                          <div>
                                                              <div className="img fit-img">
                                                                  <img loading={"lazy"} src={process3} alt=""/>
                                                              </div>
                                                          </div>
                                                          <div className="text">
                                                              <p>Coding will implment at this step, by using combine new
                                                                  tech as
                                                                  Webflow, Wordpress, iOs, etc. We turn design into
                                                                  live</p>
                                                          </div>
                                                      </div>
                                                      <div className="botm d-flex align-items-end">
                                                          <h3>Implementation</h3>
                                                          <div className="ml-auto">
                                                              <span>03</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </SwiperSlide>
                                          <SwiperSlide>
                                              <div className="swiper-slide">
                                                  <div className="item">
                                                      <div className="d-flex">
                                                          <div>
                                                              <div className="img fit-img">
                                                                  <img loading={"lazy"} src={process4} alt=""/>
                                                              </div>
                                                          </div>
                                                          <div className="text">
                                                              <p>We’ll test carefully with A/B, accessiable, usability,
                                                                  etc
                                                                  to
                                                                  ensure best quality as possible before hand over to
                                                                  client</p>
                                                          </div>
                                                      </div>
                                                      <div className="botm d-flex align-items-end">
                                                          <h3>Testing</h3>
                                                          <div className="ml-auto">
                                                              <span>04</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </SwiperSlide>
                                      </Swiper>
                                  </div>
                              </div>
                          </div>

                          <div className="team-mp">
                              <div className="container section-padding bord-thin-top-light">
                                  <div className="sec-head mb-80">
                                      <div className="row">
                                          <div className="col-lg-7">
                                              <h2><span>Team’s</span> Leaders</h2>
                                          </div>
                                          <div className="col-lg-5 d-flex align-items-center">
                                              <div className="ml-auto">
                                            <span className="sub-head">Each products built by passionate hearts. It’s our
                                                team!, meet our leader</span>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="item mb-10">
                                      <div className="d-flex">
                                          <div>
                                              <div className="img fit-img">
                                                  <img loading={"lazy"} src={team1} alt=""/>
                                              </div>
                                          </div>
                                          <div className="cont">
                                              <h2>Jurgen Klopp</h2>
                                              <span>CEO Founder</span>
                                          </div>
                                          <div className="ml-auto">
                                              <div className="social-icon">
                                                  <a href="#"> <FontAwesomeIcon icon={faFacebook}/> </a>
                                                  <a href="#"> <FontAwesomeIcon icon={faTwitter}/> </a>
                                                  <a href="#"> <FontAwesomeIcon icon={faLinkedin}/> </a>
                                                  <a href="#"> <FontAwesomeIcon icon={faInstagram}/> </a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="item mb-10">
                                      <div className="d-flex">
                                          <div>
                                              <div className="img fit-img">
                                                  <img loading={"lazy"} src={team2} alt=""/>
                                              </div>
                                          </div>
                                          <div className="cont">
                                              <h2>Rahat Chowhury</h2>
                                              <span>CTO & Product Management</span>
                                          </div>
                                          <div className="ml-auto">
                                              <div className="social-icon">
                                                  <a href="#"> <FontAwesomeIcon icon={faFacebook}/> </a>
                                                  <a href="#"> <FontAwesomeIcon icon={faTwitter}/> </a>
                                                  <a href="#"> <FontAwesomeIcon icon={faLinkedin}/> </a>
                                                  <a href="#"> <FontAwesomeIcon icon={faInstagram}/> </a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="item mb-10">
                                      <div className="d-flex">
                                          <div>
                                              <div className="img fit-img">
                                                  <img loading={"lazy"} src={team3} alt=""/>
                                              </div>
                                          </div>
                                          <div className="cont">
                                              <h2>Luke Van de Berg</h2>
                                              <span>Marketing Director</span>
                                          </div>
                                          <div className="ml-auto">
                                              <div className="social-icon">
                                                  <a href="#"> <FontAwesomeIcon icon={faFacebook}/> </a>
                                                  <a href="#"> <FontAwesomeIcon icon={faTwitter}/> </a>
                                                  <a href="#"> <FontAwesomeIcon icon={faLinkedin}/> </a>
                                                  <a href="#"> <FontAwesomeIcon icon={faInstagram}/> </a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="item">
                                      <div className="d-flex">
                                          <div>
                                          <div className="img fit-img">
                                                  <img loading={"lazy"} src={team4} alt=""/>
                                              </div>
                                          </div>
                                          <div className="cont">
                                              <h2>Logan Dang</h2>
                                              <span>Creative Director</span>
                                          </div>
                                          <div className="ml-auto">
                                              <div className="social-icon">
                                                  <a href="#"> <FontAwesomeIcon icon={faFacebook}/> </a>
                                                  <a href="#"> <FontAwesomeIcon icon={faTwitter}/> </a>
                                                  <a href="#"> <FontAwesomeIcon icon={faLinkedin}/> </a>
                                                  <a href="#"> <FontAwesomeIcon icon={faInstagram}/> </a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>


                      </section>

                      <section className="box-light botm-box">

                      <div className="layers"></div>


                          <div className="testimonials-mp section-padding">
                              <div className="container-fluid">
                                  <div className="sec-head text-center mb-80">
                                      <h2><span>Client’s</span> reviews</h2>
                                      <div className="gl-rate d-flex align-items-center">
                                          <div className="icon">
                                              <img loading={"lazy"} src={testimavatar6} alt=""/>
                                          </div>
                                          <div className="cont">
                                              <h6>4.9/5
                                                  <span className="stars">
                                                <FontAwesomeIcon icon={ faStar } />
                                                          <FontAwesomeIcon icon={ faStar } />
                                                          <FontAwesomeIcon icon={ faStar } />
                                                          <FontAwesomeIcon icon={ faStar } />
                                                          <FontAwesomeIcon icon={ faStar } />
                                            </span>
                                              </h6>
                                              <p>Based on 24 reviews on <a href="#0">Clutch</a></p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="swiper testim-swiper swiper-container">
                                      <div className="swiper-wrapper">
                                          <div className="swiper-slide">
                                              <div className="item gray">
                                                  <div>
                                                      <h6>“A rebrand is not typically done in a chaotic, archaic
                                                          industry like
                                                          ours, so their work has really set us apart. 10/10 for Hub’s
                                                          team."
                                                      </h6>
                                                  </div>
                                                  <div className="info d-flex align-items-center mt-60">
                                                      <div className="info-text">
                                                          <p>CEO & Founder, Archin Studio</p>
                                                          <span>B. Gordon</span>
                                                      </div>
                                                      <div className="ml-auto">
                                                          <div className="img-author fit-img">
                                                              <img loading={"lazy"} src={testimavatar1} alt=""/>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="swiper-slide">
                                              <div className="item colorful">
                                                  <div>
                                                      <h6>“Thanks for the support of Technofolio team, who have been
                                                          with our
                                                          business for more than 3 years. A long journey with many
                                                          exciting
                                                          experiences and moments.”</h6>
                                                  </div>
                                                  <div className="info d-flex align-items-center mt-60">
                                                      <div className="info-text">
                                                          <p>Project Managerment at Zumar Inc</p>
                                                          <span>d. Szoboslai</span>
                                                      </div>
                                                      <div className="ml-auto">
                                                          <div className="img-author fit-img">
                                                              <img loading={"lazy"} src={testimavatar2} alt=""/>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="swiper-slide">
                                              <div className="item darklen">
                                                  <div>
                                                      <h6>“Technofolio ability to create a high quality user interface
                                                          stands
                                                          out. It’s something we placed a premium on.Recommended!.”</h6>
                                                  </div>
                                                  <div className="info d-flex align-items-center mt-60">
                                                      <div className="info-text">
                                                          <p>Marketing Manager, OKG</p>
                                                          <span>T. Morthy</span>
                                                      </div>
                                                      <div className="ml-auto">
                                                          <div className="img-author fit-img">
                                                              <img loading={"lazy"} src={testimavatar3} alt=""/>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="swiper-slide">
                                              <div className="item lighten">
                                                  <div>
                                                      <h6>"The Technofolio team truly amplified our messaging through
                                                          their
                                                          expert use of visuals."</h6>
                                                  </div>
                                                  <div className="info d-flex align-items-center mt-60">
                                                      <div className="info-text">
                                                          <p>President, Newz Jsc</p>
                                                          <span>J. Arron</span>
                                                      </div>
                                                      <div className="ml-auto">
                                                          <div className="img-author fit-img">
                                                              <img loading={"lazy"} src={testimavatar4} alt=""/>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="swiper-slide">
                                              <div className="item gray">
                                                  <div>
                                                      <h6>“A rebrand is not typically done in a chaotic, archaic
                                                          industry like
                                                          ours, so their work has really set us apart. 10/10 for Hub’s
                                                          team."
                                                      </h6>
                                                  </div>
                                                  <div className="info d-flex align-items-center mt-60">
                                                      <div className="info-text">
                                                          <p>CEO & Founder, Archin Studio</p>
                                                          <span>B. Gordon</span>
                                                      </div>
                                                      <div className="ml-auto">
                                                          <div className="img-author fit-img">
                                                              <img loading={"lazy"} src={testimavatar1} alt=""/>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="swiper-slide">
                                              <div className="item colorful">
                                                  <div>
                                                      <h6>“Thanks for the support of Technofolio team, who have been
                                                          with our
                                                          business for more than 3 years. A long journey with many
                                                          exciting
                                                          experiences and moments.”</h6>
                                                  </div>
                                                  <div className="info d-flex align-items-center mt-60">
                                                      <div className="info-text">
                                                          <p>Project Managerment at Zumar Inc</p>
                                                          <span>d. Szoboslai</span>
                                                      </div>
                                                      <div className="ml-auto">
                                                          <div className="img-author fit-img">
                                                              <img loading={"lazy"} src={testimavatar2} alt=""/>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <span className="brush">
                            <img loading={"lazy"} src={header} alt=""/>
                        </span>
                          </div>

                          <div className="brands-mp">
                              <div className="container section-padding bord-thin-top">
                                  <div className="text-center sec-head mb-20">
                                      <h6 className="sub-head text-u opacity-5">Partnered with 150+ Brands</h6>
                                  </div>
                                  <div className="text-center">
                                      <div className="item">
                                          <h2>ZumarCons</h2>
                                          <div className="icon">
                                              <img loading={"lazy"} src={brands8} alt=""/>
                                          </div>
                                      </div>
                                      <div className="item">
                                          <h2>Archin</h2>
                                          <div className="icon">
                                              <img loading={"lazy"} src={brands1} alt=""/>
                                          </div>
                                      </div>
                                      <div className="item nodot">
                                          <h2>Github</h2>
                                          <div className="icon">
                                              <img loading={"lazy"} src={brands3} alt=""/>
                                          </div>
                                      </div>
                                      <div className="item">
                                          <h2>BriskLabs</h2>
                                          <div className="icon">
                                              <img loading={"lazy"} src={brands2} alt=""/>
                                          </div>
                                      </div>
                                      <div className="item">
                                          <h2>RogerArk</h2>
                                          <div className="icon">
                                              <img loading={"lazy"} src={brands6} alt=""/>
                                          </div>
                                      </div>
                                      <div className="item nodot">
                                          <h2>Mathew Co</h2>
                                          <div className="icon">
                                              <img loading={"lazy"} src={brands4} alt=""/>
                                          </div>
                                      </div>
                                      <div className="item">
                                          <h2>Udix</h2>
                                          <div className="icon">
                                              <img loading={"lazy"} src={brands7} alt=""/>
                                          </div>
                                      </div>
                                      <div className="item nodot">
                                          <h2>ODA Group</h2>
                                          <div className="icon">
                                              <img loading={"lazy"} src={brands5} alt=""/>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>


                          <div className="blog-mp section-padding bg-gray">
                              <div className="container">
                                  <div className="sec-head mb-80">
                                      <div className="row">
                                          <div className="col-lg-6">
                                              <h2>News</h2>
                                          </div>
                                          <div className="col-lg-6">
                                              <div className="d-flex">
                                                  <a href="../inner_pages/blog-grid.html"
                                                     className="butn butn-md butn-bord butn-rounded ml-auto">
                                                      <div className="d-flex align-items-center">
                                                          <span>All Articles</span>
                                                          <span className="ml-20">
                                                        <FontAwesomeIcon icon={ faChevronRight } />
                                                    </span>
                                                      </div>
                                                  </a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="row xlg-marg">
                                      <div className="col-lg-4 bord">
                                          <div className="item md-mb50">
                                              <div className="info d-flex align-items-center">
                                                  <div className="d-flex align-items-center">
                                                      <div>
                                                          <div className="author-img fit-img">
                                                              <img loading={"lazy"} src={blog1} alt=""/>
                                                          </div>
                                                      </div>
                                                      <div className="author-info ml-10">
                                                          <span>M Moussa</span>
                                                          <span className="sub-color">editor</span>
                                                      </div>
                                                  </div>
                                                  <div className="date ml-auto">
                                                <span className="sub-color"><FontAwesomeIcon icon={ faClock } /> 12 hours
                                                    ago</span>
                                                  </div>
                                              </div>
                                              <div className="img fit-img mt-30">
                                                  <img loading={"lazy"} src={blog1} alt=""/>
                                              </div>
                                              <div className="cont mt-30">
                                                  <h6>
                                                      <a href="../inner_pages/blog-details.html">We’re winner SOTY at
                                                          CSS
                                                          Award 2023</a>
                                                  </h6>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-4 bord">
                                          <div className="item md-mb50">
                                              <div className="info d-flex align-items-center">
                                                  <div className="d-flex align-items-center">
                                                      <div>
                                                          <div className="author-img fit-img">
                                                              <img loading={"lazy"} src={blog4} alt=""/>
                                                          </div>
                                                      </div>
                                                      <div className="author-info ml-10">
                                                          <span>M Moussa</span>
                                                          <span className="sub-color">editor</span>
                                                      </div>
                                                  </div>
                                                  <div className="date ml-auto">
                                                <span className="sub-color"><FontAwesomeIcon icon={ faClock } /> 12 hours
                                                    ago</span>
                                                  </div>
                                              </div>
                                              <div className="img fit-img mt-30">
                                                  <img loading={"lazy"} src={blog2} alt=""/>
                                              </div>
                                              <div className="cont mt-30">
                                                  <h6>
                                                      <a href="../inner_pages/blog-details.html">Common UX painpoints in
                                                          Dashboard-related projects you must
                                                          know</a>
                                                  </h6>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-4">
                                          <div className="item">
                                              <div className="info d-flex align-items-center">
                                                  <div className="d-flex align-items-center">
                                                      <div>
                                                          <div className="author-img fit-img">
                                                              <img loading={"lazy"} src={blog5} alt=""/>
                                                          </div>
                                                      </div>
                                                      <div className="author-info ml-10">
                                                          <span>M Moussa</span>
                                                          <span className="sub-color">editor</span>
                                                      </div>
                                                  </div>
                                                  <div className="date ml-auto">
                                                <span className="sub-color"><FontAwesomeIcon icon={ faClock } /> 12 hours
                                                    ago</span>
                                                  </div>
                                              </div>
                                              <div className="img fit-img mt-30">
                                                  <img loading={"lazy"} src={blog3} alt=""/>
                                              </div>
                                              <div className="cont mt-30">
                                                  <h6>
                                                      <a href="../inner_pages/blog-details.html">Visual Website Tips
                                                          #5</a>
                                                  </h6>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <footer className="footer-mp section-padding pb-0">
                              <div className="container">
                                  <div className="row">
                                      <div className="col-lg-4">
                                          <div className="info-item md-mb30">
                                              <span className="fz-12 text-u sub-color mb-10">location</span>
                                              <h6>152 Thatcher Road St, Mahattan, <br/> NY 10463, US</h6>
                                          </div>
                                      </div>
                                      <div className="col-lg-4">
                                          <div className="info-item md-mb30">
                                              <span className="fz-12 text-u sub-color mb-10">location</span>
                                              <h6>152 Thatcher Road St, Mahattan, <br/> NY 10463, US</h6>
                                          </div>
                                      </div>
                                      <div className="col-lg-4 d-flex">
                                          <div className="ml-auto ml-none">
                                              <div className="social-icon">
                                                  <a href="#"> <FontAwesomeIcon icon={faFacebook}/> </a>
                                                  <a href="#"> <FontAwesomeIcon icon={faTwitter}/> </a>
                                                  <a href="#"> <FontAwesomeIcon icon={faLinkedin}/> </a>
                                                  <a href="#"> <FontAwesomeIcon icon={faInstagram}/> </a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="footer-logo">
                                      <h1><span>Tec</span>folio</h1>
                                      <div className="union">
                                          <img loading={"lazy"} src={circletext} alt=""/>
                                          <div className="icon">
                                              <img loading={"lazy"} src={union} alt=""/>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="sub-footer pt-30 pb-30 mt-30 bord-thin-top">
                                      <div className="row">
                                          <div className="col-lg-8">
                                              <div className="copy sub-color md-mb30">
                                                  <p>© 2024 <a href="#0">Technopresso Group</a>. All Right Reserved</p>
                                              </div>
                                          </div>
                                          <div className="col-lg-4 d-flex justify-content-end">
                                              <div className="links sub-color d-flex justify-content-between">
                                                  <a href="#" className="active">Home</a>
                                                  <a href="../inner_pages/portfolio-standard.html">Works</a>
                                                  <a href="../inner_pages/about.html">Studio</a>
                                                  <a href="../inner_pages/blog-standard.html">News</a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </footer>

                      </section>


                  </main>


              </div>

          </div>


      </div>
  )
}
