import "../../scss/Portfolio4.scss"
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEnvelope, faStar, faChevronLeft, faChevronRight, faClock} from '@fortawesome/free-solid-svg-icons';
import {
    faFacebook,
    faTwitter,
    faInstagram,
    faLinkedin,
    faDribbble,
    faBehance
} from '@fortawesome/free-brands-svg-icons';

import logo from "../../imgs/logo.svg";
import circletext from "../../imgs/portfolio4/header/circle-text.svg";
import bg1 from "../../imgs/portfolio4/header/bg1.webp";
import union from "../../imgs/portfolio4/header/union.svg";

import arrowtopright from "../../imgs/icons/arrow-top-right.svg";
import crownsolid from "../../imgs/icons/crown-solid.svg";
import beziercurvesolid from "../../imgs/icons/bezier-curve-solid.svg";
import codesolid from "../../imgs/icons/code-solid.svg";
import chartlinesolid from "../../imgs/icons/chart-line-solid.svg";
import paperplane from "../../imgs/icons/paper-plane.svg";

import client1 from "../../imgs/portfolio4/clients/1.svg";
import client2 from "../../imgs/portfolio4/clients/2.svg";
import client3 from "../../imgs/portfolio4/clients/3.svg";
import client4 from "../../imgs/portfolio4/clients/4.svg";
import client5 from "../../imgs/portfolio4/clients/5.svg";

import testimavatar1 from "../../imgs/portfolio4/testim/avatar1.jpg";
import testimavatar2 from "../../imgs/portfolio4/testim/avatar2.jpg";
import testimavatar3 from "../../imgs/portfolio4/testim/avatar3.jpg";
import vectorcircle from "../../imgs/portfolio4/testim/Vector_Cricle_Text.svg";
import vectorquote from "../../imgs/portfolio4/testim/vector_quote.svg";

import blog1 from "../../imgs/portfolio4/blog/1.jpg";
import blog2 from "../../imgs/portfolio4/blog/2.jpg";
import blog3 from "../../imgs/portfolio4/blog/3.jpg";

import works1 from "../../imgs/portfolio4/works/1.jpg";
import works2 from "../../imgs/portfolio4/works/2.jpg";
import works3 from "../../imgs/portfolio4/works/3.jpg";
import works5 from "../../imgs/portfolio4/works/5.jpg";
import works7 from "../../imgs/portfolio4/works/7.jpg";

import technopresso from "../../imgs/background/banner-transparent.webp";
import about from "../../imgs/portfolio4/about.jpg";
import React from "react";


export default function Collection() {
  return (
      <div className='portfolio4'>

          <div className="hamenu">
              <div className="close-menu cursor-pointer ti-close"></div>
              <div className="container-fluid rest d-flex">
                  <div className="menu-links">
                      <ul className="main-menu rest">
                          <li>
                              <div className="o-hidden">
                                  <div className="link cursor-pointer dmenu"><span className="fill-text" data-text="Home">Home</span>
                                      <i></i></div>
                              </div>
                              <div className="sub-menu">
                                  <ul>
                                      <li>
                                          <a href="../startup_agency/index.html" className="sub-link">Startup Agency</a>
                                      </li>
                                      <li>
                                          <a href="../creative-studio/index.html" className="sub-link">Creative Studio</a>
                                      </li>
                                      <li>
                                          <a href="../creative_agency/index.html" className="sub-link">Creative Agency</a>
                                      </li>
                                      <li>
                                          <a href="../digital-marketing/index.html" className="sub-link">Digital Marketing</a>
                                      </li>
                                      <li>
                                          <a href="../modern_portfolio/index.html" className="sub-link">Modern Portfolio</a>
                                      </li>
                                      <li>
                                          <a href="../digital_studio/index.html" className="sub-link">Digital Studio</a>
                                      </li>
                                      <li>
                                          <a href="../modern_agency/index.html" className="sub-link">Modern Agency</a>
                                      </li>
                                      <li>
                                          <a href="../creative_agency2/index.html" className="sub-link">Creative Agency 2</a>
                                      </li>
                                  </ul>
                              </div>
                          </li>
                          <li>
                              <div className="o-hidden">
                                  <div className="link cursor-pointer dmenu"><span className="fill-text" data-text="Pages">Pages</span>
                                      <i></i></div>
                              </div>
                              <div className="sub-menu">
                                  <ul>
                                      <li>
                                          <a href="../inner_pages/about.html" className="sub-link">About Us</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/services.html" className="sub-link">Our Services</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/service-details.html" className="sub-link">Services Details</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/team.html" className="sub-link">Our Team</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/pricing.html" className="sub-link">Pricing</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/faqs.html" className="sub-link">FAQS</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/contact.html" className="sub-link">Contact Us</a>
                                      </li>
                                  </ul>
                              </div>
                          </li>
                          <li>
                              <div className="o-hidden">
                                  <div className="link cursor-pointer dmenu"><span className="fill-text" data-text="Portfolio">Portfolio</span>
                                      <i></i></div>
                              </div>
                              <div className="sub-menu">
                                  <ul>
                                      <li>
                                          <a href="../inner_pages/portfolio-standard.html" className="sub-link">Standard</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/portfolio-gallery.html" className="sub-link">Gallery</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/portfolio-cards.html" className="sub-link">Card</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/portfolio-layout2.html" className="sub-link">Grid 2 Column</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/portfolio-layout3.html" className="sub-link">Gid 3 Column</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/portfolio-layout4.html" className="sub-link">Grid 4 Column</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/project-details.html" className="sub-link">Project Details</a>
                                      </li>
                                  </ul>
                              </div>
                          </li>
                          <li>
                              <div className="o-hidden">
                                  <div className="link cursor-pointer dmenu"><span className="fill-text" data-text="Blog">Blog</span>
                                      <i></i></div>
                              </div>
                              <div className="sub-menu">
                                  <ul>
                                      <li>
                                          <a href="../inner_pages/blog-grid.html" className="sub-link">Blog Grid</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/blog-standard.html" className="sub-link">Blog Standard</a>
                                      </li>
                                      <li>
                                          <a href="../inner_pages/blog-details.html" className="sub-link">Blog Details</a>
                                      </li>
                                  </ul>
                              </div>
                          </li>
                          <li>
                              <div className="o-hidden">
                                  <a href="../inner_pages/contact.html" className="link"><span className="fill-text"
                                                                                           data-text="Contact Us">Contact Us</span></a>
                              </div>
                          </li>
                      </ul>
                  </div>
                  <div className="cont-info valign">
                      <div className="text-center full-width">
                          <div className="logo">
                              <img loading={"lazy"} src={logo} alt=""/>
                          </div>
                          <div className="social-icon mt-40">
                              <a href="#"> <FontAwesomeIcon icon={ faFacebook } /> </a>
                              <a href="#"> <FontAwesomeIcon icon={ faTwitter } /> </a>
                              <a href="#"> <FontAwesomeIcon icon={ faLinkedin } /> </a>
                              <a href="#"> <FontAwesomeIcon icon={ faInstagram } /> </a>
                          </div>
                          <div className="item mt-30">
                              <h5>541 Melville Geek, <br/> Palo Alto, CA 94301</h5>
                          </div>
                          <div className="item mt-10">
                              <h5><a href="#0">Hello@email.com</a></h5>
                              <h5 className="underline"><a href="#0">+1 840 841 25 69</a></h5>
                          </div>
                      </div>
                  </div>
              </div>
          </div>


          <div id="smooth-wrapper">


              <div id="smooth-content">

                  <main>

                      <header className="header-ma">
                          <div className="container ontop">
                              <div className="caption d-flex">
                                  <h1>Marketing <br/> & SEO studio</h1>
                                  <div className="text">
                                      <p>Connecting businesses with their audiences, and individuals with their dreams. Our
                                          path forward is one of continuous growth</p>
                                  </div>
                                  <div className="img sm-hide">
                                      <img loading={"lazy"} src={circletext} alt=""/>
                                          <img loading={"lazy"} src={union} alt="" className="icon"/>
                                  </div>
                              </div>
                          </div>
                          <div className="mimg fit-img">
                              <img loading={"lazy"} src={bg1} alt=""/>
                                  <div className="lg-text sm-hide"></div>
                                  <svg height="0" width="0">
                                      <defs>
                                          <clipPath id="svgTextPath">
                                              <text x="50" y="200" textLength="1000px" font-family="Inter" font-size="220px"
                                                    font-weight="700"> Technofolio </text>
                                          </clipPath>
                                      </defs>
                                  </svg>
                          </div>
                      </header>

                      <section className="intro-ma section-padding">
                          <div className="container">
                              <div className="sec-head">
                                  <div className="row">
                                      <div className="col-lg-5 sub-head md-mb15">
                                          <h6 className="text-u fz-14 ls1">the blend of simplicity and innovation</h6>
                                      </div>
                                      <div className="col-lg-7">
                                          <h3><span className="sub-color">Welcome to Technofolio</span> — your reliable and friendly
                                              AI companion designed to make your daily life simpler and more enjoyable in this
                                              fast-paced world.</h3>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </section>

                      <section className="services-ds">
                          <div className="container">
                              <div className="sec-head mb-70 d-flex align-items-center">
                                  <div>
                                      <h2>Our <span>Services</span></h2>
                                  </div>
                                  <div className="ml-auto">
                                      <a href="../inner_pages/services.html" className="butn butn-md butn-bord butn-rounded">
                                          <div className="d-flex align-items-center">
                                              <span>See Our Approach</span>
                                              <span className="icon ml-10">
                                            <FontAwesomeIcon icon={ faChevronRight } />
                                        </span>
                                          </div>
                                      </a>
                                  </div>
                              </div>
                              <div className="item d-flex align-items-center">
                                  <div className="title">
                                      <h2>Branding</h2>
                                  </div>
                                  <div className="text ml-auto">
                                      <div className="d-flex align-items-center">
                                          <p>Brand Identity, Stragegy & Consult, <br/> Position, Rebrand</p>
                                          <div>
                                              <div className="icon invert">
                                                  <img loading={"lazy"} src={crownsolid} alt=""/>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="item d-flex align-items-center">
                                  <div className="title">
                                      <h2>Design</h2>
                                  </div>
                                  <div className="text ml-auto">
                                      <div className="d-flex align-items-center">
                                          <p>Brand Identity, Stragegy & Consult, <br/> Position, Rebrand</p>
                                          <div>
                                              <div className="icon invert">
                                                  <img loading={"lazy"} src={beziercurvesolid} alt=""/>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="item d-flex align-items-center">
                                  <div className="title">
                                      <h2>Code</h2>
                                  </div>
                                  <div className="text ml-auto">
                                      <div className="d-flex align-items-center">
                                          <p>Brand Identity, Stragegy & Consult, <br/> Position, Rebrand</p>
                                          <div>
                                              <div className="icon invert">
                                                  <img loading={"lazy"} src={codesolid} alt=""/>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="item d-flex align-items-center">
                                  <div className="title">
                                      <h2>Growth</h2>
                                  </div>
                                  <div className="text ml-auto">
                                      <div className="d-flex align-items-center">
                                          <p>Brand Identity, Stragegy & Consult, <br/> Position, Rebrand</p>
                                          <div>
                                              <div className="icon invert">
                                                  <img loading={"lazy"} src={chartlinesolid} alt=""/>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </section>

                      <div className="marq-mp section-padding">
                          <div className="main-marq color-bg shadow-off">
                              <div className="slide-har st1">
                                  <div className="box">
                                      <div className="item">
                                          <h5>Top-notch Experts</h5>
                                      </div>
                                      <div className="item">
                                          <h5>Dedicated Support 24/7</h5>
                                      </div>
                                      <div className="item">
                                          <h5>Flexible Pricing</h5>
                                      </div>
                                      <div className="item">
                                          <h5>Top-notch Experts</h5>
                                      </div>
                                      <div className="item">
                                          <h5>AI-Driven Solutions</h5>
                                      </div>
                                      <div className="item">
                                          <h5>Flexiable Price</h5>
                                      </div>
                                  </div>
                                  <div className="box">
                                      <div className="item">
                                          <h5>Top-notch Experts</h5>
                                      </div>
                                      <div className="item">
                                          <h5>Dedicated Support 24/7</h5>
                                      </div>
                                      <div className="item">
                                          <h5>Flexible Pricing</h5>
                                      </div>
                                      <div className="item">
                                          <h5>Top-notch Experts</h5>
                                      </div>
                                      <div className="item">
                                          <h5>AI-Driven Solutions</h5>
                                      </div>
                                      <div className="item">
                                          <h5>Flexiable Price</h5>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="main-marq gray-bg shadow-off">
                              <div className="slide-har st2">
                                  <div className="box">
                                      <div className="item">
                                          <h5>Top-notch Experts</h5>
                                      </div>
                                      <div className="item">
                                          <h5>Dedicated Support 24/7</h5>
                                      </div>
                                      <div className="item">
                                          <h5>Flexible Pricing</h5>
                                      </div>
                                      <div className="item">
                                          <h5>Top-notch Experts</h5>
                                      </div>
                                      <div className="item">
                                          <h5>AI-Driven Solutions</h5>
                                      </div>
                                      <div className="item">
                                          <h5>Flexiable Price</h5>
                                      </div>
                                  </div>
                                  <div className="box">
                                      <div className="item">
                                          <h5>Top-notch Experts</h5>
                                      </div>
                                      <div className="item">
                                          <h5>Dedicated Support 24/7</h5>
                                      </div>
                                      <div className="item">
                                          <h5>Flexible Pricing</h5>
                                      </div>
                                      <div className="item">
                                          <h5>Top-notch Experts</h5>
                                      </div>
                                      <div className="item">
                                          <h5>AI-Driven Solutions</h5>
                                      </div>
                                      <div className="item">
                                          <h5>Flexiable Price</h5>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <section className="works-ma section-padding pt-0">
                          <div className="container">
                              <div className="sec-head mb-70">
                                  <div className="row">
                                      <div className="col-lg-5">
                                          <h6 className="sub-head md-mb15">Featured Works</h6>
                                      </div>
                                      <div className="col-lg-7">
                                          <h4>Our user-centered design encourages
                                              <span className="sub-color inline">productivity & boosts revenue.</span>
                                          </h4>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="container-fluid">
                              <div className="gallery-img">
                                  <div className="swiper-container">

                                      <Swiper
                                          breakpoints={{
                                              100: {
                                                  slidesPerView: 1,
                                              },
                                              640: {
                                                  slidesPerView: 1,
                                              },
                                              // when window width is >= 768px
                                              768: {
                                                  slidesPerView: 2,
                                              },

                                          }}
                                          spaceBetween={50}
                                          slidesPerView={3}
                                          onSlideChange={() => console.log('slide change')}
                                          onSwiper={(swiper) => console.log(swiper)}
                                      >
                                          <SwiperSlide>
                                              <div className="swiper-slide">
                                                  <div className="bg-img" data-background={works1} style={{backgroundImage: "url(" + works1 + ")"}}>
                                                      <a href="../inner_pages/project-details.html"></a>
                                                  </div>
                                              </div>
                                          </SwiperSlide>
                                          <SwiperSlide>
                                              <div className="swiper-slide">
                                                  <div className="bg-img" data-background={works2} style={{backgroundImage: "url(" + works2 + ")"}}>
                                                      <a href="../inner_pages/project-details.html"></a>
                                                  </div>
                                              </div>
                                          </SwiperSlide>
                                          <SwiperSlide>
                                              <div className="swiper-slide">
                                                  <div className="bg-img" data-background={works3} style={{backgroundImage: "url(" + works3 + ")"}}>
                                                      <a href="../inner_pages/project-details.html"></a>
                                                  </div>
                                              </div>
                                          </SwiperSlide>
                                          <SwiperSlide>
                                              <div className="swiper-slide">
                                                  <div className="bg-img" data-background={works5} style={{backgroundImage: "url(" + works5 + ")"}}>
                                                      <a href="../inner_pages/project-details.html"></a>
                                                  </div>
                                              </div>
                                          </SwiperSlide>
                                          <SwiperSlide>
                                              <div className="swiper-slide">
                                                  <div className="bg-img" data-background={works7} style={{backgroundImage: "url(" + works7 + ")"}}>
                                                      <a href="../inner_pages/project-details.html"></a>
                                                  </div>
                                              </div>
                                          </SwiperSlide>
                                      </Swiper>

                                  </div>
                              </div>
                          </div>
                      </section>

                      <section className="clients-ds section-padding">
                          <div className="container">
                              <div className="sec-head mb-70">
                                  <h6 className="sub-head">partner with +150 brands</h6>
                              </div>
                              <div className="row sm-marg">
                                  <div className="col-lg col-md-4 col-6 md-mb30">
                                      <div className="item d-flex align-items-center justify-content-center">
                                          <div className="img">
                                              <img loading={"lazy"} src={client1} alt=""/>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg col-md-4 col-6 md-mb30">
                                      <div className="item d-flex align-items-center justify-content-center">
                                          <div className="img">
                                              <img loading={"lazy"} src={client2} alt=""/>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg col-md-4 col-6 md-mb30">
                                      <div className="item d-flex align-items-center justify-content-center">
                                          <div className="img">
                                              <img loading={"lazy"} src={client3} alt=""/>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg col-md-4 col-6 sm-mb30">
                                      <div className="item d-flex align-items-center justify-content-center">
                                          <div className="img">
                                              <img loading={"lazy"} src={client4} alt=""/>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg col-md-4 col-6">
                                      <div className="item d-flex align-items-center justify-content-center">
                                          <div className="img">
                                              <img loading={"lazy"} src={client5} alt=""/>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </section>

                      <section className="testimonials-ds">
                          <div className="container">
                              <div className="row">
                                  <div className="col-lg-5">
                                      <div className="qoute-icon md-mb50">
                                          <div className="text">
                                              <img loading={"lazy"} src={vectorcircle} alt=""/>
                                          </div>
                                          <div className="icon">
                                              <img loading={"lazy"} src={vectorquote} alt=""/>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg-7">
                                      <div>
                                          <div>
                                              <Swiper
                                                  slidesPerView={1}
                                                  autoplay={true}
                                                  onSlideChange={() => console.log('slide change')}
                                                  onSwiper={(swiper) => console.log(swiper)}
                                              >
                                                  <SwiperSlide className="item">
                                                      <div className="text">
                                                          <h4>“Technofolio studio ability to create a high quality UI is
                                                              stands out. It’s something we placed a premium on. A
                                                              studio
                                                              with passionate, professional, fun and full creativity.
                                                              Recommend!.”</h4>
                                                      </div>
                                                      <div
                                                          className="info d-flex align-items-center mt-40 pt-30 bord-thin-top-light">
                                                          <div>
                                                              <div className="img-author">
                                                              <img loading={"lazy"} src={testimavatar1} alt=""/>
                                                              </div>
                                                          </div>
                                                          <div className="info-text">
                                                              <span>Bradley Gordon</span>
                                                              <p>CEO & Founder, Archin Studio</p>
                                                          </div>
                                                      </div>
                                                  </SwiperSlide>
                                                  <SwiperSlide>
                                                      <div className="swiper-slide">
                                                          <div className="item">
                                                              <div className="text">
                                                                  <h4>“Technofolio studio ability to create a high
                                                                      quality
                                                                      UI is
                                                                      stands out. It’s something we placed a premium on.
                                                                      A
                                                                      studio
                                                                      with passionate, professional, fun and full
                                                                      creativity.
                                                                      Recommend!.”</h4>
                                                              </div>
                                                              <div
                                                                  className="info d-flex align-items-center mt-40 pt-30 bord-thin-top-light">
                                                                  <div>
                                                                      <div className="img-author">
                                                                          <img loading={"lazy"} src={testimavatar2} alt=""/>
                                                                      </div>
                                                                  </div>
                                                                  <div className="info-text">
                                                                      <span>Bradley Gordon</span>
                                                                      <p>CEO & Founder, Archin Studio</p>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </SwiperSlide>
                                                  <SwiperSlide>
                                                      <div className="swiper-slide">
                                                          <div className="item">
                                                              <div className="text">
                                                                  <h4>“Technofolio studio ability to create a high
                                                                      quality
                                                                      UI is
                                                                      stands out. It’s something we placed a premium on.
                                                                      A
                                                                      studio
                                                                      with passionate, professional, fun and full
                                                                      creativity.
                                                                      Recommend!.”</h4>
                                                              </div>
                                                              <div
                                                                  className="info d-flex align-items-center mt-40 pt-30 bord-thin-top-light">
                                                                  <div>
                                                                      <div className="img-author">
                                                                          <img loading={"lazy"} src={testimavatar3} alt=""/>
                                                                      </div>
                                                                  </div>
                                                                  <div className="info-text">
                                                                      <span>Bradley Gordon</span>
                                                                      <p>CEO & Founder, Archin Studio</p>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </SwiperSlide>
                                              </Swiper>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </section>

                      <section className="awards-sa section-padding pt-0">
                          <div className="container">
                              <div className="row">
                                  <div className="col-lg-4">
                                      <div className="sec-head md-mb80">
                                          <h6 className="sub-head mb-30">Awards & Recognition</h6>
                                          <h4>Efforts to receive worthy rewards, awards & recognition
                                              <span className="sub-color inline">help us affirm our brand.</span>
                                          </h4>
                                      </div>
                                  </div>
                                  <div className="col-lg-7 offset-lg-1">
                                      <div>
                                          <div className="item-title row">
                                              <div className="col-md-9">
                                                  <div>
                                                      <h6 className="sub-title">Award title</h6>
                                                  </div>
                                              </div>
                                              <div className="col-md-3 d-flex justify-content-end">
                                                  <div>
                                                      <h6 className="sub-title">Date</h6>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="item-line row">
                                          <div className="col-md-9">
                                                  <div className="honors">
                                                      <h6>Awwwards</h6>
                                                  </div>
                                                  <div className="project-title">
                                                      <h6>SOTY 2023 - 1st Winner</h6>
                                                  </div>
                                              </div>
                                              <div className="col-md-3">
                                                  <div className="project-date">
                                                      <span>May 2023</span>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="item-line row">
                                              <div className="col-md-9">
                                                  <div className="honors">
                                                      <h6>css awards</h6>
                                                  </div>
                                                  <div className="project-title">
                                                      <h6>Top 5 Best of eCommerce Websites 2022</h6>
                                                  </div>
                                              </div>
                                              <div className="col-md-3">
                                                  <div className="project-date">
                                                      <span>Dec 2022</span>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="item-line row">
                                              <div className="col-md-9">
                                                  <div className="honors">
                                                      <h6>Awwwards</h6>
                                                  </div>
                                                  <div className="project-title">
                                                      <h6>Honor SOTD November, 2022r</h6>
                                                  </div>
                                              </div>
                                              <div className="col-md-3">
                                                  <div className="project-date">
                                                      <span>Nov 2022</span>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="item-line row">
                                              <div className="col-md-9">
                                                  <div className="honors">
                                                      <h6>Behance Portfolio</h6>
                                                  </div>
                                                  <div className="project-title">
                                                      <h6>Winner - US Behance Portfolio Review 2021</h6>
                                                  </div>
                                              </div>
                                              <div className="col-md-3">
                                                  <div className="project-date">
                                                      <span>Aug 2021</span>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </section>

                      <section className="about-ma">
                          <div className="bg-img md-hide" data-background={about} style={{backgroundImage: "url(" + about + ")"}}></div>
                          <div className="content">
                              <div className="item sec-head mb-100">
                                  <h6 className="sub-head sub-color mb-20">Our Experience</h6>
                                  <h3>We help creative agencies, designers, and other creative people to connect with potential clients.</h3>
                                  <div className="botm d-flex align-items-end pt-50 mt-100 bord-thin-top-light">
                                      <h2>12</h2>
                                      <h6 className="ml-auto">Years of <br/> experience</h6>
                                  </div>
                              </div>
                              <div className="item sec-head mb-100">
                                  <h6 className="sub-head sub-color mb-20">Our Successfully</h6>
                                  <h3>We are a passionate team of designers and developers.</h3>
                                  <div className="botm d-flex align-items-end pt-50 mt-100 bord-thin-top-light">
                                      <h2>64</h2>
                                      <h6 className="ml-auto">Projects <br/> Completed</h6>
                                  </div>
                              </div>
                              <div className="item sec-head">
                                  <h6 className="sub-head sub-color mb-20">Our Growth</h6>
                                  <h3>Commitment to Innovation and Growth.</h3>
                                  <div className="botm d-flex align-items-end pt-50 mt-100 bord-thin-top-light">
                                      <h2>180<span>%</span></h2>
                                      <h6 className="ml-auto">Conversions <br/> growth increased.</h6>
                                  </div>
                              </div>
                          </div>
                      </section>

                      <section className="blog-ds section-padding">
                          <div className="container">
                              <div className="sec-head mb-70 d-flex align-items-center">
                                  <div>
                                      <h2>Our <span>Articles</span></h2>
                                  </div>
                                  <div className="ml-auto">
                                      <a href="../inner_pages/blog-standard.html" className="butn butn-md butn-bord butn-rounded">
                                          <div className="d-flex align-items-center">
                                              <span>See All Articles</span>
                                              <span className="icon ml-10">
                                            <FontAwesomeIcon icon={ faChevronRight } />
                                        </span>
                                          </div>
                                      </a>
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-lg-4">
                                      <div className="item pt-30 bord-thin-top-light md-mb50">
                                          <div className="info d-flex align-items-center mb-20">
                                              <a href="#0" className="tag">
                                                  <span>Experience</span>
                                              </a>
                                              <span className="dash">/</span>
                                              <a href="#0" className="date">
                                                  <span>May 15, 2024</span>
                                              </a>
                                          </div>
                                          <div className="text mb-50">
                                              <h6>How to build work culture for <br/> young office?</h6>
                                          </div>
                                          <div className="img">
                                              <img loading={"lazy"} src={blog1} alt=""/>
                                                  <a href="../inner_pages/blog-details.html" className="icon invert">
                                                      <img loading={"lazy"} src={arrowtopright}alt=""/>
                                                  </a>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg-4">
                                      <div className="item pt-30 bord-thin-top-light md-mb50">
                                          <div className="info d-flex align-items-center mb-20">
                                              <a href="#0" className="tag">
                                                  <span>Design Trends</span>
                                              </a>
                                              <span className="dash">/</span>
                                              <a href="#0" className="date">
                                                  <span>May 1, 2024</span>
                                              </a>
                                          </div>
                                          <div className="text mb-50">
                                              <h6>Technofolio - Winner SOTY at CSS <br/> Winner 2023 with Zumar project</h6>
                                          </div>
                                          <div className="img">
                                              <img loading={"lazy"} src={blog2} alt=""/>
                                                  <a href="../inner_pages/blog-details.html" className="icon invert">
                                                      <img loading={"lazy"} src={arrowtopright} alt=""/>
                                                  </a>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg-4">
                                      <div className="item pt-30 bord-thin-top-light">
                                          <div className="info d-flex align-items-center mb-20">
                                              <a href="#0" className="tag">
                                                  <span>Tips & Tricks</span>
                                              </a>
                                              <span className="dash">/</span>
                                              <a href="#0" className="date">
                                                  <span>April 24, 2024</span>
                                              </a>
                                          </div>
                                          <div className="text mb-50">
                                              <h6>Rebrand vs Refesh: 10 Minutes On Brand by Technofolio</h6>
                                          </div>
                                          <div className="img">
                                              <img loading={"lazy"} src={blog3} alt=""/>
                                                  <a href="../inner_pages/blog-details.html" className="icon invert">
                                                      <img loading={"lazy"} src={arrowtopright} alt=""/>
                                                  </a>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </section>

                  </main>

                  <footer className="footer-ds bord-thin-top-light">
                      <div className="container section-padding">
                          <div className="row justify-content-between">
                              <div className="col-lg-4">
                                  <div className="subscribe md-mb50">
                                      <h6>Be the first to get the latest news <br/> about trends, inspiration & more</h6>
                                      <div className="form-group">
                                          <label for="email_subscribe">Email Address</label>
                                          <input id="email_subscribe" type="email" placeholder="Enter your email address"
                                                 name="email_subscribe"/>
                                              <button type="submit" className="icon invert">
                                                  <img loading={"lazy"} src={paperplane} alt=""/>
                                              </button>
                                      </div>
                                      <span>By subscribing, you’re accept our <a href="#0">Privacy Policy</a></span>
                                  </div>
                              </div>
                              <div className="col-lg-6">
                                  <div className="contact-info">
                                      <div className="row lg-marg">
                                          <div className="col-md-6">
                                              <div className="item mb-60">
                                                  <h6>Work Inquiry</h6>
                                                  <p>contact@Technofolio.agency</p>

                                              </div>
                                          </div>
                                          <div className="col-md-6">
                                              <div className="item mb-60">
                                                  <h6>Open Position</h6>
                                                  <p>Senior Front-end Wordpress Developer</p>
                                                  <p>UI/UX Designer (Remote)</p>
                                              </div>
                                          </div>
                                          <div className="col-md-6">
                                              <div className="item sm-mb60">
                                                  <h6>Mahattan, NY</h6>
                                                  <p>152 Thatcher Road St, Mahattan NY 10463 United States</p>
                                              </div>
                                          </div>
                                          <div className="col-md-6">
                                              <div className="item">
                                                  <h6>Links</h6>
                                                  <p><a href="#0">Terms & Conditions</a></p>
                                                  <p><a href="#0">Privacy Policy</a></p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="sub-footer bord-thin-top-light pt-50 pb-50">
                          <div className="container">
                              <div className="row">
                                  <div className="col-lg-8">
                                      <div className="copy d-flex align-items-center md-mb30">
                                          <div>
                                              <div className="logo">
                                                  <img loading={"lazy"} src={technopresso} alt=""/>
                                              </div>
                                          </div>
                                          <div className="ml-50">
                                              <p>© 2024 <a href="#0">Technopresso Group</a>. <br/> All Right Reserved</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg-4 d-flex justify-content-end">
                                      <div className="links sub-color d-flex justify-content-between">
                                          <a href="#" className="active">Home</a>
                                          <a href="../inner_pages/portfolio-standard.html">Works</a>
                                          <a href="../inner_pages/about.html">Studio</a>
                                          <a href="../inner_pages/blog-standard.html">News</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </footer>
              </div>

          </div>

      </div>
  )
}
