import { useTranslation } from "react-i18next";

import "../scss/LngBtn.scss"

export default function LngButton() {
  const { i18n } = useTranslation();
  const lngs = {
    ja: "English",
    en: "Japanese"
  };

  const lngChange = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="LngBtn">
      <button
        onClick={() => lngChange(i18n.language === 'ja' ? 'en' : 'ja')}
      >
        {lngs[i18n.language] || lngs.en}
      </button>
    </div>
  );
}
