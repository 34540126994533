import React from 'react'
import "../scss/WebCollection.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import img1 from "../imgs/header/bg.webp";

import img2 from "../imgs/portfolio2/works/1.webp";

import img3 from "../imgs/portfolio3/header/1.webp";
import img4 from "../imgs/portfolio4/header/bg1.webp";
import img5 from "../imgs/portfolio5/works/1.webp";
import img6 from "../imgs/portfolio6/header/1.webp";

import img7 from "../imgs/WebCollection/Mookda/beauty1.webp";
import img8 from "../imgs//WebCollection/Wedding/wedding.webp";

export default function Collection() {
const data = [
        { id:1, src: img1,  alt:'Digital Marketing Portfolio', caption:'collection.Digital Marketing', description:'collection.Digital Marketing Description', url:"/allCollection/porfolio1"},
        { id:2, src: img2,  alt:'Agency Website Portfolio', caption:'collection.Creative Agency', description:'collection.Creative Agency Description', url:"/allCollection/porfolio2"},
        { id:3, src: img3,  alt:'Modern Portfolio for you Business', caption:'collection.Modern Portfolio', description:'collection.Modern Portfolio Description', url:"/allCollection/porfolio3"},
        { id:4, src: img4,  alt:'Personal and Work Portfolio made by Technopresso', caption:'collection.Modern Agency', description:'collection.Modern Agency Description', url:"/allCollection/porfolio4"},
        { id:5, src: img5,  alt:'Personal Blog site made by Technopresso', caption:'collection.Digital Products', description:'collection.Digital Products Description', url:"/allCollection/porfolio5"},
        { id:6, src: img6,  alt:'Business Website Template made by Technopresso', caption:'collection.Modern Design', description:'collection.Modern Design Description', url:"/allCollection/porfolio6"},
        { id:7, src: img7,  alt:'Company Profile website template made by Technopresso', caption:'collection.Beauty Salon', description:'collection.Hair Salon description', url:"https://technopresso-beauty.web.app/"},
        { id:8, src: img8,  alt:'Business and Music website template made by Technopresso', caption:'collection.Wedding Website', description:'collection.Wedding description', url:"https://ghalreekandjessicawedding.com/"},
    ];
const { t } = useTranslation();
  return (
  <div className='webCollectionContainer'>
    <div className=" works-mp section-padding pt-0">
        <div className='titles'>
            <h3 className='subTitle' data-aos="zoom-in" data-aos-delay="200">{t("collection.inspiringWebsites")}</h3>
            <h2 className='title' data-aos="zoom-in" data-aos-delay="300">{t('collection.webCollections')}</h2>
       </div>
        <div className="container-xxl" >
            <div className="row gallery">
            {data.map((item) => (
                <div className="col-lg-3 items" key={item.id} >
                    <a href={item.url} target="_blank">
                        <div className="item">
                            <div className="cont d-flex align-items-center">
                                <div>
                                    <h5>{t(`${item.caption}`)}</h5>
                                    <span>{t(`${item.description}`)}</span>
                                </div>
                                <div className="ml-auto">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                </div>
                            </div>
                            <div className="img">
                                <img loading={"lazy"} src={item.src} alt={item.alt}/>
                            </div>
                        </div>
                    </a>
                </div>
            ))}
            </div>
        </div>
    </div>
  </div>
  )
}
