import React from 'react';
import ReactDOM from 'react-dom/client'
import App from './App';
// import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrapのスタイルをインポート
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Bootstrapのスクリプトをインポート
import './i18next'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);