import React, { useState, useEffect } from 'react';
import "../scss/ScrollBotBtn.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';


const ScrollToBotButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY < 500) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToBot = () => {
    window.scrollTo(0, document.body.scrollHeight /4 );
  };

  return (
    <div className={`scroll-to-bot ${isVisible ? '' : 'visible'}`} onClick={scrollToBot}>
      <FontAwesomeIcon icon={ faAngleDoubleDown } className='icon'/>
    </div>
  );
};

export default ScrollToBotButton;
