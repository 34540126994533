import React from 'react'
import "../scss/AboutUs.scss"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { faBookOpenReader } from '@fortawesome/free-solid-svg-icons';
import { faCommentsDollar } from '@fortawesome/free-solid-svg-icons';
import { faMobile } from '@fortawesome/free-solid-svg-icons';

import { useTranslation } from "react-i18next";

function AboutUs() {
  const { t } = useTranslation();

  return (
    <div className='aboutUs' id='aboutUs'>
        <div className='aboutUsContainer row'>
            <div className='about-text col-sm-8'>
                <h2 data-aos="fade-up" ><FontAwesomeIcon icon={ faLayerGroup } className='icon'/>{t("About Us")}</h2>

                <h3 data-aos="fade-up">{t("aboutus-title")}</h3>
                <p  data-aos="fade-up">
                {t("about1")}<br/>
                {t("about2")}<br/><br/>
                {t("about3")}<br/><br/>
                {t("about4")}<br/><br/>
                {t("about5")}<br/>
                </p>
                <div className="ftbox mt-30">
                    <div className="sub-title" >
                        <h6>{t("check")}</h6>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <ul>
                        <li className="space wow  fadeIn" data-aos="fade-up" data-aos-delay="100" >
                            <span ><FontAwesomeIcon icon={ faBookOpenReader } className='icon'/></span>
                            <h6 >Our <br/> Service</h6>
                            <div className="dots">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </li>
                        <li className="space wow fadeIn" data-aos="fade-up" data-aos-delay="200">

                                <span className="icon pe-7s-paint-bucket"><FontAwesomeIcon icon={ faCommentsDollar } className='icon'/></span>
                                <h6 className="custom-font">Customer's<br/>Benefit</h6>
                                <div className="dots">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                        </li>
                        <li className="space wow fadeIn" data-aos="fade-up" data-aos-delay="300">

                            <span className="icon pe-7s-medal"><FontAwesomeIcon icon= { faMobile } className='icon'/></span>
                            <h6 className="custom-font">Apps / Web<br/>Collections</h6>
                            <div className="dots">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  )
}
export default AboutUs
