import Service from '../pages/Service';
import OurProgrammers from "../pages/OurProgrammers"
import CustomerBenefit from "../pages/CustomerBenefit"
import Footer from "./Footer";

function OurServices() {
  return (
    <div>
        <Service/>
        <CustomerBenefit/>
        <OurProgrammers/>
        <Footer/>
    </div>
  )
}

export default OurServices