import React from 'react'
import "../scss/CustomerBenefit.scss"


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faArrowTrendDown } from '@fortawesome/free-solid-svg-icons';
import { faUserTie} from '@fortawesome/free-solid-svg-icons';

import img1 from "../imgs/Customerbenefit/1.webp";
import img2 from "../imgs/Customerbenefit/2.webp";
import img3 from "../imgs/Customerbenefit/3.webp";

import { useTranslation } from "react-i18next";

function CustomerBenefit() {
  const { t } = useTranslation();

  return (
    <div className='CustomerBenefit' id='customerBenefit'>
        <div className='row'>
            <div className='col-lg-5'>
                <div className="img-mons">
                    <div className="row">
                        <div className="col-md-5 cmd-padding valign" data-aos="fade-right">
                            <div className="imgs img1" >
                                <img loading={"lazy"} src={img1} alt="Low Cost OurServices by Technopresso"/>
                            </div>
                        </div>
                        <div className="col-md-7 cmd-padding" data-aos="fade-right">
                            <div className="imgs img2">
                                <img loading={"lazy"} src={img2} alt="High Quality Software by Technopresso"/>
                            </div>
                            <div className="imgs img3" data-aos="fade-right">
                                <img loading={"lazy"} src={img3} alt="Bug-free code optimized code by Technopresso"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-6 offset-lg-1 valign'>
                <div className='content'>
                    <div className="sub-title" data-aos="fade-right">
                        <h6>{t("What's the benefit for Customer?")}</h6>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <h3 data-aos="fade-right">
                       {t("Build your Project with Low Cost and High Quality")}
                    </h3>
                    <p data-aos="fade-right">
                        {t("customerBenefit1")}
                    </p>
                    <p  data-aos="fade-right">
                        {t("customerBenefit2")}
                    </p>
                    <p data-aos="fade-right">
                        {t("customerBenefit3")}
                    </p>

                    <div className="ftbox mt-30">
                    <div className="sub-title" data-aos="fade-up">
                        <h6>{t("Benefits :")}</h6>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                        <ul>
                            <li className="wow fadeIn" data-aos="fade-up"  data-aos-delay="600">
                                <span className="icon pe-7s-gleam"></span>
                                <h6 className="custom-font">{t("Skilled")}<br/>{t("Software Engineers")}</h6>
                                <div className='icon'>
                                    <FontAwesomeIcon icon={ faUsers } className='icon'/>
                                </div>
                                <div className="dots">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </li>
                            <li className="space wow fadeIn" data-aos="fade-up"  data-aos-delay="800">
                                <span className="icon pe-7s-paint-bucket"></span>
                                <h6 className="custom-font">{t("Reasonable")}<br/>{t("OurServices Costs")}</h6>
                                <div className='icon'>
                                    <FontAwesomeIcon icon={ faArrowTrendDown } className='icon'/>
                                </div>
                                <div className="dots">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </li>
                            <li className="wow fadeIn" data-aos="fade-up"  data-aos-delay="1000">
                                <span className="icon pe-7s-medal"></span>
                                <h6 className="custom-font">{t("Project Manager")}<br/>{t("in every project")}</h6>
                                <div className='icon'>
                                    <FontAwesomeIcon icon={ faUserTie } className='icon'/>
                                </div>
                                <div className="dots">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
export default CustomerBenefit