import React from 'react'
import "../scss/Footerpage.scss"

import { Link } from "react-router-dom";


function Footer() {
  return (
    <footer>
        <div className="container" >
            <div className="footerInner" >
                <div className="footerImg" data-aos="zoom-out-up" data-aos-delay="100">
                    <Link to="/" rel="noopener noreferrer">
                        <img loading={"lazy"} src="https://technopresso.com/banner-transparent.webp" alt="Technopresso IT Solutions Footer Page"/>
                    </Link>
                </div>
            </div>
            <div className="row" data-aos="zoom-out-up" data-aos-delay="200">
                <div className="col col-md-3">
                    <ul>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/collections">Collection</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>
                </div>
                <div className="col col-md-3">
                    <ul>
                        <li><Link to="/service">Service</Link></li>
                        <li><Link to="/OurProgrammers">Resource</Link></li>
                        <li><Link to="/appCollection">Collections</Link></li>
                    </ul>
                </div>

                <div className="col col-md-3">
                    <ul>
                        <li><Link to="https://blog.technopresso.com" target="_blank" >Blog</Link></li>
                        <li><Link to="https://www.instagram.com/technopressogroup/" target="_blank" >Instagram</Link></li>
                        <li><Link to="https://www.facebook.com/technopressogroup" target="_blank" >Facebook</Link></li>
                    </ul>
                </div>
                <div className="col col-md-3">
                    <ul>
                       <li><Link to="https://github.com/technopresso" target="_blank" >Github</Link></li>
                       <li><Link to="https://twitter.com/technopresso" target="_blank" >Twitter</Link></li>
                       <li><Link to="https://twitter.com/technopresso" target="_blank" >LinkedIn</Link></li>
                    </ul>
                </div>
                </div>
        </div>
    </footer>
  )
}

export default Footer